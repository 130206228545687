import React from "react";
import { withRouter } from "react-router-dom";
import * as short from "short-uuid";
import DownloadCardItem from "./DownloadCardItem";

const MultiDownloadCards = (props) => {
  function renderDownloadListLeft() {
    const maxItems = props.files_left.length - 1;
    return props.files_left.map((item, index) => renderDownloadItem(item, index, maxItems));
  }

  function renderDownloadListRight() {
    const maxItems = props.files_right.length - 1;
    return props.files_right.map((item, index) => renderDownloadItem(item, index, maxItems));
  }

  const renderDownloadItem = (item, index, maxItems) => {
    let rulerClass = "visible";
    if (index === maxItems) {
      rulerClass = "invisible";
    }

    const screenName =
      item.properties.title !== null ? item.properties.title : item.properties.filename;

    return (
      <DownloadCardItem key={short.uuid()} title={screenName} item={item} rulerClass={rulerClass} />
    );
  };

  const elementClasses = `multiple-download-cards ${props.classes}`;

  return (
    <div className={elementClasses}>
      <div className="row is-flex">
        <div className="col-md-6 mb-large">
          <div className="card download-list">
            <h3>{props.header_left}</h3>
            {renderDownloadListLeft()}
          </div>
        </div>
        <div className="col-md-6 mb-large">
          <div className="card download-list">
            <h3>{props.header_right}</h3>
            {renderDownloadListRight()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MultiDownloadCards);
