import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Routes } from "../Constants";
import { isAuthenticated, logoutUser } from "../Utils/UserUtils";

const LogoutContainer = ({ userInput }) => {
  useEffect(() => {
    if (isAuthenticated()) {
      logoutUser();
    }
  }, []);

  return <Redirect to={Routes.DASHBOARD} userInput={userInput} />;
};

LogoutContainer.propTypes = {
  userInput: PropTypes.instanceOf(Object),
};

export default LogoutContainer;
