import React from "react";
import PropTypes from "prop-types";

const RteCard = ({ classes, headline, subline, copy }) => {
  const elementClasses = `cards ${classes}`;

  return (
    <div className={elementClasses}>
      <div className=" is-flex">
        <div className="col-md-6 mb-large">
          <div className="card">
            <h3>{headline}</h3>
            <span className="subline mb-medium">{subline}</span>
            <div className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
          </div>
        </div>
      </div>
    </div>
  );
};

RteCard.propTypes = {
  classes: PropTypes.string,
  headline: PropTypes.string,
  subline: PropTypes.string,
  copy: PropTypes.string,
};

export default RteCard;
