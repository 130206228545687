import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { getApi } from "../Utils/ApiUtils";
import { TOP_NAVIGATION, FOOTER_NAVIGATION } from "../Constants/ApiConstants";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loadNavigationSuccess: ["page"],
  loadNavigationFailure: ["error"],
  loadNavigation: ["navigation"],
  loadFooterNavigationSuccess: ["footer"],
  loadFooterNavigationFailure: ["footerError"],
});

export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  success: null,
  navigation: null,
  subNav: [],
  subNavLoaded: false,
  footerNav: [],
  footerNavLoaded: false,
});

/* ------------- Reducers ------------- */

/**
 * Successfully loaded page
 *
 * @param state
 * @param page
 */
export const success = (state, navigation) => {
  return { ...state, fetching: false, error: null, success: true, navigation };
};

/**
 * Had a problem loading page
 *
 * @param state
 * @param error
 */
export const failure = (state, { error }) => {
  return { ...state, fetching: false, success: false, error };
};

export const successFooter = (state, navigation) => {
  return { ...state, footerNavigation: navigation.footer };
};

export const failureFooter = (state, { error }) => {
  return { ...state, fetching: false, success: false, error };
};

export const loadFooterNavigation = () => async (dispatch) => {
  const { data } = await getApi(FOOTER_NAVIGATION, {});

  if (data.navigation) {
    const footerNav = data.navigation;
    dispatch(Creators.loadFooterNavigationSuccess(footerNav, true));
  } else {
    dispatch(Creators.loadFooterNavigationFailure(null, false));
  }
};

export const loadNavigation = () => async (dispatch) => {
  const { data } = await getApi(TOP_NAVIGATION, {});

  if (data && data.navigation) {
    const { navigation } = data;
    dispatch(Creators.loadNavigationSuccess(navigation));
  } else {
    dispatch(Creators.loadNavigationFailure());
  }
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOAD_NAVIGATION_SUCCESS]: success,
  [Types.LOAD_NAVIGATION_FAILURE]: failure,
  [Types.LOAD_FOOTER_NAVIGATION_SUCCESS]: successFooter,
  [Types.LOAD_FOOTER_NAVIGATION_FAILURE]: failureFooter,
});
