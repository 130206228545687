import React, { useState } from "react";
import * as short from "short-uuid";
import { Modal } from "react-bootstrap";
import { ReactComponent as Close } from "../../Images/SVG/close.svg";

import ModalContent from "./ModalContent";

const ModalButton = (props) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const handleShow = () => setShowModal(true);

  return (
    <>
      <button
        key={short.uuid()}
        id="link"
        className="btn btn-white btn-small mr-medium"
        style={{ cursor: "pointer" }}
        onClick={handleShow}
        type="button"
      >
        {props.title}
      </button>
      <Modal show={showModal} keyboard className="custom-modal-style" animation>
        <Modal.Header>
          <div
            className="col-12 mb-medium d-flex justify-content-end"
            key={short.uuid()}
            id="close"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          >
            <Close />
          </div>
        </Modal.Header>
        <Modal.Body>{props.modal && <ModalContent page={props.modal} />}</Modal.Body>
      </Modal>
    </>
  );
};

export default ModalButton;
