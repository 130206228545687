import React from "react";
import PropTypes from "prop-types";

const Headline = ({ classes, headline }) => {
  const elementClasses = `headline row ${classes}`;

  return (
    <div className={elementClasses}>
      <div className="col-12">
        <h2>{headline}</h2>
      </div>
    </div>
  );
};

Headline.propTypes = {
  classes: PropTypes.string,
  headline: PropTypes.string,
};

export default Headline;
