import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Charts } from "../Constants";

export function cookColor(colorSet, colorScale, baseColorHex) {
  const colorArray = [];

  for (let i = 0; i < colorScale; i++) {
    const opacity = 1 - i * 0.05;
    const str = `rgba(${baseColorHex}, ${opacity})`;
    colorArray.push(str);
  }

  return colorArray;
}

export function createPieChart(chartDiv, legendDiv, data, pickedColor) {
  // create a piechart
  const chart = am4core.create(chartDiv, am4charts.PieChart);

  // LPP-240 (Reorder Labels)
  data.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

  const kasseItemIndex = data.findIndex((item) => item.name.toLowerCase() === "kasse");

  let reorderedData;

  if (kasseItemIndex > -1) {
    // splice() returns an array with the removed values, have to pop() it to get the actual value
    const kasseItem = data.splice(kasseItemIndex, 1).pop();
    reorderedData = [...data, kasseItem];
  } else {
    reorderedData = data;
  }

  // set data
  chart.data = reorderedData;

  // create new legend, set options
  const legendContainer = am4core.create(legendDiv, am4core.Container);
  chart.legend = new am4charts.Legend();
  chart.legend.valueLabels.template.align = "left";
  chart.legend.valueLabels.template.textAlign = "start";
  legendContainer.width = am4core.percent(100);
  legendContainer.height = am4core.percent(100);
  // add legend to chart
  chart.legend.parent = legendContainer;

  // chart.legend.events.on("ready", function (ev) {
  //   ev.target.itemContainers.each(function (item) {
  //     item.zIndex = item.dataItem.dataContext.dummyData.position;
  //     ev.target.children.moveValue(item, item.zIndex);
  //   });
  // });

  // cut a hole in the pie
  chart.innerRadius = am4core.percent(30);

  // define styling
  const pieSeries = chart.series.push(new am4charts.PieSeries());
  const colorSet = new am4core.ColorSet();
  pieSeries.dataFields.value = "value";
  pieSeries.dataFields.category = "name";
  pieSeries.labels.template.disabled = true;
  pieSeries.ticks.template.disabled = true;
  colorSet.list = cookColor(colorSet, chart.data.length, Charts.baseColorHex[pickedColor]).map(
    function (color) {
      return new am4core.color(color);
    },
  );
  pieSeries.colors = colorSet;
  pieSeries.slices.template.stroke = am4core.color("#fff");
  pieSeries.slices.template.strokeWidth = 2;
  pieSeries.slices.template.strokeOpacity = 1;
  pieSeries.slices.template.cursorOverStyle = [
    {
      property: "cursor",
      value: "pointer",
    },
  ];

  // return chart
  return chart;
}

export function createPerformanceChart(targetDiv, data) {
  const x = am4core.create(targetDiv, am4charts.XYChart);
  x.data = data.data;

  const dateAxis = x.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0;

  // Create vertical scrollbar and place it before the value axis
  x.scrollbarY = new am4core.Scrollbar();
  x.scrollbarY.parent = x.leftAxesContainer;
  x.scrollbarY.background.fill = am4core.color("#263f51");
  x.scrollbarY.fill = am4core.color("red");

  const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;
  valueAxis.renderer.labels.template.adapter.add("text", (text, label) => {
    return label.dataItem.value !== undefined ? `${label.dataItem.value.toFixed(1)} %` : null;
  });
  // valueAxis.title.text = "Wertentwicklung kumuliert (in %)";

  x.legend = new am4charts.Legend();
  const scrollbarX = new am4charts.XYChartScrollbar();

  data.fonds.forEach((item, index) => {
    const series = x.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = item.isin;
    series.tooltipText = "{valueY}";
    series.stroke = am4core.color(`rgba(${Charts.baseColorHex[item.color]}, 1)`);
    series.strokeWidth = 2;
    series.name = item.name;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color(`rgba(${Charts.baseColorHex[item.color]}, 1)`);
    series.tooltip.label.background.fill = am4core.color(
      `rgba(${Charts.baseColorHex[item.color]}, 1)`,
    );
    series.tooltip.background.cornerRadius = 6;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.label.fill = am4core.color("#fff");
    series.tooltip.label.textColor = am4core.color("#fff");
    scrollbarX.series.push(series);
  });

  x.cursor = new am4charts.XYCursor();

  x.scrollbarX = scrollbarX;
  x.scrollbarX.parent = x.bottomAxesContainer;

  x.numberFormatter.numberFormat = {
    style: "decimal",
    minimumFractionDigits: 2,
  };

  // Enable export
  x.exporting.menu = new am4core.ExportMenu();
  x.exporting.menu.items = [
    {
      label: "",
      menu: [
        { type: "png", label: "Download" },
        { label: "Drucken", type: "print" },
      ],
    },
  ];
  x.exporting.menu.verticalAlign = "bottom";
  // x.exporting.menu.align = 'left';

  return x;
}
