import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { getApi } from "../Utils/ApiUtils";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loadPageSuccess: ["page"],
  loadPageFailure: ["error"],
  loadModalPageSuccess: ["modalpage"],
  loadModalPageFailure: ["modalerror"],
  loadNotificationPageSuccess: ["notificationpage"],
  loadNotificationPageFailure: ["notificationerror"],
  loadPage: ["page"],
  loadPagination: ["paginate"],
  clearItems: null,
});

export const LoadPageTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  success: null,
  page: null,
  modal: null,
  items: null,
  notification: null,
});

/* ------------- Reducers ------------- */

/**
 * Successfully loaded page
 *
 * @param state
 * @param page
 */
export const success = (state, { page }) => {
  return {
    ...state,
    fetching: false,
    error: null,
    success: true,
    page,
    items: [
      ...(state.items ?? []),
      ...(page.items.colPos0 ?? []).filter((el) => {
        return el.type === "news_pi1";
      }),
    ],
  };
};

/**
 * Had a problem loading page
 *
 * @param state
 * @param error
 */
export const failure = (state, { error }) => {
  return { ...state, fetching: false, success: false, error };
};

/**
 * Successfully loaded page
 *
 * @param state
 * @param page
 */
export const successModal = (state, page) => {
  return {
    ...state,
    fetching: false,
    error: null,
    success: true,
    modal: page,
  };
};

/**
 * Had a problem loading page
 *
 * @param state
 * @param error
 */
export const failureModal = (state, error) => {
  return { ...state, fetching: false, success: false, error, modal: null };
};

export const successNotification = (state, page) => {
  return {
    ...state,
    fetching: false,
    error: null,
    success: true,
    notification: page,
  };
};

export const failureNotification = (state, error) => {
  return { ...state, fetching: false, success: false, error, notification: null };
};

export const loadPagination = (slug) => async (dispatch) => {
  const { data } = await getApi(slug, {});
  if (data && data.page) {
    const page = {
      uid: data.page.id,
      common: data.page,
      items: data.content,
    };

    dispatch(Creators.loadPageSuccess(page));
  } else {
    dispatch(Creators.loadPageFailure(data));
  }
};

export const clear = (state, action) => {
  return { ...state, items: [] };
};

export const clearItems = () => (dispatch) => {
  dispatch(Creators.clearItems());
};

/**
 *
 *
 */
export const loadPage = (slug) => async (dispatch) => {
  const { data } = await getApi(slug, {});
  if (data && data.page) {
    const page = {
      uid: data.page.id,
      common: data.page,
      items: data.content,
    };
    dispatch(Creators.loadPageSuccess(page));
  } else {
    dispatch(Creators.loadPageFailure(data));
  }
};

export const loadModalPage = (slug) => async (dispatch) => {
  const { data } = await getApi(slug, {});
  if (data && data.page) {
    const page = {
      uid: data.page.id,
      common: data.page,
      items: data.content,
    };
    dispatch(Creators.loadModalPageSuccess(page));
  } else {
    dispatch(Creators.loadModalPageFailure(data));
  }
};

export const loadNotificationPage = (slug) => async (dispatch) => {
  const { data } = await getApi(slug, {});
  if (data && data.page) {
    const page = {
      uid: data.page.id,
      common: data.page,
      items: data.content,
    };
    dispatch(Creators.loadNotificationPageSuccess(page));
  } else {
    dispatch(Creators.loadNotificationPageFailure(data));
  }
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOAD_PAGE_SUCCESS]: success,
  [Types.LOAD_PAGE_FAILURE]: failure,
  [Types.LOAD_MODAL_PAGE_SUCCESS]: successModal,
  [Types.LOAD_MODAL_PAGE_FAILURE]: failureModal,
  [Types.LOAD_NOTIFICATION_PAGE_SUCCESS]: successNotification,
  [Types.LOAD_NOTIFICATION_PAGE_FAILURE]: failureNotification,
  [Types.CLEAR_ITEMS]: clear,
});
