/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Close } from "../../../Images/SVG/close.svg";
import Checkbox from "../CheckmarkIcons";
import { postApi } from "../../../Utils/ApiUtils";
import { NEWSLETTER_REGISTRATION } from "../../../Constants/ApiConstants";

const validator = require("email-validator");

/**
 *
 * @param {preselectedNewsletter={{ laic: state.nl1, lloyd: state.nl2 }}
 content={props.item.content}} props
 */

const NewsletterModal = (props) => {

  const [newsletterSelection, setNewsletterSelection] = useState({
    laic: props.preselectedNewsletter.laic,
    lloyd: props.preselectedNewsletter.lloyd,
  });

  useEffect(() => {
    setNewsletterSelection({
      ...newsletterSelection,
      laic: props.preselectedNewsletter.laic,
      lloyd: props.preselectedNewsletter.lloyd,
    });
  }, [props.preselectedNewsletter]);

  const [emailValue, setEmailValue] = useState("");
  const [selectError, setSelectError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [generalError, setGeneralError] = useState(false);

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    props.hideModal();
  };

  const validateForm = () => {
    if (!newsletterSelection.laic && !newsletterSelection.lloyd) {
      setSelectError(true);
      return false;
    }
    if (!validator.validate(emailValue)) {
      setEmailError(true);
      return false;
    }
    return true;
  };

  const registerForNewsletter = async () => {
    setSelectError(false);
    setEmailError(false);
    setGeneralError(false);

    if (validateForm()) {
      const { payload, error } = await postApi(NEWSLETTER_REGISTRATION, {
        rest: {
          form: {
            email: emailValue,
            laicOptin: newsletterSelection.laic ?? false,
            lloydOptin: newsletterSelection.lloyd ?? false,
          },
        },
      });

      if (error === undefined) {
        handleClose();
      } else {
        setGeneralError(true);
      }
    }
  };

  return (
    <Modal
      show={props.showModal}
      keyboard
      className="custom-modal-style newsletter-modal"
      animation
    >
      <Modal.Header>
        <div
          className="col-12 mb-medium d-flex justify-content-end"
          id="close"
          style={{ cursor: "pointer" }}
          onClick={handleClose}
        >
          <Close />
        </div>
      </Modal.Header>
      <Modal.Body>
        <section id="modalContent">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="row justify-content-center mb-medium">
                <div className="col-xl-8 col-lg-10 col-md-12 newsletter-modal-head">
                  <h1>{props.content.form[0].title}</h1>
                  <h4>{props.content.form[0].body}</h4>
                  <input
                    className="newsletter-modal-input"
                    placeholder="Email *"
                    type="email"
                    value={emailValue}
                    onChange={(e) => {
                      setEmailValue(e.target.value);
                    }}
                  />
                  <div className="newsletter-modal-selection">
                    <div className="row newsletter-modal-option">
                      <div className="col-1">
                        <Checkbox
                          status={newsletterSelection.laic}
                          onStatusChanged={(status) =>
                            setNewsletterSelection({ ...newsletterSelection, laic: status })
                          }
                        />
                      </div>
                      <div className="col-11">
                        <span>{props.content.form[0].laic.optin}</span>
                      </div>
                    </div>
                    <div className="row newsletter-modal-option">
                      <div className="col-1">
                        <Checkbox
                          status={newsletterSelection.lloyd}
                          onStatusChanged={(status) =>
                            setNewsletterSelection({ ...newsletterSelection, lloyd: status })
                          }
                        />
                      </div>
                      <div className="col-11">
                        <span>{props.content.form[0].lloyd.optin}</span>
                      </div>
                    </div>
                  </div>
                  <span className="newsletter-modal-required-hint">* Pflichtfelder</span>
                  <hr />
                  <div className="d-flex flex-row-reverse newsletter-modal-confirm">
                    <button type="button" className="ml-xlarge btn" onClick={registerForNewsletter}>
                      {props.content.form[0].title}
                    </button>

                    {generalError ? (
                      <span className="newsletter-modal-error">
                        Beim der Übermittlung der Daten ist ein Fehler aufgetreten.
                      </span>
                    ) : (
                      <></>
                    )}
                    {emailError ? (
                      <span className="newsletter-modal-error">
                        Bitte überprüfen Sie Ihre E-Mail-Adresse.
                      </span>
                    ) : (
                      <></>
                    )}
                    {selectError ? (
                      <span className="newsletter-modal-error">
                        Bitte wählen Sie mindestens einen Newsletter aus.
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default NewsletterModal;
