/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Routes } from "../Constants";
import PrivateRoute from "../Components/PrivateRoute";
import Layout from "../Components/Layout";
import LoginContainer from "./LoginContainer";
import LogoutContainer from "./LogoutContainer";
import DashboardContainer from "./DashboardContainer";
import Imprint from "../Components/Meta/Imprint";
import PageContainer from "./PageContainer";
import { AM_CHARTS_LICENCE } from "../Constants/ConfigConstants";
import SideDrawer from "../Components/ContentElements/Sidebar/SideDrawer";
import BackDrop from "../Components/ContentElements/Sidebar/BackDrop";
import DownloadsSwitch from "../Components/ContentElements/Sidebar/DownloadsSwitch";
import Profile from "../Components/User/Profile";
import UserList from "../Components/User/UserList";
import PasswordReset from "../Components/User/PasswordReset";
import PrivacyFlex from "../Components/Meta/PrivacyFlex";
import UserAdminRoute from "../Components/UserAdminRoute";

am4core.useTheme(am4themes_animated);
am4core.addLicense(AM_CHARTS_LICENCE);

const RootContainer = ({ store }) => {
  return (
    <Provider store={store}>
      <SideDrawer />
      <BackDrop />

      <DownloadsSwitch />
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route path={Routes.LOGIN} component={LoginContainer} />
            <Route path={Routes.IMPRINT} component={Imprint} />
            <Route path={Routes.PRIVACY} component={PrivacyFlex} />
            <Route path={Routes.PASSWORD_RESET} component={PasswordReset} />
            <PrivateRoute path={Routes.LOGOUT} component={LogoutContainer} />
            <PrivateRoute exact path={Routes.DASHBOARD} component={DashboardContainer} />
            <PrivateRoute path={Routes.PAGE} component={PageContainer} />
            <PrivateRoute path={Routes.PROFILE} component={Profile} />
            <UserAdminRoute path={Routes.USER_LIST} component={UserList} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </Provider>
  );
};

RootContainer.propTypes = {
  store: PropTypes.object.isRequired,
};

export default RootContainer;
