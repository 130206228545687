import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { getApi } from "../Utils/ApiUtils";
import { DASHBOARD_ROUTE, TOP_NAVIGATION, FOOTER_NAVIGATION } from "../Constants/ApiConstants";
import { getUser } from "../Utils/UserUtils";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loadDashboardSuccess: ["page"],
  loadDashboardFailure: ["error"],
  loadDashboard: ["dashboard"],
});

export const LoadDashboardTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  success: null,
  dashboard: null,
});

/* ------------- Reducers ------------- */

/**
 * Successfully loaded page
 *
 * @param state
 * @param page
 */
export const success = (state, dashboard) => {
  return { ...state, fetching: false, error: null, success: true, dashboard };
};

/**
 * Had a problem loading page
 *
 * @param state
 * @param error
 */
export const failure = (state, error) => {
  return { ...state, fetching: false, success: false, error };
};

/**
 *
 *
 */
export const loadDashboard = () => async (dispatch) => {
  const { data } = await getApi(DASHBOARD_ROUTE, {});

  if (data && data.page) {
    const dashboard = {
      page: data.page,
      sidebar: data.content.colPos1,
      mainContent: data.content.colPos0,
    };

    dispatch(Creators.loadDashboardSuccess(dashboard));
  } else {
    dispatch(Creators.loadDashboardFailure([]));
  }
};

export const loadFooterNavigation = () => async (dispatch) => {
  const { data } = await getApi(FOOTER_NAVIGATION, {});
  if (data && data.navigation) {
    console.log(data);
  }
};

export const loadNavigation = () => async (dispatch) => {
  const { data } = await getApi(TOP_NAVIGATION, {});
  if (data && data.navigation) {
    console.log(data);
  }
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOAD_DASHBOARD_SUCCESS]: success,
  [Types.LOAD_DASHBOARD_FAILURE]: failure,
});
