import React from "react";
import * as short from "short-uuid";
import PropTypes from "prop-types";

const Sample = ({ persona, classes, headline, subline, copy }) => {
  const renderBulletPoint = (item) => {
    return (
      <div key={short.uuid()} className="bullet col-md-4 col-sm-6">
        <span>{item}</span>
      </div>
    );
  };

  const renderPersona = () => {
    return persona.map((item) => renderBulletPoint(item));
  };

  const elementClasses = `sample row ${classes}`;

  return (
    <div className={elementClasses}>
      <div className="col-12">
        <h3>{headline}</h3>
      </div>
      <div className="col-12 mt-xlarge persona">
        <h4>Steckbrief</h4>
        <div className="row">{renderPersona()}</div>
      </div>
      <div className="col-12 mt-xxxlarge">
        <h4>{subline}</h4>
        <div className="row example">
          <div className="col-12 copy" dangerouslySetInnerHTML={{ __html: copy }} />
        </div>
      </div>
    </div>
  );
};

Sample.propTypes = {
  persona: PropTypes.instanceOf(Array),
  classes: PropTypes.string,
  headline: PropTypes.string,
  subline: PropTypes.string,
  copy: PropTypes.string,
};

export default Sample;
