/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { ReactComponent as Close } from "../../../Images/SVG/close.svg";
import {
  closeMailModal,
  clearDownloadList,
  deleteFileForEmail,
  sendFilesToMailService,
} from "../../../Redux/DownloadRedux";

import IconZipFile from "../../../Images/SVG/zip_file.svg";

import IconMailWhite from "../../../Images/SVG/mail_white.svg";
import Checkbox from "../CheckmarkIcons";

const DownloadEmailModal = (props) => {
  const [formData, setFormData] = useState({
    assets: [],
    gender: 1,
    firstName: null,
    lastName: null,
    email: null,
    message: null,
    copyToUser: false,
  });

  const validateEntries = () => {
    return (
      formData.firstName !== null &&
      formData.lastName !== null &&
      formData.email !== null &&
      formData.message !== null
    );
  };

  const submitData = async () => {
    if (validateEntries()) {
      await sendFilesToMailService(formData);
      props.deleteFileForEmail();
      props.closeMailModal();
    }
  };

  useEffect(() => {
    const fileOrFiles =
      props.downloads.emailFile !== null
        ? [props.downloads.emailFile.properties.uidLocal]
        : props.downloads.fileCollection.map((el) => el.properties.uidLocal);

    setFormData({
      ...formData,
      assets: fileOrFiles,
      gender: 1,
      firstName: null,
      lastName: null,
      email: null,
      message: null,
      copyToUser: false,
    });
  }, [props.downloads]);

  return (
    <Modal
      show={props.downloads.mailModalOpen}
      keyboard
      className="custom-modal-style download-email-modal"
      animation
    >
      <Modal.Header>
        <div
          className="col-12 mb-medium d-flex justify-content-end"
          id="close"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.closeMailModal();
          }}
        >
          <Close />
        </div>
      </Modal.Header>
      <Modal.Body>
        <section id="modalContent">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="row justify-content-center mb-medium">
                <div className="col-xl-8 col-lg-10 col-md-12 download-email-modal-head">
                  <h1>Dokumente versenden</h1>
                  <h4>
                    Versenden Sie Dokumente per E-Mail. Füllen Sie dazu einfach das Formular aus.
                  </h4>

                  <div className="col-12">
                    <div className="row">
                      <input
                        className="download-email-modal-input name"
                        placeholder="Vorname *"
                        type="text"
                        value={formData.firstName}
                        onChange={(e) => {
                          setFormData({ ...formData, firstName: e.target.value });
                        }}
                      />

                      <input
                        className="download-email-modal-input lastname"
                        placeholder="Nachname *"
                        type="text"
                        value={formData.lastName}
                        onChange={(e) => {
                          setFormData({ ...formData, lastName: e.target.value });
                        }}
                      />
                    </div>

                    <div className="row">
                      <input
                        className="download-email-modal-input"
                        placeholder="Email *"
                        type="email"
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                      />
                    </div>

                    <div className="row">
                      <textarea
                        className="download-email-modal-textarea"
                        placeholder="Nachricht *"
                        value={formData.message}
                        onChange={(e) => {
                          setFormData({ ...formData, message: e.target.value });
                        }}
                      />
                    </div>

                    <div className="row">
                      <h5 className="download-email-modal-info">* Pflichtfelder</h5>
                    </div>
                  </div>

                  <div className="download-email-modal-attachment">
                    <p className="download-email-modal-attachment-head">Anhang</p>
                    <div className="col-12">
                      <div className="row">
                        <img src={IconZipFile} alt="" />
                        <h4>Dokumente.zip</h4>
                      </div>

                      <div className="row download-email-modal-attachment-copy">
                        <Checkbox
                          clean
                          className="download-email-modal-attachment-checkbox"
                          status={formData.copyToUser}
                          onStatusChanged={(val) => {
                            setFormData({ ...formData, copyToUser: val });
                          }}
                        />
                        <span>Kopie an mich senden</span>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="row flex-row-reverse download-email-modal-submit ">
                    <button
                      type="button"
                      className="ml-xlarge btn"
                      onClick={() => {
                        submitData();
                      }}
                    >
                      <div className="row align-items-center">
                        <img src={IconMailWhite} alt="" />
                        <span>VERSENDEN</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    downloads: state.downloads,
  };
};

export default connect(mapStateToProps, {
  closeMailModal,
  clearDownloadList,
  deleteFileForEmail,
})(DownloadEmailModal);
