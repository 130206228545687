/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import short from "short-uuid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ListItem from "./ListItem";
import NewsListItem from "./NewsListItem";
import { loadPagination, clearItems } from "../../../Redux/PageRedux";
import FilterItem from "./FilterItem";

const List = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFilter, setFilter] = useState(0);

  // LPP-222 - Archive tab (moved up to set default category to first one)
  const { listItems } = props;

  const allCategoriesFromData = listItems
    .flatMap((li) => li.content.data.list)
    .map((item) => item.categories)
    .flatMap((i) => i);

  const categories = allCategoriesFromData.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
  );

  // const [activeFilter, setFilter] = useState(categories[0].id || 0);

  function renderFilter() {
    let isHidden =
      listItems && listItems.length > 0 ? listItems[0].content.data.settings.hideFilters : true;

    isHidden =
      listItems && listItems.length > 0 && listItems[0].content.data.list.length === 0
        ? true
        : isHidden;

    if (isHidden === true || isHidden === "1") {
      return <div className="mb-large" />;
    }

    // const allCategoriesFromData = listItems
    //   .flatMap((li) => li.content.data.list)
    //   .map((item) => item.categories)
    //   .flatMap((i) => i);

    // const categories = allCategoriesFromData.filter(
    //   (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
    // );

    // LPP-222 - Archive tab
    const archiveIndex = categories.findIndex((category) => category.id === 99);

    if (archiveIndex !== -1) {
      categories[archiveIndex].title = "Vergangene Termine";

      categories.push(categories.splice(archiveIndex, 1)[0]);
    }

    return (
      <div key={short.uuid()} className="col-12">
        <div className="row filter-bar mb-large">
          <FilterItem
            title="Alle Anzeigen"
            isActive={activeFilter === 0}
            setFilter={() => setFilter(0)}
          />

          {categories.map((el) => (
            <FilterItem
              title={el.title}
              isActive={el.id === activeFilter}
              setFilter={() => setFilter(el.id)}
            />
          ))}
        </div>
      </div>
    );
  }

  function renderItems(items) {
    return items
      .flatMap((li) => li.content.data.list)
      .filter((v, i, a) => {
        // fallback for "Alle" tab, which is currently commented out
        if (activeFilter === 0) {
          return true;
        }

        let found = false;

        if (v.categories.length > 0) {
          if (activeFilter === 99) {
            // LPP-222 - Archive tab
            // if the active tab is "Vergangene Termine" render a record if it has the archive cat
            found = v.categories.some((category) => {
              return category.id === activeFilter;
            });
          } else {
            // LPP-222 - Archive tab
            // otherwise render a record if it has the active tab cat and HAS NOT the archive cat
            found =
              v.categories.some((category) => {
                return category.id === activeFilter;
              }) &&
              !v.categories.some((category) => {
                return category.id === 99;
              });
          }
        }
        return found;
      })
      .map((item) => renderItem(item));
  }

  function renderItem(item) {
    switch (item.type) {
      case "3":
        return (
          <div key={short.uuid()} className="event-list">
            <ListItem key={short.uuid()} item={item} />
          </div>
        );
      default:
        return (
          <div key={short.uuid()} className="news-list">
            <NewsListItem key={short.uuid()} item={item} />
          </div>
        );
    }
  }

  function loadNextPage() {
    const { slug } = props;
    const newIndex = currentPage + 1;
    props.loadPagination(`${slug}/page-${newIndex}`);
    setCurrentPage(newIndex);
  }

  return (
    <div key={short.uuid()} className="news-list">
      {renderFilter()}
      {renderItems(props.listItems)}
      {props.currentPage < props.maxPages ? (
        <div className="d-flex justify-content-center flex-row newsletter-modal-confirm">
          <button
            type="button"
            className="ml-xlarge btn btn-blue-transparent"
            onClick={loadNextPage}
          >
            Mehr Anzeigen
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

List.propTypes = {
  listItems: PropTypes.instanceOf(Array),
  slug: PropTypes.string,
  currentPage: PropTypes.number,
  maxPages: PropTypes.number,
  loadPagination: PropTypes.func.isRequired,
  clearItems: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    listItems: state.page.items,
    slug: state.page.page.common.slug,
  };
};

export default connect(mapStateToProps, { loadPagination, clearItems })(withRouter(List));
