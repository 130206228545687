/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-newline */

import React from "react";
import { Modal } from "react-bootstrap";
import { Player, BigPlayButton } from "video-react";
import { ReactComponent as Close } from "../../Images/SVG/close.svg";

const VideoModal = (props) => {
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    props.hideModal();
  };

  return (
    <Modal
      show={props.showModal}
      keyboard
      className="custom-modal-style newsletter-modal"
      animation
    >
      <Modal.Header>
        <div
          className="col-12 mb-medium d-flex justify-content-end"
          id="close"
          style={{ cursor: "pointer" }}
          onClick={handleClose}
        >
          <Close />
        </div>
      </Modal.Header>
      <Modal.Body>
        <section id="modalContent">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="row justify-content-center mb-medium">
                <div className="col-xl-8 col-lg-10 col-md-12 newsletter-modal-head align-items-center ">
                  <div className="video-player">
                    <Player playsInline src={props.src}>
                      <BigPlayButton position="center" />
                    </Player>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;
