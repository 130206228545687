import React from "react";
import PropTypes from "prop-types";

const CTA = ({ classes, target, label, url }) => {
  const elementClasses = `cta row justify-content-center ${classes}`;

  return (
    <div className={elementClasses}>
      <div className="col-12 text-center">
        <a href={url} target={target} className="btn">
          {label}
        </a>
      </div>
    </div>
  );
};

CTA.propTypes = {
  classes: PropTypes.string,
  target: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
};

export default CTA;
