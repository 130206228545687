/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import {
  addToDownloadList,
  addFileForMail,
  deleteFromDownloadList,
  openMailModal,
} from "../../Redux/DownloadRedux";
import IconDownloadAdd from "../../Images/SVG/download_add.svg";
import IconDownloadAdded from "../../Images/SVG/download_added.svg";
import IconDownloadAction from "../../Images/SVG/download_action.svg";
import IconMailAction from "../../Images/SVG/download_send_mail.svg";

const DownloadCardItem = (props) => {
  const checkFileAddedToBasket = () => {
    if (props.item.properties.uidLocal) {
      return props.files.some((el) => el.properties.uidLocal === props.item.properties.uidLocal);
    }
    return props.files.some(
      (el) => el.properties.fileReferenceUid === props.item.properties.fileReferenceUid,
    );
  };

  const addOrdDeleteFromList = (item) => {
    if (checkFileAddedToBasket()) {
      props.deleteFromDownloadList(item);
    } else {
      props.addToDownloadList(item);
    }
  };

  const openForMail = (item) => {
    props.addFileForMail(item);
    props.openMailModal();
  };

  return (
    <>
      <div className="">
        <div className="download-card-item justify-content-between ">
          <div className="row download-card-item-left-wrapper">
            <div
              className="download-card-item-icon float-left col-1"
              onClick={() => addOrdDeleteFromList(props.item)}
            >
              <img src={checkFileAddedToBasket() ? IconDownloadAdded : IconDownloadAdd} alt="" />
            </div>
            <div className="download-card-item-title float-left col-9">
              {props.title ?? props.item.properties.title ?? props.item.properties.filename}
            </div>
            <div className="col-2">
              <a
                href={props.item.publicUrl}
                className="download-card-item-cta-dl"
                download
                target="_blank"
                rel="noreferrer"
              >
                <img src={IconDownloadAction} alt="" />
              </a>
              <span
                className="mail-link"
                onClick={() => {
                  openForMail(props.item);
                }}
              >
                <img src={IconMailAction} alt="" className="mail" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr className={props.rulerClass} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    files: state.downloads.fileCollection,
  };
};

export default connect(mapStateToProps, {
  addToDownloadList,
  addFileForMail,
  deleteFromDownloadList,
  openMailModal,
})(DownloadCardItem);
