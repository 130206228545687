import React from "react";
import { withRouter } from "react-router-dom";
import * as short from "short-uuid";
import PropTypes from "prop-types";

const CardTypeOne = ({ buttonLabel, headline, target, image, history }) => {
  return (
    <div id={short.uuid()} className="col-md-6 col-lg-4 col-xl-3">
      <div className="card-type-one">
        <div className="row card-image">
          <div className="col-12 text-right mt-medium pr-xlarge">
            <img src={image} className="logo-image" alt="" />
          </div>
        </div>
        <div className="row card-label">
          <div className="col-12">
            <h3>
              <span className="h3-white ml-medium-2">{headline}</span>
            </h3>
          </div>
        </div>
        <div className="row card-link">
          <button
            className="ml-xlarge btn btn-white btn-small"
            type="button"
            onClick={() =>
              history.push({
                pathname: target,
              })
            }
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

CardTypeOne.propTypes = {
  buttonLabel: PropTypes.string,
  image: PropTypes.string,
  headline: PropTypes.string,
  target: PropTypes.string,
  history: PropTypes.instanceOf(Object),
};

export default withRouter(CardTypeOne);
