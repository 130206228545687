/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable class-methods-use-this */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as short from "short-uuid";
import { connect } from "react-redux";
import PageHeadline from "../Components/ContentElements/PageHeadline";
import Sidebar from "../Components/ContentElements/Sidebar";
import CardTypeOne from "../Components/ContentElements/Dashboard/CardTypeOne";
import NewsletterCard from "../Components/ContentElements/Dashboard/NewsletterCard";
import { getUserName } from "../Utils/UserUtils";
import { loadDashboard } from "../Redux/DashboardRedux";
import ImageCard from "../Components/ContentElements/Dashboard/ImageCard";
import NewsTeaser from "../Components/ContentElements/Dashboard/NewsTeaser";
import IconTeaser from "../Components/ContentElements/Dashboard/IconTeaser";

const DashboardContainer = ({ loadDashboard, dashboard, history, success, error, fetching }) => {
  const [showServiceInformation, setShowServiceInformation] = useState(true);

  useEffect(() => {
    loadDashboard();
  }, []);

  const getDashboardTitle = () => {
    if (dashboard?.page?.page?.title) {
      if (dashboard?.page?.page?.title === "empty") {
        return "";
      }
      return dashboard?.page?.page?.title;
    }

    return "";
  };

  const renderContentItem = (item) => {
    switch (item.type) {
      case "partnerportal_coloredcard":
        return (
          <CardTypeOne
            key={short.uuid()}
            buttonLabel={item.content.subheader}
            headline={item.content.header}
            image={item.content.image.publicUrl}
            target={item.content.headerLink.url}
          />
        );
      case "partnerportal_imagecard":
        return (
          <ImageCard
            key={short.uuid()}
            buttonLabel={item.content.subheader}
            headline={item.content.header}
            image={item.content.image.publicUrl}
            target={item.content.headerLink.url}
            history={history}
          />
        );
      case "partnerportal_newsletterformteaser":
        return <NewsletterCard key={short.uuid()} item={item} />;
      case "news_pi1":
        return (
          <NewsTeaser
            key={short.uuid()}
            header={item.content.header}
            linkLabel={item.content.subheader}
            listPage={item.content.headerLink.url}
            news={item.content.data.list}
            history={history}
          />
        );
      case "partnerportal_iconteaser":
        return (
          <IconTeaser
            key={short.uuid()}
            headline={item.content.header}
            linkLabel={item.content.subheader}
            link={item.content.headerLink.url}
            image={item.content.image.publicUrl}
            teaser={item.content.bodytext}
            history={history}
          />
        );
      default:
        return <span />;
    }
  };

  const renderMainContent = () => {
    if (dashboard?.page?.mainContent) {
      return dashboard?.page?.mainContent.map((item) => renderContentItem(item));
    }
  };

  const renderLoginInformation = () => {
    const today = new Date().toLocaleDateString();
    return (
      <div className="row">
        <div className="col-4 font-size-small pl-medium">
          {getUserName()} |{today}
        </div>
      </div>
    );
  };

  return (
    dashboard && (
      <section>
        <PageHeadline
          showServiceInformation={showServiceInformation}
          headline={getDashboardTitle()}
          layout="default"
        />
        {renderLoginInformation()}
        <div className="row mt-xxlarge">
          <div className="col-lg-10">
            <div className="row">{renderMainContent()}</div>
          </div>
          <div className="col-lg-2">
            <Sidebar />
          </div>
        </div>
      </section>
    )
  );
};

DashboardContainer.propTypes = {
  success: PropTypes.bool,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  history: PropTypes.instanceOf(Object),
  dashboard: PropTypes.instanceOf(Object),
  loadDashboard: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    success: state.dashboard.success,
    fetching: state.dashboard.fetching,
    error: state.dashboard.error,
    dashboard: state.dashboard.dashboard,
  };
};

export default connect(mapStateToProps, { loadDashboard })(withRouter(DashboardContainer));
