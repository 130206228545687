import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import short from "short-uuid";
import { useCookies } from "react-cookie";
import { ReactComponent as Close } from "../../Images/SVG/close.svg";
import ModalContent from "./ModalContent";
import { loadNotificationPage } from "../../Redux/PageRedux";

const Notification = (props) => {
  const [isNotificationLoaded, setNotificationLoaded] = useState(false);
  const [cookies, setCookie] = useCookies(["showNotifications"]);

  useEffect(() => {
    if (!isNotificationLoaded) {
      props.loadNotificationPage(props.page);
      setNotificationLoaded(true);
    } else {
      setShowNotificationModal(true);
    }
  }, [isNotificationLoaded, props]);

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setShowNotificationModal(false);
    setTimeout(() => {
      setCookie("showNotifications", "false", { path: "/", maxAge: 24 * 60 * 60 });
    }, 500);
  };

  const [showNotificationModal, setShowNotificationModal] = useState(false);

  return (
    <Modal show={showNotificationModal} keyboard className="custom-modal-style" animation>
      <Modal.Header>
        <div
          className="col-12 mb-medium d-flex justify-content-end"
          key={short.uuid()}
          id="close"
          style={{ cursor: "pointer" }}
          onClick={handleClose}
        >
          <Close />
        </div>
      </Modal.Header>
      <Modal.Body className="notification-body">
        {props.modal && (
          <ModalContent page={props.modal} renderButton handleClose={() => handleClose()} />
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    modal: state.page.notification ? state.page.notification.notificationpage : null,
    isFetching: !state.page.notification,
  };
};

export default connect(mapStateToProps, { loadNotificationPage })(withRouter(Notification));
