import React, { useState } from "react";
import short from "short-uuid";
import BackLink from "../Common/BackLink";
import PageHeadline from "../ContentElements/PageHeadline";
import { getApi, postApi } from "../../Utils/ApiUtils";
import {
  USER_BLOCK,
  USER_LIST,
  REACT_APP_FE_USER_FOLDER,
  USER_DELETE,
  USER_CREATE,
  USER_UPDATE,
} from "../../Constants/ApiConstants";
import ListItem from "./ListItem";
import { getUser, isSuperAdmin } from "../../Utils/UserUtils";
import { ReactComponent as Add } from "../../Images/SVG/add_white.svg";
import CreateUserModal from "./UserModal";

const UserList = () => {
  const [state, setState] = useState({
    userList: [],
    downloadCSVurl: "",
    loaded: false,
    showCreateModal: false,
    formError: false,
    userGroups: [],
    filerList: false,
    updateUser: false,
  });

  const getUserList = async () => {
    const { data } = await getApi(USER_LIST, {});
    const downloadCSVarray = [
      ["Gesperrt", "Name", "Benutzer"],
      ...data.map((user) => [
        user.blocked ? "Ja" : "Nein",
        `${user.firstName} ${user.lastName}`,
        user.username,
      ]),
    ].join("\r\n");

    const downloadCSVblob = new Blob([downloadCSVarray], {
      type: "text/csv;charset=utf-8;",
    });

    const downloadCSVurl = window.URL.createObjectURL(downloadCSVblob);

    let userGroups = [];
    data.forEach((item) => {
      if (item.groupName) {
        if (!userGroups.includes(item.groupName)) {
          userGroups.push(item.groupName);
        }
      }
    });

    setState({
      userList: data,
      downloadCSVurl,
      loaded: true,
      userGroups: userGroups,
    });
  };

  const blockUser = async (uid) => {
    const { data } = await postApi(USER_BLOCK, {
      rest: {
        blockUser: {
          uid,
          pid: REACT_APP_FE_USER_FOLDER,
        },
      },
    });
    setState({
      loaded: false,
    });
  };

  const deleteUser = async (uid) => {
    const { data } = await postApi(USER_DELETE, {
      rest: {
        deleteUser: {
          uid,
          pid: REACT_APP_FE_USER_FOLDER,
        },
      },
    });
    setState({
      loaded: false,
    });
  };

  const saveUser = async (userObject) => {
    const { data, error } = await postApi(USER_CREATE, {
      rest: {
        userToCreate: userObject,
      },
    });

    if (error) {
      setState({
        ...state,
        formError: "Die E-Mail-Adresse wird schon verwendet.",
      });
    } else {
      setState({
        ...state,
        showCreateModal: false,
        loaded: false,
      });
    }
  };

  const updateUser = async (userObject) => {
    const { data, error } = await postApi(USER_UPDATE, {
      rest: {
        userToUpdate: userObject,
      },
    });
    if (error) {
      setState({
        ...state,
        formError: "error",
      });
    } else {
      setState({
        ...state,
        showCreateModal: false,
        loaded: false,
      });
    }
  };

  const editUser = (userObject) => {
    setState({
      ...state,
      showCreateModal: true,
      updateUser: userObject,
    });
  };

  const closeCreateUserModal = () => {
    setState({
      ...state,
      showCreateModal: false,
    });
  };

  const openCreateModal = () => {
    setState({
      ...state,
      showCreateModal: true,
      updateUser: false,
    });
  };

  function renderList() {
    if (state.loaded === false) {
      getUserList();
    }

    const myOwn = getUser();

    let userList = state.userList;

    if (state.filerList) {
      userList = userList.filter((item) => {
        return item.groupName === state.filerList;
      });
    }
    if (!userList) {
      return <></>;
    }

    return (
      <div className="user-list">
        {userList.map((item) => {
          if (myOwn.userObject.uid === item.uid) {
            return null;
          }

          return (
            <ListItem
              key={short.uuid()}
              item={item}
              blockUser={blockUser}
              deleteUser={deleteUser}
              editUser={editUser}
            />
          );
        })}
      </div>
    );
  }

  function changeFilter(e) {
    let value = e.target.value;
    if (value === "empty") {
      value = false;
    }

    setState({
      ...state,
      filerList: value,
    });
  }

  function renderGroupSelect() {
    if (state.loaded === false) {
      return <></>;
    }

    if (isSuperAdmin() && state.userGroups.length > 1) {
      return (
        <select id="listFilter" className="group-select" onChange={changeFilter}>
          <option value="empty">----</option>
          {state.userGroups.map((item) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      );
    }
  }

  return (
    <section id="pageContent">
      <div className="row justify-content-center mb-medium">
        <div className="col-xl-10 col-lg-12">
          <BackLink />
          <PageHeadline
            showServiceInformation={false}
            headline="Benutzer verwalten"
            layout="default"
          />
          <div className="col-12 card profile-section">
            <div className="row">
              <div className="col-4">
                <h3>
                  <span className="h3-dark ">Benutzer</span>
                </h3>
              </div>
              <div className="col-4"></div>
              <div className="col-4">
                <span className="float-right">{renderGroupSelect()}</span>
              </div>
            </div>
            {renderList()}
            <div className="row">
              <div className="col-4">
                <button
                  className="btn btn-small"
                  type="button"
                  onClick={() => {
                    openCreateModal();
                  }}
                >
                  <Add className="mr-small" />
                  <span>Neuen Benutzer erstellen</span>
                </button>
              </div>
              <div className="col-4 d-flex align-items-center">
                <a
                  href={state.downloadCSVurl}
                  download="Benutzer.csv"
                  className="link btn btn-small d-flex align-items-center"
                >
                  EXPORT
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateUserModal
        show={state.showCreateModal}
        closeModal={closeCreateUserModal}
        saveUser={saveUser}
        updateUser={updateUser}
        errors={state.formError}
        userObject={state.updateUser}
      />
    </section>
  );
};

export default UserList;
