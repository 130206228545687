import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Routes } from "../Constants";
import { isAuthenticated } from "../Utils/UserUtils";
import Login from "../Components/User/Login";

const LoginContainer = ({ userInput }) => {
  const renderRedirect = () => {
    return isAuthenticated() ? <Redirect to={Routes.DASHBOARD} userInput={userInput} /> : null;
  };

  return (
    <div>
      {renderRedirect()}
      <Login />
    </div>
  );
};

LoginContainer.propTypes = {
  userInput: PropTypes.instanceOf(Object),
};

export default LoginContainer;
