import React from "react";
import * as short from "short-uuid";
import { Row, Col, Card } from "react-bootstrap";

const InvestmentTeam = (props) => {
  function renderContact(contact) {
    const name = `${contact.firstName} ${contact.lastName}`;
    return (
      <div>
        <div className="figureImg text-center">
          <img src={contact.image.publicUrl} alt={name} />
        </div>
        <div className="mt-medium">
          <div className="label">{name}</div>
          <div className="position mt-small">{contact.position}</div>
        </div>
      </div>
    );
  }

  function renderDummy(bool) {
    if (bool) {
      return <Col key={short.uuid()} />;
    }
  }

  function renderContacts(contacts) {
    const needDummy = contacts.length % 2 !== 0;

    return (
      <Col key={short.uuid()}>
        <Row>
          {contacts.map(function (contact) {
            return <Col key={short.uuid()}>{renderContact(contact)}</Col>;
          })}
          {renderDummy(needDummy)}
        </Row>
      </Col>
    );
  }

  function renderTeam(team) {
    const numberOfContract = team.content.contacts.length;
    const colspan = numberOfContract > 2 ? 2 * numberOfContract : 4;

    return (
      <Col key={short.uuid()} xs={colspan} className="mb-medium">
        <Card>
          <h3>{team.content.header}</h3>
          <Row>{renderContacts(team.content.contacts)}</Row>
        </Card>
      </Col>
    );
  }

  return (
    props.teams && (
      <div className={`${props.classes} cards invest-team`}>
        <Row>{props.teams.map((team) => renderTeam(team))}</Row>
      </div>
    )
  );
};

export default InvestmentTeam;
