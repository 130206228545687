import React from "react";
import PreFooter from "../Footer/PreFooter";
import BackLink from "../Common/BackLink";
import { Text } from "../../Constants";

const Imprint = () => {
  return (
    <section id="imprint" className="meta">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-md-10 col-xs-12">
          <BackLink />
          <h1>{Text.IMPRINT}</h1>
        </div>
      </div>

      <div className="row justify-content-center mt-xlarge mb-xxlarge">
        <div className="col-lg-10 col-md-10 col-xs-12 separator" />
      </div>

      <div className="row justify-content-center imprint">
        <div className="col-lg-4 col-md-10">
          <h3>
            Dies ist ein gemeinsamer Webauftritt der LAIC Capital GmbH und der LAIC
            Vermögensverwaltung GmbH.
          </h3>
        </div>
        <div className="col-lg-6 col-md-10">
          <p>
            <span className="h5">LAIC Capital GmbH</span>
            An der Alster 42
            <br />
            20099 Hamburg
          </p>
          <p>
            Tel: +49 (0)40 32 56 78-900
            <br />
            Fax: +49 (0)40 32 56 78-999
            <br />
            E-Mail: info(at)laic.de
            <br />
            Website: <a href="https://www.laic.de">www.laic.de</a>
            <br />
          </p>
          <p>
            Alle Telefon- und Telefax-Nummern sind normale Ortsnetzrufnummern. Die Kosten hängen
            ausschließlich von Ihrem gewählten Telefon- bzw. Mobilfunktarif ab.
          </p>
          <p>
            <span className="h5">Vertretungsberechtigte:</span>
            Geschäftsführer: Achim Plate, Christian Sievers
          </p>
          <p>
            <span className="h5">Handelsregister</span>
            Amtsgericht Hamburg HRB 158044
          </p>
          <p>
            <span className="h5">USt-IdNr.</span>
            DE327954478
          </p>
          <span className="mt-small">&nbsp;</span>
          <p>
            <span className="h5">Verantwortlicher im Sinne von § 55 Abs. 2 RStV:</span>
            Christian Sievers
          </p>
          <p>
            <span className="h5">Erlaubnisse</span>
            Versicherungsmakler mit Erlaubnis nach § 34d Abs. 1 GewO
            <br />
            Finanzanlagenvermittler mit Erlaubnis nach § 34f Abs. 1 S. 1 GewO
          </p>
          <p>
            <span className="h5">Zuständige Aufsichtsbehörde</span>
            Handelskammer Hamburg
            <br />
            Adolphsplatz 1
            <br />
            20457 Hamburg
          </p>
          <p>
            <span className="h5">Verbraucherschlichtungsstelle</span>
            Die zuständige Verbraucherschlichtungsstelle ist:
            <br />
            <br />
            Versicherungsombudsmann e.V.
            <br />
            Postfach 08 06 32
            <br />
            10006 Berlin
            <br />
            <br />
            Telefonnummer: 0800 3696000 (kostenfrei aus deutschen Telefonnetzen)
            <br />
            Faxnummer: 0800 3699000 (kostenfrei aus deutschen Telefonnetzen)
            <br />
            Website:{" "}
            <a href="http://www.versicherungsombudsmann.de">
              http://www.versicherungsombudsmann.de
            </a>
          </p>
          <p>
            <span className="h5">
              Europäische Plattform für Online-Streitbeilegung (OS-Plattform)
            </span>
            Unter{" "}
            <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>{" "}
            erreichen sie die von der Europäischen Kommission bereitgestellte Europäische Plattform
            für Online-Streitbeilegung (OS-Plattform).
            <br />
            <br />
            Diese Plattform dient der außergerichtlichen Streitbeilegung im Bereich der
            Online-Verträge von Unternehmern mit Verbrauchern.
          </p>
          <p>
            <span className="h5">LAIC Vermögensverwaltung GmbH</span>
            LAIC Vermögensverwaltung GmbH
            <br />
            An der Alster 42
            <br />
          </p>
          <p>
            Telefonnummer: + 49 40 32 56 78 – 900
            <br />
            Faxnummer: + 49 40 32 56 78 – 999
            <br />
            E-Mail-Adresse: info(at)laic.de
            <br />
            Website: <a href="https://www.laic.de">www.laic.de</a>
          </p>
          <p>
            Alle Telefon- und Telefax-Nummern sind normale Ortsnetzrufnummern. Die Kosten hängen
            ausschließlich von Ihrem gewählten Telefon- bzw. Mobilfunktarif ab.
          </p>
          <p>
            <span className="h5">Vertretungsberechtigte</span>
            Geschäftsführer: Herr Hans-Jürgen Röwekamp
          </p>
          <p>
            <span className="h5">Handelsregister</span>
            Amtsgericht Hamburg HRB 162129
          </p>
          <p>
            <span className="h5">USt-IdNr.</span>
            DE 328984013
          </p>
          <p>
            <span className="h5">Verantwortlicher im Sinne von § 55 Abs. 2 RStV</span>
            Hans-Jürgen Röwekamp
          </p>
          <p>
            <span className="h5">Zuständige Aufsichtsbehörde</span>
            Bundesanstalt für Finanzdienstleistungsaufsicht
            <br />
            Graurheindorfer Straße 108
            <br />
            53117 Bonn
            <br />
            und
            <br />
            Marie-Curie-Straße 24-28
            <br />
            60439 Frankfurt
            <br />
            <a href="https://www.bafin.de">www.bafin.de</a>
          </p>
          <p>BaFin-ID: 5509549-1</p>
          <p>
            <span className="h5">Offenlegungsbericht</span>
            Für die LAIC Vermögensverwaltung GmbH gilt keine Offenlegungspflicht gem. § 16
            InstitutsVergV, da sie als nicht-bedeutendes Institut gem. § 25n KWG mit einer über die
            letzten drei abgeschlossenen Geschäftsjahre gesehenen durchschnittlichen Bilanzsumme von
            unter 3 Mrd. EUR einzuordnen ist.
          </p>
          <p>
            <span className="h5">Bestmögliche Ausführung von Kundenaufträgen</span>
          </p>
          <p>
            <span className="h5">Rechtsgrundlagen:</span>
            Die Rechtsgrundlagen für die bestmögliche Ausführung von Kundenaufträgen ergeben sich
            aus § 82 WpHG. Danach haben Finanzdienstleistungsinstitute, die Aufträge von Kunden für
            den Kauf oder Verkauf von Finanzinstrumenten ausführen, alle angemessenen Vorkehrungen
            zu treffen, insbesondere Grundsätze zur Auftragsausführung festzulegen und regelmäßig zu
            überprüfen, um das bestmögliche Ergebnis für ihre Kunden zu erreichen, sowie
            sicherzustellen, dass die Ausführung jedes einzelnen Kundenauftrags nach Maßgabe dieser
            Grundsätze vorgenommen wird.
          </p>
          <p>
            Unser Institut leitet alle Aufträge zur Ausführung an Dritte weiter. Dies sind
            Depotbanken (ausführende Einrichtungen), bei der unsere Kunden ihre Konten und Depots
            unterhalten.
          </p>
          <p>
            Die durch uns vorgenommene Beauftragung ausführender Einrichtungen mit der Ausführung
            von Aufträgen für unsere Kunden erfolgt bei Erteilung von Wertpapieraufträgen im Rahmen
            der Finanzportfolioverwaltung. Dabei haben wir nach Artikel 65 der DVO 2017/565 im
            bestmöglichen Interesse unserer Kunden zu handeln und alle hinreichenden Maßnahmen zu
            treffen, um für unsere Kunden das bestmögliche Ergebnis zu erzielen.
          </p>
          <p>
            <span className="h5">Ausführende Einrichtungen und deren Ausführungsplätze</span>
            Um diesen Vorgaben nachzukommen, wählen wir die ausführenden Einrichtungen so aus, dass
            deren Ausführungsgrundsätze die bestmögliche Auftragsausführung gewährleisten,
            insbesondere das bestmögliche Ergebnis für unsere Kunden erreicht wird. Das bestmögliche
            Ergebnis orientiert sich am Gesamtentgelt, das sich aus dem Preis für das
            Finanzinstrument und sämtlichen mit der Auftragsausführung verbundenen Kosten
            (einschließlich der Gebühren und Entgelte des Ausführungsplatzes, Kosten für Clearing
            und Abwicklung sowie allen sonstigen Gebühren) ergibt.
          </p>
          <p>
            Die Weiterleitung von Aufträgen zur Ausführung von Aufträgen für Kunden erfolgte in den
            letzten 12 Monaten an folgende ausführende Einrichtungen:
          </p>
          <p>
            <ul>
              <li>Baader Bank AG, Unterschleißheim</li>
            </ul>
          </p>
          <p>
            <span className="h5">Eigene Ausführungsgrundsätze</span>
            Zusätzlich zu den Ausführungsgrundsätzen der ausführenden Einrichtungen haben wir eigene
            „Grundsätze zur Auftragsdurchführung“ aufgestellt, in denen wir die von uns ausgewählten
            Einrichtungen benennen, an die wir Wertpapieraufträge im Rahmen der
            Finanzportfolioverwaltung erteilen bzw. Kundenaufträge zur Ausführung weiterleiten.
            Diese sind als schriftliche Information Bestandteil des Vermögensverwaltungsvertrags mit
            dem Kunden. Dadurch stellen wir sicher, dass die Kunden vor der erstmaligen Erbringung
            von Wertpapierdienstleistungen durch uns über unsere Ausführungsgrundsätze unterrichtet
            werden und mit ihrer Unterschrift ihre Zustimmung zu diesen erteilen (Stand April 2020).
          </p>
          <p>
            <span className="h5">Verbraucherschlichtungsstelle</span>
            Der Verband unabhängiger Vermögensverwalter Deutschland e.V. (VuV) hat eine
            Schlichtungsstelle nach Maßgabe der EU-Richtlinie Nr. 2013/EU vom 21.05.2013 über die
            Alternative Streitbeilegung eingerichtet. Vor der VuVOmbudsstelle sollen Streitigkeiten
            zwischen Verbrauchern und Mitgliedern des VuV im Zusammenhang mit
            Finanzdienstleistungsgeschäften in einem außergerichtlichen Schlichtungsverfahren
            beigelegt werden können. Die LAIC Vermögensverwaltung GmbH ist Mitglied des VuV und
            damit dieser Schlichtungsstelle angeschlossen.
          </p>
          <p>
            Für den Fall, dass einer Kundenbeschwerde zwischen den Parteien nicht unmittelbar
            abgeholfen werden kann, wird damit die Möglichkeit eröffnet, kundenseits die
            Schlichtungsstelle anzurufen. Das Schlichtungsverfahren ist für den Antragsteller
            kostenfrei. Die Anschrift der VuV-Ombudsstelle lautet:
          </p>
          <p>
            VuV-Ombudsstelle
            <br />
            Stresemannallee 30
            <br />
            60596 Frankfurt am Main
            <br />
          </p>
          <p>
            Weitere Informationen zur VuV-Ombudsstelle (u.a. Kommunikationsdaten, Verfahrensordnung,
            Antragsformular) erhalten Sie unter{" "}
            <a href="https://vuv-ombudsstelle.de">https://vuv-ombudsstelle.de</a>{" "}
          </p>
          <p>
            <span className="h5">
              Europäische Plattform für Online-Streitbeilegung (OS-Plattform)
            </span>
            Unter{" "}
            <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>{" "}
            erreichen Sie die von der Europäischen Kommission bereitgestellte Europäische Plattform
            für Online-Streitbeilegung (OS-Plattform).
          </p>
          <p>
            Diese Plattform dient der außergerichtlichen Streitbeilegung im Bereich der
            Online-Verträge von Unternehmern mit Verbrauchern.
          </p>
        </div>
      </div>
      <div className="row justify-content-center mt-xlarge mb-xxlarge">
        <div className="col-lg-10 col-md-10 col-xs-12 separator" />
      </div>
      <div className="row justify-content-center">
        <div className="col-4">
          <PreFooter />
        </div>
      </div>
    </section>
  );
};

export default Imprint;
