export const COLOR_VIOLET = "violet";
export const COLOR_PURPLE = "purple";
export const COLOR_ORANGE = "orange";
export const COLOR_GREEN = "green";
export const COLOR_BLUE = "blue";

export const baseColorHex = {
  violet: "125, 87, 161",
  purple: "199, 26, 136",
  orange: "235, 102, 23",
  green: "68, 167, 31",
  bue: "0, 140, 214",
};

export const chartColors = ["violet", "purple", "orange", "green", "bue"];
