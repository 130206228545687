import React, { useEffect, useState } from "react";
import * as short from "short-uuid";
import { connect } from "react-redux";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { ReactComponent as ArrowOut } from "../../Images/SVG/arrow_out_negativ.svg";
import { loadFooterNavigation } from "../../Redux/NavigationRedux";
import DownloadEmailModal from "../ContentElements/Downloads/DownloadEmailModal";
import ModalItem from "./ModalItem";
import MailItem from "./MailItem";
import PhoneItem from "./PhoneItem";
import ChatItem from "./ChatItem";
import ExternalItem from "./ExternalItem";
import Notification from "./Notification";
import GetStartedModal from "../ContentElements/GetStartedModal";

import { getGroupId, getGroupName } from "../../Utils/UserUtils";

const Footer = (props) => {
  const [cookies, setCookie] = useCookies(["showNotifications"]);

  function renderFooterNav() {
    const items = props.footerNavigation;
    if (items) {
      const footerNav = [];
      items.forEach((item) => {
        if (item.link.startsWith("tel:")) {
          footerNav.push(<PhoneItem key={short.uuid()} link={item.link} title={item.title} />);
        } else if (item.link.startsWith("mailto:")) {
          footerNav.push(<MailItem key={short.uuid()} link={item.link} title={item.title} />);
        } else if (item.target === "_modal") {
          footerNav.push(<ModalItem key={short.uuid()} title={item.title} link={item.link} />);
        } else if (item.target === "_chat") {
          footerNav.push(<ChatItem key={short.uuid()} title={item.title} />);
        } else if (item.target === "_external") {
          footerNav.push(<ExternalItem key={short.uuid()} link={item.link} title={item.title} />);
        } else {
          // console.error('Footer: Unknown item:', item);
        }
      });
      return footerNav;
    }

    return <span />;
  }

  const renderFooterLinks = () => {
    switch (getGroupId()) {
      case 9:
      case 8:
        return (
          <a
            href="http://www.vki-smartinvest.de/"
            target="_blank"
            className="float-right"
            rel="noopener noreferrer"
          >
            <span>
              <ArrowOut height="11px" />
            </span>{" "}
            vki-smartinvest.de
          </a>
        );
      default:
        return (
          <>
            <a
              href="https://www.laic.de/"
              target="_blank"
              className="float-right"
              rel="noopener noreferrer"
            >
              <span>
                <ArrowOut height="11px" />
              </span>{" "}
              laic.de
            </a>
            <a
              href="https://www.lloydfonds.de/"
              target="_blank"
              className="float-right"
              rel="noopener noreferrer"
            >
              <span>
                <ArrowOut height="11px" />
              </span>{" "}
              lloydfonds.de
            </a>
          </>
        );
    }
  };

  const [isFooterNavigationLoaded, setFooterNavigationLoaded] = useState(false);

  useEffect(() => {
    if (!isFooterNavigationLoaded) {
      props.loadFooterNavigation();
      setFooterNavigationLoaded(true);
    }
  }, [isFooterNavigationLoaded, props]);

  function renderNotifications() {
    if (typeof props.footerNavigation !== "undefined") {
      const notification = props.footerNavigation.filter((item) => {
        return item.target === "_notification";
      });

      if (notification.length > 0) {
        if (!cookies.showNotifications) {
          return <Notification page={notification[0].link} />;
        }
      }
    }

    return null;
  }

  return (
    <div key={short.uuid()} className="footer">
      <Helmet async={false}>
        <script type="text/javascript">
          {`
          piAId = '775843';
          piCId = '79634';
          piHostname = 'pi.pardot.com';
          (function() {
          function async_load(){
          var s = document.createElement('script'); s.type = 'text/javascript';
          s.setAttribute("data-cookieconsent", "ignore");
          s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
          var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
          }
          if(window.attachEvent) { window.attachEvent('onload', async_load); }
          else { window.addEventListener('load', async_load, false); }
          })();
        `}
        </script>
      </Helmet>

      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6 service-links">{renderFooterNav()}</div>
        <div className="col-lg-4 ">{renderFooterLinks()}</div>
      </div>
      <DownloadEmailModal />
      <GetStartedModal />
      {renderNotifications()}
    </div>
  );
};

Footer.propTypes = {
  footerNavigation: PropTypes.instanceOf(Array),
  loadFooterNavigation: PropTypes.func.isRequired,
};

const mapStateToProps = ({ navigation: { footerNavigation } }) => {
  return {
    footerNavigation,
  };
};

export default connect(mapStateToProps, { loadFooterNavigation })(Footer);
