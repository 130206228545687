import * as short from "short-uuid";
import React from "react";
import DownloadCardItem from "../DownloadCardItem";

const Downloads = (props) => {
  const renderColumn = (side) => {
    const renderDownloadDocumentsSection = (section, option) => {
      return (
        <>
          <div className="download-card-section">
            <h5>{option === "required" ? "Pflichtunterlagen" : "Vertriebsunterlagen"}</h5>
            {section.map((el) => (
              <DownloadCardItem key={short.uuid()} item={el} />
            ))}
          </div>
        </>
      );
    };

    const renderDownloadCard = (item) => {
      return (
        <React.Fragment key={short.uuid()}>
          <div className="card download-card col-12">
            <h3>{item.content.header}</h3>
            <h5>{item.content.subheader}</h5>
            {renderDownloadDocumentsSection(item.content.requiredDocuments, "required")}
            {renderDownloadDocumentsSection(item.content.salesDocuments, "sales")}
          </div>
        </React.Fragment>
      );
    };

    const sideElements = props.items.filter((value, index, arr) => {
      if (side === "a") {
        return index % 2 === 0;
      }
      return index % 2 !== 0;
    });

    return sideElements.map((el) => renderDownloadCard(el));
  };

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-6">{renderColumn("a")}</div>
        <div className="col-6">{renderColumn("b")}</div>
      </div>
    </div>
  );
};

export default Downloads;
