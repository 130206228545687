import React, { useState } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as Next } from "../../Images/SVG/slider_button_next.svg";
import { ReactComponent as Prev } from "../../Images/SVG/slider_button_prev.svg";

const Slider = ({ classes, headline, slides }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const renderSingleSlide = () => {
    const item = slides[activeSlide];

    if (item.image) {
      return (
        <div className="row justify-content-center">
          <div className="col-3">
            <img src={item.image.publicUrl} alt="" />
          </div>
          <div className="col-9">
            <h3>{item.header}</h3>
            <div className="copy" dangerouslySetInnerHTML={{ __html: item.bodytext }} />
          </div>
        </div>
      );
    }

    return null;
  };

  const onClickSliderButton = (direction) => {
    const slidesCount = slides.length;

    if (direction === "prev") {
      if (activeSlide === 0) {
        setActiveSlide(slidesCount - 1);
      } else {
        setActiveSlide(activeSlide - 1);
      }
    }
    if (direction === "next") {
      if (activeSlide === slidesCount - 1) {
        setActiveSlide(0);
      } else {
        setActiveSlide(activeSlide + 1);
      }
    }
  };

  const renderSliderButton = (direction) => {
    if (slides.length < 2) {
      return null;
    }

    if (direction === "prev") {
      return <Prev onClick={() => onClickSliderButton("prev")} />;
    }

    return <Next onClick={() => onClickSliderButton("next")} />;
  };

  const elementClasses = `slider row ${classes}`;

  return (
    <div className={elementClasses}>
      <div className="col-12">
        <div className="panel">
          <div className="row justify-content-center">
            <div className="col-lg-10 mt-xxlarge">
              <h2>{headline}</h2>
            </div>
          </div>
          <div className="row justify-content-center mt-xxlarge">
            <div className="col-1">{renderSliderButton("prev")}</div>
            <div className="col-8">
              <CSSTransition in timeout={200} classNames="my-node">
                {renderSingleSlide()}
              </CSSTransition>
            </div>
            <div className="col-1">{renderSliderButton("next")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Slider.propTypes = {
  classes: PropTypes.string,
  headline: PropTypes.string,
  slides: PropTypes.instanceOf(Array),
};

export default Slider;
