import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Text } from "../../Constants";
import { USER_PASSWORD_RESET } from "../../Constants/ApiConstants";
import BackLink from "../Common/BackLink";
import PreFooter from "../Footer/PreFooter";
import MetaFooter from "../Footer/MetaFooter";
import { postApi } from "../../Utils/ApiUtils";

const validator = require("email-validator");

const PasswordReset = () => {
  const [state, setState] = useState({
    email: "",
    showEmailSend: false,
  });

  const history = useHistory();

  const handleSubmit = async (start) => {
    if (start === true) {
      if (validator.validate(state.email)) {
        const { payload, error } = await postApi(USER_PASSWORD_RESET, {
          rest: {
            resetObject: {
              email: state.email,
            },
          },
        });

        if (error === undefined) {
          setState({ ...state, showEmailSend: true });
        }
      }
    } else {
      history.push("/login");
    }
  };

  const renderPasswortResetEntry = () => (
    <div className="panel pd-large mt-xlarge">
      <BackLink />
      <h3 className="mt-xlarge">Passwort vergessen?</h3>
      <p className="mt-medium">
        Bitte geben Sie Ihre E-Mail Adresse ein, um ein neues Passwort anzufordern.
      </p>
      <form className="form mt-xlarge">
        <div className="form-group">
          <label htmlFor="email">{Text.EMAIL}</label>
          <input
            type="email"
            name="email"
            className="form-control form-control-xs form-control-custom text-light"
            placeholder={Text.EMAIL}
            value={state.email}
            onChange={(e) => setState({ ...state, email: e.target.value })}
          />
        </div>
        <div className="form-group mt-xlarge">
          <input
            type="button"
            className="btn"
            value="Neues Passwort Anfordern"
            onClick={() => handleSubmit(true)}
          />
        </div>
      </form>
    </div>
  );

  const renderPasswortResetSuccess = () => (
    <div className="panel pd-large mt-xlarge">
      <h3 className="mt-xlarge">Passwort erfolgreich versendet</h3>
      <p className="mt-medium">
        Wir haben Ihnen ein neues Passwort an die angegebene E-Mail-Adresse gesendet.
      </p>
      <div className="mt-xlarge">
        <input
          type="submit"
          className="btn"
          value="Zurück zum Login"
          onClick={() => handleSubmit(false)}
        />
      </div>
    </div>
  );

  return (
    <div className="row justify-content-center full-height password-reset">
      <div className="login align-self-center">
        <h1 className="text-center">Ihr Partnerportal</h1>
        {state.showEmailSend ? renderPasswortResetSuccess() : renderPasswortResetEntry()}
        <PreFooter />
        <MetaFooter />
      </div>
    </div>
  );
};

export default withRouter(PasswordReset);
