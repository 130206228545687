import React, { useRef, useEffect } from "react";
import short from "short-uuid";
import PropTypes from "prop-types";
import { createPerformanceChart } from "../../../Utils/ChartUtils";
import { chartColors } from "../../../Constants/ChartConstants";

const Performance = (props) => {
  const { classes, content } = props;

  const elementClasses = `row fonds-performance ${classes}`;
  const chart = useRef(null);

  const rand = Math.random();

  /**
   * take care that we get an array to operate with
   * @returns {[]}
   */
  function getPerformance() {
    const performance = {
      fonds: [],
      data: [],
    };

    if (content.displayType === "funds") {
      if (content.fonds && content.fonds.length > 0) {
        content.fonds.forEach((item, index) => {
          if (index > 4) {
            return;
          }
          const fond = {
            isin: item.isin,
            name: item.name,
            color: chartColors[index],
          };
          performance.fonds.push(fond);
          item.performance.forEach((dataSet) => {
            const found = performance.data.filter((data) => data.date === dataSet.date);
            if (found.length > 0) {
              found[0][item.isin] = dataSet.performancePercent;
            } else {
              const newItem = [];
              newItem.date = dataSet.date;
              newItem[item.isin] = dataSet.performancePercent;
              performance.data.push(newItem);
            }
          });
        });
      }
    } else {
      if (content.depots && content.depots.length > 0) {
        content.depots.forEach((item, index) => {
          if (index > 4) {
            return;
          }
          const fond = {
            isin: item.depot_id,
            name: item.name,
            color: chartColors[index],
          };
          performance.fonds.push(fond);
          item.performance.forEach((dataSet) => {
            const found = performance.data.filter((data) => data.date === dataSet.date);
            if (found.length > 0) {
              found[0][item.isin] = dataSet.performancePercent;
            } else {
              const newItem = [];
              newItem.date = dataSet.date;
              newItem[item.depot_id] = dataSet.performancePercent;
              performance.data.push(newItem);
            }
          });
        });
      }
    }

    performance.data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return performance;
  }

  useEffect(() => {
    chart.current = createPerformanceChart(`performanceChart-${rand}`, getPerformance());

    return () => {};
  }, []);

  function renderChart() {
    return <div id={`performanceChart-${rand}`} className="performancec_chart mt-large" />;
  }

  return (
    <div key={short.uuid()} className={elementClasses}>
      <div className="col-12">
        <div className="card">
          <h3>{content.header}</h3>
          {renderChart()}
        </div>
      </div>
    </div>
  );
};

Performance.propTypes = {
  classes: PropTypes.string,
  content: PropTypes.instanceOf(Object),
};

export default React.memo(Performance);
