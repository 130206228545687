import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loadModalPage } from "../../Redux/PageRedux";
import ModalButton from "./ModalButton";

const ModalItem = (props) => {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      props.loadModalPage(props.link);
      setLoaded(true);
    }
  }, [isLoaded, props]);

  return <ModalButton modal={props.modal} title={props.title} />;
};

const mapStateToProps = (state) => {
  return {
    modal: state.page.modal ? state.page.modal.modalpage : null,
    isFetching: !state.page.modal,
  };
};

export default connect(mapStateToProps, { loadModalPage })(withRouter(ModalItem));
