/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Close } from "../../Images/SVG/close.svg";
import { postApi } from "../../Utils/ApiUtils";
import { USER } from "../../Constants/ApiConstants";
import { getUser } from "../../Utils/UserUtils";

const PasswordChangeModal = (props) => {
  const [generalError, setGeneralError] = useState(false);
  const [inputData, setInputData] = useState({
    pass: "",
    passWdh: "",
  });

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    props.hideModal();
  };

  const validateInput = () => {
    if (inputData.pass === inputData.passWdh) {
      return true;
    }
    setGeneralError(true);
    return false;
  };

  const postPasswordChangeRequest = async () => {
    if (!validateInput()) {
      return;
    }
    const user = getUser();

    const { payload, error } = await postApi(`${USER}${user.userObject.username}/password_update`, {
      rest: {
        updateObject: {
          password: inputData.pass,
        },
      },
    });

    if (error === undefined) {
      handleClose();
    } else {
      setGeneralError(true);
    }
  };

  return (
    <Modal
      show={props.showModal}
      keyboard
      className="custom-modal-style password-change-modal"
      animation
    >
      <Modal.Header>
        <div
          className="col-12 mb-medium d-flex justify-content-end"
          id="close"
          style={{ cursor: "pointer" }}
          onClick={handleClose}
        >
          <Close />
        </div>
      </Modal.Header>
      <Modal.Body>
        <section id="modalContent">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="row justify-content-center mb-medium">
                <div className="col-xl-8 col-lg-10 col-md-12 password-change-modal-head">
                  <h1>Passwort ändern</h1>
                  <h4>Ändern Sie ihr Passwort nur, wenn Sie sich zum ersten mal anmelden </h4>

                  <div className="password-change-modal-input">
                    <label>Neues Passwort *</label>
                    <input
                      type="password"
                      value={inputData.pass}
                      onChange={(e) => {
                        setInputData({ ...inputData, pass: e.target.value });
                      }}
                    />
                  </div>

                  <div className="password-change-modal-input">
                    <label>Neues Passwort wiederholen *</label>
                    <input
                      type="password"
                      value={inputData.passWdh}
                      onChange={(e) => {
                        setInputData({ ...inputData, passWdh: e.target.value });
                      }}
                    />
                  </div>

                  <div className="required-hint">
                    <span>* Pflichtfelder</span>
                  </div>

                  <div className="d-flex flex-row-reverse password-change-modal-cta">
                    <button
                      type="button"
                      className="ml-xlarge btn btn-small"
                      onClick={() => postPasswordChangeRequest()}
                    >
                      <span>SPEICHERN</span>
                    </button>
                    <button
                      type="button"
                      className="ml-xlarge btn btn-blue-transparent btn-small"
                      onClick={() => handleClose()}
                    >
                      <span>ABBRECHEN</span>
                    </button>
                  </div>

                  {generalError ? (
                    <span className="password-change-modal-error">
                      Beim der Übermittlung der Daten ist ein Fehler aufgetreten.
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordChangeModal;
