/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from "react";
import IconDownloadAdd from "../../Images/SVG/download_add.svg";
import IconDownloadAction from "../../Images/SVG/download_action.svg";

const DownloadLinkCardItem = (props) => {
  return (
    <>
      <div className="">
        <div className="download-card-item justify-content-between ">
          <div className="row download-card-item-left-wrapper">
            <div className="download-card-item-icon float-left col-1">
              <img src={IconDownloadAdd} alt="" />
            </div>
            <div className="download-card-item-title float-left col-9">
              {props.item.title ?? props.item.url}
            </div>
            <div className="col-2">
              <a
                href={props.item.url}
                className="download-card-item-cta-dl"
                download
                target="_blank"
                rel="noreferrer"
              >
                <img src={IconDownloadAction} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr className={props.rulerClass} />
    </>
  );
};

export default DownloadLinkCardItem;
