export const { REACT_APP_API_BASE_URL, REACT_APP_FE_USER_FOLDER } = process.env;

/* Static Page Routes */
export const PUBLIC_PRIVACY = "/public/privacy";
export const DASHBOARD_ROUTE = "/index.php";

/* Static Elements */
export const TOP_NAVIGATION = "/index.php?type=834";
export const FOOTER_NAVIGATION = "/index.php?type=835";

/* Common Form Routes */
export const GET_STARTED =
  "/index.php?type=837&rest[controller]=GetStartedForm&rest[action]=request&no_cache=1";
export const NEWSLETTER_REGISTRATION =
  "/index.php?type=838&rest[action]=request&rest[controller]=NewsletterForm&no_cache=1";
export const MAIL_ONDEMAND = "/index.php?type=836&rest[controller]=AssetMail&rest[action]=render";
export const DOWNLOAD_ONDEMAND =
  "/index.php?type=836&rest[controller]=AssetDownload&rest[action]=render";
export const FILE_UPLOAD = "api/v1/formular/upload";
export const BROCHURE_ORDER = "api/v1/order/form";

/* User Management Routes */
export const USER_AUTHENTICATION = "/api/v1/auth/login";
export const USER_PASSWORD_RESET = "/api/v1/auth/password_reset";
export const USER = "/api/v1/user/";
export const USER_LIST = "/api/v1/user/list";
export const USER_BLOCK = "/api/v1/user/block";
export const USER_CREATE = "/api/v1/user/create";
export const USER_DELETE = "/api/v1/user/delete";
export const USER_UPDATE = "/api/v1/user/update";
