/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";

const CheckMarkSelected = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g>
      <g fill="none" stroke="#008cd6" strokeWidth="2">
        <rect width="30" height="30" rx="6" stroke="none" />
        <rect x="1" y="1" width="28" height="28" rx="5" fill="none" />
      </g>
    </g>
    <g transform="translate(8 8)">
      <line
        id="Linie_97"
        data-name="Linie 97"
        y1="14"
        x2="8"
        transform="translate(6)"
        fill="none"
        stroke="#008cd6"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <line
        id="Linie_107"
        data-name="Linie 107"
        x2="6"
        y2="6"
        transform="translate(0 8)"
        fill="none"
        stroke="#008cd6"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

const CheckMarkUnselected = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g opacity="0.65">
      <g fill="none" stroke="#06293d" strokeWidth="2">
        <rect width="30" height="30" rx="6" stroke="none" />
        <rect x="1" y="1" width="28" height="28" rx="5" fill="none" />
      </g>
    </g>
  </svg>
);

const Checkbox = ({ clean, status, onStatusChanged }) => {
  return (
    <div
      className={clean ? "checkbox-clean" : "checkbox"}
      onClick={() => {
        onStatusChanged(!status);
      }}
    >
      {status ? <CheckMarkSelected /> : <CheckMarkUnselected />}
    </div>
  );
};

Checkbox.propTypes = {
  status: PropTypes.bool,
  clean: PropTypes.bool,
  onStatusChanged: PropTypes.func,
};

export default Checkbox;
