import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import BackLink from "../Common/BackLink";
import PreFooter from "../Footer/PreFooter";
import PageHeadline from "../ContentElements/PageHeadline";
import PasswordChangeModal from "./PasswordChangeModal";
import { getGroupLogo, getUser, getGroupName } from "../../Utils/UserUtils";
import { Routes } from "../../Constants";

const Profile = () => {
  const [user, setUser] = useState();
  const logoUrl = Routes.REACT_APP_BASE_URL + getGroupLogo();
  const groupName = getGroupName();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const userData = getUser();
    setUser(userData);
  }, []);

  return (
    <section id="pageContent">
      <div className="row justify-content-center mb-medium">
        <div className="col-xl-10 col-lg-12">
          <BackLink />
          <PageHeadline showServiceInformation={false} headline="Profil" layout="default" />
          <div className="col-12 card profile-section">
            <h3>
              <span className="h3-dark ">Unternehmensdaten</span>
            </h3>
            <div className="col-12">
              <div className="row">
                <div className="col-8 company">
                  <label>Unternehmen</label>
                  <input type="text" value={groupName} readOnly />
                </div>
                <div className="col-4 company_logo">
                  <img src={logoUrl} className="brand" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 card profile-section personal-data">
            <h3>
              <span className="h3-dark ">Persönliche Daten</span>
            </h3>
            <div className="col-12">
              <div className="row">
                <div className="profile-section-input">
                  <label>Anrede</label>
                  <input type="text" value={user?.userObject?.title ?? ""} readOnly />
                </div>
                <div className="profile-section-input">
                  <label>Vorname</label>
                  <input type="text" value={user?.userObject?.firstName ?? ""} readOnly />
                </div>
                <div className="profile-section-input">
                  <label>Name</label>
                  <input type="text" value={user?.userObject?.lastName ?? ""} readOnly />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 card profile-section">
            <h3>
              <span className="h3-dark ">Zugangsdaten</span>
            </h3>
            <div className="col-12">
              <div className="row justify-content-between">
                <div className="profile-section-input">
                  <label>E-Mail</label>
                  <input type="email" value={user?.userObject?.username ?? ""} readOnly />
                </div>
                <div className="profile-section-input">
                  <label>Passwort</label>
                  <input type="password" value="*************" />
                </div>
              </div>
              <div className="row flex-row-reverse">
                <button
                  onClick={() => setShowModal(true)}
                  type="button"
                  className="ml-xlarge btn btn-blue-transparent btn-small"
                >
                  PASSWORT ÄNDERN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="login mb-xlarge">
          <PreFooter />
        </div>
      </div>
      <PasswordChangeModal showModal={showModal} hideModal={() => setShowModal(false)} />
    </section>
  );
};

export default withRouter(Profile);
