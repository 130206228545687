/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import * as short from "short-uuid";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addToDownloadList } from "../../Redux/DownloadRedux";
import DownloadCardItem from "./DownloadCardItem";
import DownloadLinkCardItem from "./DownloadLinkCardItem";

const MSDACard = (props) => {
  const renderEvent = () => {
    if (props.news[0]) {
      return (
        <div className="card">
          <h3>{props.header_news}</h3>

          <span className="subline">{props.news[0].title}</span>
          <div
            className="copy mt-large"
            dangerouslySetInnerHTML={{ __html: props.news[0].teaser }}
          />
          {renderEventlink(props.news[0])}
        </div>
      );
    }
    return (
      <div className="card">
        <h3>{props.header_news}</h3>
      </div>
    );
  };

  const renderEventlink = (event) => {
    if (event.eventlink.length) {
      return (
        <a
          href={props.news[0].eventlink}
          target="_blank"
          className="external-link"
          rel="noopener noreferrer"
        >
          {props.news[0].eventlinktext}
        </a>
      );
    }
  };

  const renderDownloadList = () => {
    return props.downloads.map((item, index) => renderDownloadItem(item, index));
  };

  const renderDownloadItem = (item, index) => {
    const maxItems = props.downloads.length - 1;
    let rulerClass = "visible";
    if (index === maxItems) {
      rulerClass = "invisible";
    }

    if (item.type === "internal" && item["asset"]) {
      const screenName =
        item.asset.properties.title !== null
          ? item.asset.properties.title
          : item.asset.properties.filename;

      return (
        <DownloadCardItem
          key={short.uuid()}
          title={screenName}
          item={item.asset}
          rulerClass={rulerClass}
        />
      );
    } else if (item.type === "external") {
      return <DownloadLinkCardItem key={short.uuid()} item={item.link} rulerClass={rulerClass} />;
    }

    return <span>nothing to see here</span>;
  };

  return (
    <div className={`download-news-card ${props.classes}`}>
      <div className="row is-flex">
        <div className="col-md-6 mb-large">
          <div className="card download-list">
            <h3>{props.header_downloads}</h3>
            {renderDownloadList()}
          </div>
        </div>
        <div className="col-md-6 mb-large">{renderEvent()}</div>
      </div>
    </div>
  );
};

export default MSDACard;
