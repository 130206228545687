import React from "react";
import "../scss/Custom.scss";
import { withRouter } from "react-router-dom";
import Navigation from "./Common/Navigation";
import { isAuthenticated } from "../Utils/UserUtils";
import Footer from "./Footer/Footer";

const Layout = (props) => {
  function renderNavigation() {
    if (isAuthenticated()) {
      return <Navigation />;
    }
  }

  function renderFooter() {
    if (isAuthenticated()) {
      return <Footer />;
    }
  }

  function renderClasses() {
    if (isAuthenticated()) {
      return "container mb-xxxlarge mt-xxxlarge";
    }

    return "container";
  }

  return (
    <section id="content">
      {renderNavigation()}
      <div className={renderClasses()}>{props.children}</div>
      {renderFooter()}
    </section>
  );
};

export default withRouter(Layout);
