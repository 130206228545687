import React from "react";
import PropTypes from "prop-types";
import * as short from "short-uuid";

const ImageCard = (props) => {
  const inlineStyles = {
    backgroundImage: `url(${props.image})`,
    // backgroundRepeat: 'round',
    backgroundSize: "cover",
  };
  return (
    <div id={short.uuid()} className="col-md-6 col-lg-4">
      <div className="image-card" style={inlineStyles}>
        <div className="row card-image" />
        <div className="row card-label">
          <div className="col-12">
            <h3>
              <span className="h3-white ml-medium-2">{props.headline}</span>
            </h3>
          </div>
        </div>
        <div className="row card-link">
          <button
            className="ml-xlarge btn btn-transparent-white btn-small"
            onClick={() =>
              props.history.push({
                pathname: props.target,
              })
            }
          >
            {props.buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

ImageCard.propTypes = {
  buttonLabel: PropTypes.string,
  image: PropTypes.string,
  target: PropTypes.string,
};

export default ImageCard;
