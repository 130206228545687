import React from "react";
import * as short from "short-uuid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ReactComponent as ArrowOut } from "../../../Images/SVG/arrow_out.svg";
import { ReactComponent as ArrowRight } from "../../../Images/SVG/arrow_right.svg";
import { cleanUrl } from "../../../Utils/TextUtils";
import { openGetStartedModal } from "../../../Redux/GetStartedRedux";
import { getUser } from "../../../Utils/UserUtils";

const Card = ({
  label,
  link,
  target,
  image: { publicUrl },
  openGetStartedModal,
  addAffiliateKey,
  affiliateKeyName,
}) => {
  const renderArrow = () => {
    if (["_blank", "_getStarted"].includes(target)) {
      return <ArrowOut height="11px" />;
    }
    return <ArrowRight height="11px" />;
  };

  const renderLogo = () => {
    if (publicUrl) {
      return <img src={publicUrl} className="logo" alt="" />;
    }

    return null;
  };

  const handeOpenGetStartedModal = (e) => {
    e.preventDefault();

    openGetStartedModal();
  };

  const buildLink = () => {
    let buildedLink = cleanUrl(link);
    if (addAffiliateKey === "1") {
      const user = getUser();
      const affiliatePart = affiliateKeyName + "=" + user.userObject.uid;
      if (buildedLink.includes("?")) {
        buildedLink = buildedLink + "&" + affiliatePart;
      } else {
        buildedLink = buildedLink + "?" + affiliatePart;
      }

      if (buildedLink.includes("#getstart-modal")) {
        buildedLink = buildedLink.replace("#getstart-modal", "");
        buildedLink = buildedLink + "#getstart-modal";
      }
    }

    return cleanUrl(buildedLink);
  };

  const renderLink = () => {
    switch (true) {
      case target === "_getStarted":
        return (
          <a href={buildLink()} target={target} onClick={handeOpenGetStartedModal}>
            <span>{renderArrow()}</span>
            {label}
          </a>
        );
      default:
        return (
          <a href={buildLink()} target={target}>
            <span>{renderArrow()}</span>
            {label}
          </a>
        );
    }
  };

  return (
    <div id={short.uuid()} className="card mb-large">
      <div className="row justify-content-center align-items-center card-image">
        <div className="col-10 text-center">{renderLogo()}</div>
      </div>
      <div className="row justify-content-center">
        <div className="col-9 card-separator" />
      </div>
      <div className="row justify-content-center card-link align-items-center">
        <div className="col-10">{renderLink()}</div>
      </div>
    </div>
  );
};

Card.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.string,
  image: PropTypes.instanceOf(Object),
  openGetStartedModal: PropTypes.func,
};

export default connect(null, { openGetStartedModal })(Card);
