import React from "react";
import short from "short-uuid";
import IconClose from "../../../Images/SVG/close_cross.svg";
import IconCloseBlue from "../../../Images/SVG/close_cross_blue.svg";
import { downloadDownloadList } from "../../../Redux/DownloadRedux";
import ArrowDownloadWhite from "../../../Images/SVG/arrow_download_white.svg";
import IconMailBlue from "../../../Images/SVG/mail_blue.svg";

const DownloadList = (props) => {
  if (props.download.fileCollection.length === 0) {
    return (
      <React.Fragment key={short.uuid()}>
        <div className="dl-list-empty mt-large text-center col-12">Keine Dokumente hinzugefügt</div>
      </React.Fragment>
    );
  }

  const elementCount = props.download.fileCollection.length - 1;

  return (
    <div className="ml-large mr-large">
      <h5>Dokumente</h5>
      {props.download.fileCollection.map((el, index) => {
        let title = el.properties.title ? el.properties.title : el.properties.filename;
        if (title.length > 40) {
          title = `${title.substring(0, 40)}...`;
        }
        return (
          <React.Fragment key={short.uuid()}>
            <div className="dl-list-element col-12">
              <div className="row justifiy-items-between">
                <p className="dl-list-element-title" key={el.properties.fileReferenceUid}>
                  {title}
                </p>
                <div className="spacer" />
                <img
                  src={IconClose}
                  alt="IconClose"
                  className="dl-list-element-close"
                  onClick={() => {
                    props.deleteFromDownloadList(el);
                  }}
                />
              </div>
            </div>
            <hr
              className={
                index === elementCount ? "dl-item-seperator invisible" : "dl-item-seperator"
              }
            />
          </React.Fragment>
        );
      })}

      <>
        <div className="dl-list-footer">
          <hr className="dl-seperator" />
          <div className="dl-list-footer-delete-all">
            <div
              className="row justify-content-center align-items-center"
              onClick={() => props.clearDownloadList()}
            >
              <img src={IconCloseBlue} alt="" />
              <div className="remove-elements">ALLE DOKUMENTE ENTFERNEN</div>
            </div>
          </div>
          <div className="dl-list-footer-cta justify-content-center">
            <button
              type="button"
              className="ml-xlarge btn dl-list-footer-btn-cta"
              onClick={() => {
                downloadDownloadList(props.download.fileCollection);
              }}
            >
              <div className="row justify-content-center align-items-center">
                <img src={ArrowDownloadWhite} alt="download_list_icon" className="download-img" />
                <div className="dl-list-footer-btn-cta-text">ALS ZIP DOWNLOADEN</div>
              </div>
            </button>
            <button
              type="button"
              className="ml-xlarge btn btn-blue-transparent dl-list-footer-btn-cta"
              onClick={() => {
                props.openMailModal();
              }}
            >
              <div className="row justify-content-center align-items-center">
                <img src={IconMailBlue} alt="mail_list_icon" />
                <div className="dl-list-footer-btn-cta-text">ALS ZIP VERSENDEN</div>
              </div>
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default DownloadList;
