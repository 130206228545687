/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react";
import { connect } from "react-redux";
import IconDownload from "../../../Images/SVG/download_list_icon.svg";
import { setSidebarOpen } from "../../../Redux/DownloadRedux";
import { isAuthenticated } from "../../../Utils/UserUtils";

const DownloadsSwitch = (props) => {
  if (!isAuthenticated()) {
    return <></>;
  }
  return (
    <>
      {window.location.pathname === "/login" ? (
        <></>
      ) : (
        <div className="downloads-switch" onClick={() => props.setSidebarOpen()}>
          <img className="downloads-switch-icon" src={IconDownload} alt="" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { sore: state };
};

export default connect(mapStateToProps, { setSidebarOpen })(DownloadsSwitch);
