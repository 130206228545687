import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import short from "short-uuid";
import { addToDownloadList } from "../../Redux/DownloadRedux";
import DownloadCardItem from "./DownloadCardItem";

const Download = (props) => {
  const classes = `row download ${props.classes}`;
  const { files } = props.content;
  const chunkSize = 10;
  const chunks = files.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  function renderCard(items, index) {
    const maxItems = items.length;
    return (
      <div className="col-md-12 col-lg-6 mb-large">
        <div className="card download-list">
          {items.map((item, index) => {
            return renderDownloadItem(item, index, maxItems - 1);
          })}
        </div>
      </div>
    );
  }

  const renderDownloadItem = (item, index, maxItems) => {
    let rulerClass = "visible";
    if (index === maxItems) {
      rulerClass = "invisible";
    }

    const screenName =
      item.properties.title !== null ? item.properties.title : item.properties.filename;

    return (
      <DownloadCardItem key={short.uuid()} title={screenName} item={item} rulerClass={rulerClass} />
    );
  };

  return (
    <div className={classes}>
      <div className="col-12">
        <h2>{props.content.header}</h2>
        <h3>{props.content.subheader}</h3>
      </div>
      <div className="col-12">
        <div className="row is-flex">
          {chunks.map((chunk, index) => {
            return renderCard(chunk, index + 1);
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    download: state.downloads,
  };
};

export default connect(mapStateToProps, { addToDownloadList })(withRouter(Download));
