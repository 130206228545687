import camelize from "camelize";
import apisauce from "apisauce";
import { isAuthenticated, getUser, logoutUser } from "./UserUtils";
import { REACT_APP_API_BASE_URL } from "../Constants/ApiConstants";
import { IMPRINT, PRIVACY, LOGIN, PASSWORD_RESET } from "../Constants/RoutesConstants";

export const createApiSause = () => {
  const api = apisauce.create({
    baseURL: REACT_APP_API_BASE_URL,
    timeout: 10000,
    headers: { "Cache-Control": "no-cache" },
  });

  if (isAuthenticated()) {
    const user = getUser();

    api.setHeader("Authorization", `Bearer ${user.token}`);
  }

  return api;
};

const createApiClient = () => {
  const api = createApiSause();

  api.addMonitor((response) => {
    if (
      ![IMPRINT, PRIVACY, LOGIN, PASSWORD_RESET].includes(window.location.pathname) &&
      isAuthenticated() &&
      response.data.authentication &&
      !response.data.authentication.isValidUser
    ) {
      logoutUser();

      window.location.reload();
    }
  });

  return api;
};

export const getApi = async (url, options) => {
  return createApiClient()
    .get(url)
    .then(
      (response) => {
        if (response.ok) {
          return response.data;
        }
        Promise.reject(response);
      },
      (error) => Promise.reject(error),
    )
    .then(
      (data) => ({ data }),
      (error) => ({ error }),
    )
    .catch((error) => ({ error }));
};

export const postApi = async (url, data, options) => {
  return createApiClient()
    .post(url, data, options)
    .then(
      (response) => (response.ok ? response.data : Promise.reject(response.data)),
      (error) => Promise.reject(error),
    )
    .then(
      (payload) => ({ payload: camelize(payload) }),
      (error) => ({ error }),
    )
    .catch((error) => ({ error }));
};
