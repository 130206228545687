import React from "react";
import PropTypes from "prop-types";

import JSONTree from "react-json-tree";
import * as short from "short-uuid";

import { isLocalhost } from "../../Utils/DevUtils";

const DebugInfo = (props) => {
  if (isLocalhost) {
    return (
      <div key={short.uuid()} style={{ color: props.color ? props.color : "red" }}>
        <h4>Calling component {props.componentName}</h4>
        <h5>
          DebugInfo&nbsp;
          <b>{props.title}</b>
        </h5>
        <b>JSON</b>
        <div>
          <JSONTree data={props.json} />
        </div>
        <div>
          <p>{props.children}</p>
        </div>
      </div>
    );
  }

  return null;
};

DebugInfo.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  componentName: PropTypes.string.isRequired,
};

export default DebugInfo;
