import React from "react";
import Checkbox from "../CheckmarkIcons";

const IconTeaser = (props) => {
  return (
    <div className="col-md-6 col-lg-4 icon_teaser">
      <div className="card-type-second">
        <div className="row card-label">
          <div className="col-12">
            <h3>
              <span className="h3-dark ml-medium-2">{props.headline}</span>
            </h3>
            <img src={props.image} className="card-image" />
          </div>
        </div>
        <div className="row">
          <div className="col-10 teaser-text">{props.teaser}</div>
        </div>
        <div className="row card-link">
          <button
            className="ml-xlarge btn btn-blue-transparent btn-small"
            onClick={() =>
              props.history.push({
                pathname: props.link,
              })
            }
          >
            {props.linkLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default IconTeaser;
