export const cleanUrl = (url) => {
  return url.replace(" _blank", "");
};

export const currency = (value, currency) => {
  return new Intl.NumberFormat("de-DE", { style: "currency", currency }).format(value);
};

export const percentage = (value) => {
  return new Intl.NumberFormat("de-DE", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / 100);
};

export const buildScreenName = (person) => {
  let screenName = "";

  if (typeof person === "undefined") {
    return screenName;
  }

  if (person.title.length) {
    // screenName = `${person.title} `;
  }
  screenName += person.firstName;

  if (person.middleName.length) {
    screenName = `${screenName} ${person.middleName}`;
  }

  screenName = `${screenName} ${person.lastName}`;

  return screenName;
};
