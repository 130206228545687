import { combineReducers } from "redux";
import { reducer as loginReducer } from "./LoginRedux";
import { reducer as pageReducer } from "./PageRedux";
import { reducer as dashboardReducer } from "./DashboardRedux";
import { reducer as navigationReducer } from "./NavigationRedux";
import { reducer as downloadsReducer } from "./DownloadRedux";
import { reducer as getStartedReducer } from "./GetStartedRedux";
import configureStore from "./CreateStore";

export default () => {
  /* ------------- Assemble The Reducers ------------- */
  const rootReducer = combineReducers({
    login: loginReducer,
    page: pageReducer,
    dashboard: dashboardReducer,
    navigation: navigationReducer,
    downloads: downloadsReducer,
    getStarted: getStartedReducer,
  });

  return configureStore(rootReducer);
};
