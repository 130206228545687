import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getStartedModalToggle: ["value"],
});

export const GetStartedTypes = Types;

export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  getStartedModalOpen: false,
});

// TODO: Add emailFile: [val] and the methods for that. For a single file.  and customize the email modal.
/* ------------- Reducers ------------- */

export const getStartedModalToggle = (state, { value }) => {
  return { ...state, getStartedModalOpen: value };
};

/* ------------- Redux Pub Actions ------------- */

export const openGetStartedModal = () => (dispatch) => {
  dispatch(Creators.getStartedModalToggle(true));
};

export const closeGetStartedModal = () => (dispatch) => {
  dispatch(Creators.getStartedModalToggle(false));
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_STARTED_MODAL_TOGGLE]: getStartedModalToggle,
});
