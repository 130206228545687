import React from "react";
import { connect } from "react-redux";
import { setSidebarClose } from "../../../Redux/DownloadRedux";
import { isAuthenticated } from "../../../Utils/UserUtils";

const BackDrop = (props) => {
  if (!isAuthenticated()) {
    return <></>;
  }
  return (
    <>
      {props.sidebarOpen ? (
        <div className="backdrop" onClick={() => props.setSidebarClose()} />
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebarOpen: state.downloads.sidebarOpen,
  };
};

export default connect(mapStateToProps, { setSidebarClose })(BackDrop);
