import React from "react";
import PropTypes from "prop-types";

const SimpleText = ({ classes, header, subheader, copy }) => {
  const elementClasses = `simpleText row ${classes}`;

  return (
    <div className={elementClasses}>
      <div className="col-12">
        <h2>{header}</h2>
        <h3>{subheader}</h3>
        <div
          className="copy mt-xlarge mr-large ml-large"
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </div>
    </div>
  );
};

SimpleText.propTypes = {
  classes: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  copy: PropTypes.string,
};

export default SimpleText;
