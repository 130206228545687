import moment from "moment";

export const formatDate = (str) => {
  let result = "";

  if (str.length > 0) {
    let orgDate = new Date(Date.parse(`${str} UTC`));
    const options = { year: "numeric", month: "long", day: "numeric" };
    result = orgDate.toLocaleDateString("de-DE", options);

    if (!isNaN(orgDate)) {
      return result;
    }
    const dateParts = str.split(" ");
    orgDate = new Date(Date.parse(dateParts[0]));
    result = orgDate.toLocaleDateString("de-DE", options);
  } else {
    result = "-";
  }
  return result;
};

export const formatUtcDate = (str) => {
  let result = "";
  try {
    result = moment.utc(moment(str, "MMM DD YYYY")).format();
    result = result.replaceAll(":", "");
    result = result.replaceAll("-", "");
  } catch (e) {
    // ignore
  }

  return result;
};

export const createCookieExpireDate = () => {
  const newDate = new Date();
  const secondsUnitlEndOfDay =
    24 * 60 * 60 - newDate.getHours() * 60 * 60 - newDate.getMinutes() * 60 - newDate.getSeconds();

  return new Date(new Date().getTime() + secondsUnitlEndOfDay * 1000);
};
