import React from "react";
import { withRouter } from "react-router-dom";
import * as short from "short-uuid";

const MultipleLinksCard = (props) => {
  const { content } = props;
  const { category } = content;

  function renderHeader(row) {
    return (
      <div key={short.uuid()} className="row">
        {row.map((item) => renderColumn(item, "subheader pt-large pb-large"))}
      </div>
    );
  }

  function renderRow(row) {
    return (
      <div key={short.uuid()} className="row">
        {row.map((item) => renderCard(item))}
      </div>
    );
  }

  function renderCard(card) {
    let classes = "col card";

    if (!card.header) {
      classes += " invisible";
    }

    return (
      <div key={short.uuid()} className={classes}>
        <h4>{card.header}</h4>
        <div className="categorysubheader">{card.subheader}</div>
        <button
          className="btn btn-linkcard btn-small"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = card.link.url;
          }}
        >
          Anzeigen
        </button>
      </div>
    );
  }

  function renderColumn(column, className) {
    const classes = `col ${className}`;
    return (
      <div key={short.uuid()} className={classes}>
        {column}
      </div>
    );
  }

  function renderContent(rows) {
    return (
      <div key={short.uuid()}>
        {renderHeader(rows[0])}
        {renderBullets()}
        {rows.map(function (row, index) {
          if (index > 0) {
            return renderRow(row);
          }

          return "";
        })}
      </div>
    );
  }

  function renderBullets() {
    let showRow = false;

    category.forEach((item) => {
      if (item.bullets.length > 0) {
        showRow = true;
      }
    });
    if (showRow === true) {
      return (
        <div key={short.uuid()} className="row">
          {category.map(function (item) {
            return (
              <div key={short.uuid()} className="col bullet">
                <ul>
                  {item.bullets.map((item) => {
                    return (
                      <li key={short.uuid()} className="mb-medium">
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      );
    }
  }

  function reformatContentIntoRows(categories) {
    const rows = [];
    let maxItems = 0;
    rows[0] = [];
    categories.forEach((item) => {
      rows[0].push(item.header);
      if (item.links.length > maxItems) {
        maxItems = item.links.length;
      }
    });

    for (let i = 0; i < maxItems; i++) {
      rows[i + 1] = [];
      categories.forEach(function (item, index) {
        if (item.links[i]) {
          rows[i + 1][index] = item.links[i];
        } else {
          rows[i + 1][index] = {};
        }
      });
    }

    return rows;
  }

  return (
    content &&
    category && (
      <div key={short.uuid()} className={`${props.classes} multiple-linkcards`}>
        <div className="header">{content.header}</div>
        {renderContent(reformatContentIntoRows(category))}
      </div>
    )
  );
};

export default withRouter(MultipleLinksCard);
