/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
import React from "react";
import { formatDate } from "../../../Utils/DateUtils";
import ArrowLeft from "../../../Images/SVG/arrow_left_blue.svg";
import ArrowRight from "../../../Images/SVG/arrow_right_blue.svg";
import { REACT_APP_API_BASE_URL } from "../../../Constants/ApiConstants";

const DetailPage = (props) => {
  function getCategories(item) {
    let categories = "";

    item.categories.forEach((category) => {
      categories = `, ${category.title}`;
    });

    return categories;
  }

  const renderContent = (item) => {
  const html = item.bodytext.replaceAll(
      'href="/fileadmin/',
      `href="${REACT_APP_API_BASE_URL}/fileadmin/`,
    );
    
    return (
      <div>
        <div className="detail-head">
          <div className="detail-date font-color-standard-grey ">
            {formatDate(item.datetime)}
            {getCategories(item)}
          </div>
          <h2 className="detail-title">{item.title}</h2>
        </div>

        <div className="detail-body" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  };

  const renderPaginationLinks = (pagination) => {
    return (
      <div className="row justify-content-between">
        {pagination.previous ? (
          <div className="col align-items-center  last-item">
            <a href={pagination.previous?.slug ?? "#"}>
              <div className="card">
                <div className="row justify-content-start align-items-center ">
                  <img className="detail-arrow-svg" src={ArrowLeft} alt="lastnews" />
                  <div className="col-9 align-top">
                    <p className="last-item-date font-color-standard-grey ">
                      {formatDate(pagination.previous.datetime)}
                      {getCategories(pagination.previous)}
                    </p>
                    <span className="last-item-title">{pagination.previous.title}</span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ) : (
          <></>
        )}
        {pagination.next ? (
          <div className="col  next-item">
            <a href={pagination.next?.slug ?? "#"}>
              <div className="card">
                <div className="row justify-content-between align-items-center ">
                  <div className="col-9 align-top">
                    <p className="next-item-date font-color-standard-grey ">
                      {formatDate(pagination.next.datetime)}
                      {getCategories(pagination.next)}
                    </p>
                    <span className="next-item-title">{pagination.next.title}</span>
                  </div>
                  <img className="detail-arrow-svg" src={ArrowRight} alt="nextnews" />
                </div>
              </div>
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div className="detail mt-xxxlarge">
      <div className="col-12 card  ">
        <div className="row justify-content-center">
          <div className="col-10">
            {renderContent(
              props.items ? props.items[0].content.data.detail : props.item.content.data.detail,
            )}
          </div>
        </div>
      </div>
      <div className="col-12">
        {renderPaginationLinks(
          props.items ? props.items[0].content.data.pagination : props.item.content.data.pagination,
        )}
      </div>
      <div className="col-12">
        <div className="row justify-content-center mt-medium">
          <a href="/page/news">
            <button className="btn btn-blue-transparent btn-small">ZURÜCK ZUR ÜBERSICHT</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
