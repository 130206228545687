import React from "react";
import { ReactComponent as Checked } from "../../Images/SVG/checked.svg";
import { ReactComponent as Blocked } from "../../Images/SVG/denied.svg";
import { ReactComponent as Delete } from "../../Images/SVG/close_cross_grey.svg";

const ListItem = (props) => {
  function getAccessSign() {
    return props.item.blocked ? <Blocked /> : <Checked />;
  }

  function blockUser() {
    props.blockUser(props.item.uid);
  }

  function deleteUser() {
    props.deleteUser(props.item.uid);
  }

  function editUser() {
    props.editUser(props.item);
  }

  return (
    <div className="list-item row">
      <div className={props.item.blocked ? "col-md-12 col-lg-4 blocked" : "col-md-12 col-lg-4"}>
        <div className="row">
          <div className="col-1">{getAccessSign()}</div>
          <div className="col-3 text-center">{props.item.title}</div>
          <div className="col-8">
            {props.item.firstName} {props.item.lastName}
          </div>
        </div>
      </div>
      <div className={props.item.blocked ? "col-3 blocked" : "col-3"}>{props.item.username}</div>
      <div className="col-5">
        <div className="row">
          <div className="col-1" />
          <div className="col-4 blocking">
            <span onClick={() => blockUser()}>
              <Blocked className="mr-small" />
              {props.item.blocked ? "Entsperren" : "Sperren"}
            </span>
          </div>
          <div className="col-3 edit">
            <span onClick={() => editUser()}>Bearbeiten</span>
          </div>
          <div className="col-4 actions">
            <span onClick={() => deleteUser()}>
              <Delete className="mr-small" />
              Löschen
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
