import React from "react";
import * as short from "short-uuid";
import { Helmet } from "react-helmet";

const ChatItem = () => {
  return (
    <span className="embeddedServiceHelpButton" key={short.uuid()}>
      <Helmet async={false}>
        <script type="text/javascript">
          {`
                var initESW = function (gslbBaseURL) {
                  embedded_svc.settings.displayHelpButton = true; //Or false
                  embedded_svc.settings.language = ""; //For example, enter 'en' or 'en-US'
          
                  embedded_svc.settings.enabledFeatures = ["LiveAgent"];
                  embedded_svc.settings.entryFeature = "LiveAgent";
          
                  embedded_svc.init(
                    "https://lloydfondsag.my.salesforce.com",
                    "https://laic.force.com",
                    gslbBaseURL,
                    "00D0O000000Z3HF",
                    "LAIC_Portal",
                    {
                      baseLiveAgentContentURL: "https://c.la3-c1-fra.salesforceliveagent.com/content",
                      deploymentId: "5721r000000TYo8",
                      buttonId: "57369000000oLl3",
                      baseLiveAgentURL: "https://d.la3-c1-fra.salesforceliveagent.com/chat",
                      eswLiveAgentDevName: "LAIC_Portal_Queue",
                      isOfflineSupportEnabled: false,
                    },
                  );
                };
                if (!window.embedded_svc) {
                  var s = document.createElement("script");
                  s.setAttribute(
                    "src",
                    "https://lloydfondsag.my.salesforce.com/embeddedservice/5.0/esw.min.js",
                  );
                  s.onload = function () {
                    initESW(null);
                  };
                  document.body.appendChild(s);
                } else {
                  initESW("https://service.force.com");
                }
        `}
        </script>
      </Helmet>
    </span>
  );
};

export default ChatItem;
