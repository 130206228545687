import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LFLogo } from "../../Images/SVG/logo_lloyd.svg";
import { ReactComponent as LaicLogo } from "../../Images/SVG/logo_laic.svg";
import VKILogo from "../../Images/logo_vki.png";

import { getGroupId, getGroupName } from "../../Utils/UserUtils";

const PageHeadline = ({ showServiceInformation = false, headline, layout, subline, classes }) => {
  const renderLogo = () => {
    switch (getGroupId()) {
      case 8:
        return (
          <div className="col-xl-10 col-lg-12 col-md-12">
            <span>
              <img src={VKILogo} alt="vki" className="img-fluid" />
            </span>
          </div>
        );
      default:
        return (
          <>
            <div className="col-xl-6 col-lg-12 col-md-12">
              <span>
                <LFLogo />
              </span>
            </div>
            <div className="col-xl-4 col-md-12 text-right">
              <span>
                <LaicLogo />
              </span>
            </div>
          </>
        );
    }
  };

  const renderServiceInformation = () => {
    if (showServiceInformation) {
      return (
        <div className="col-3 service-information">
          <div className="row justify-content-end">
            <div className="col-lg-10 mb-small">
              <span className="font-color-dark-grey font-size-small">Ein Service von</span>
            </div>
          </div>
          <div className="row justify-content-end">{renderLogo()}</div>
        </div>
      );
    }

    return null;
  };

  const renderHeadline = () => {
    switch (layout) {
      case "default":
        return <h1>{headline}</h1>;
      case "layout-1":
        return <h2>{headline}</h2>;
      default:
        return <h1>{headline}</h1>;
    }
  };

  const elementClasses = `page-headline row ${classes}`;

  return (
    <div className={elementClasses}>
      <div className="col-9">
        {renderHeadline()}
        <div className="subline">{subline}</div>
      </div>
      {renderServiceInformation()}
    </div>
  );
};

PageHeadline.propTypes = {
  showServiceInformation: PropTypes.bool,
  headline: PropTypes.string,
  layout: PropTypes.string,
  subline: PropTypes.string,
  classes: PropTypes.string,
};

export default PageHeadline;
