import React from "react";
import PropTypes from "prop-types";
import ICalendarLink from "react-icalendar-link";
import { ReactComponent as Ical } from "../../Images/SVG/ical.svg";
import { formatUtcDate } from "../../Utils/DateUtils";

const ICalLink = (props) => {
  const startDate = props.startDate ? formatUtcDate(props.startDate) : "";

  const event = {
    title: props.title,
    description: props.description ? props.description : "",
    startTime: startDate,
    endTime: startDate,
    location: props.location ? props.location : "",
  };

  return (
    props.startDate && (
      <span>
        <Ical />
        &nbsp;
        <ICalendarLink filename={`${props.title}.ics`} event={event}>
          {props.label}
        </ICalendarLink>
      </span>
    )
  );
};

ICalLink.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  location: PropTypes.string,
  description: PropTypes.string,
};

export default ICalLink;
