import React, { useState, useEffect, useRef } from "react";
// import PropTypes from 'prop-types'

import Spinner from "../../Common/Spinner";

import { postApi } from "../../../Utils/ApiUtils";
import { FILE_UPLOAD } from "../../../Constants/ApiConstants";

import IconDownloadAdded from "../../../Images/SVG/download_added.svg";
import IconCloseCrossGray from "../../../Images/SVG/close_cross_grey.svg";
import IconCloseBlue from "../../../Images/SVG/close_cross_blue.svg";

const UploadForm = (props) => {
  const classes = `row download ${props.classes}`;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [response, setResponse] = useState({});
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef();

  useEffect(() => {
    // prevent duplicates
    const newFilteredFiles = selectedFiles.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name);

      if (!x) {
        return acc.concat([current]);
      }

      return acc;
    }, []);

    setFilteredFiles([...newFilteredFiles]);

    fileInputRef.current.value = "";
  }, [selectedFiles]);

  const validateFile = (file) => {
    const validTypes = ["application/pdf"];

    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    if (file.size > 10 * 1024 * 1024) {
      return false;
    }

    return true;
  };

  const handleFiles = (files) => {
    const newSelectedFiles = [...files].filter((file) => validateFile(file));
    const invalidFiles = [...files].filter((file) => !validateFile(file)).map((file) => file.name);

    setSelectedFiles([...selectedFiles, ...newSelectedFiles]);

    if (invalidFiles.length) {
      const invalidFilesMessage = invalidFiles
        .concat([
          "Bitte laden Sie ausschließlich Dateien im Format PDF mit einer maximalen Größe von 10mb hoch.",
        ])
        .join("<br/>");

      setResponse({ type: "error", message: invalidFilesMessage });
    } else {
      setResponse({});
    }
  };

  const removeFile = (name) => {
    const newSelectedFiles = selectedFiles.filter((file) => file.name !== name);

    setSelectedFiles(newSelectedFiles);
  };

  const clearUploadsList = () => {
    setSelectedFiles([]);
  };

  const sendFiles = async () => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append("elementId", props.id);
    formData.append("elementType", props.type);

    filteredFiles.forEach((file, index) => {
      formData.append(`${file.name}_${index}`, file);
    });

    const options = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    try {
      const { payload, error } = await postApi(FILE_UPLOAD, formData, options);

      setResponse({ type: payload.status, message: payload.message });

      if (error) {
        throw error;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      setSelectedFiles([]);
    }
  };

  const preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);

    setIsDraggedOver(true);
  };

  const dragLeave = (e) => {
    preventDefault(e);

    setIsDraggedOver(false);
  };

  const fileDrop = (e) => {
    preventDefault(e);

    setIsDraggedOver(false);

    const { files } = e.dataTransfer;

    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const fileSize = (size) => {
    if (size === 0) {
      return "0 Bytes";
    }

    const k = 1024;

    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    const i = Math.floor(Math.log(size) / Math.log(k));

    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  return (
    <div className={classes}>
      <div className="col-12">
        <div className="mb-large">
          <h2>{props.content.header}</h2>
        </div>

        <div className="dropzone">
          {response.message && (
            <div
              className={`alert alert-${
                response.type === "success" ? "success" : "danger"
              } mb-large`}
              role="alert"
            >
              <span dangerouslySetInnerHTML={{ __html: response.message }} />
            </div>
          )}

          <div
            className={`dropzone-container ${isDraggedOver ? "active" : ""}`.trim()}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
          >
            <div className="dropzone-message">
              <h3>Dokumente hierhin ziehen</h3>
              <span>oder</span>
              <button
                type="button"
                className="btn btn-download-list select"
                onClick={fileInputClicked}
              >
                Dokumente auswählen
              </button>
            </div>
            <input
              ref={fileInputRef}
              className="dropzone-input"
              type="file"
              multiple
              onChange={filesSelected}
            />
          </div>

          <div className="dropzone-meta">
            <span className="font-color-dark-grey font-size-small mr-2">
              Zugelassene Dateiformate: .pdf
            </span>
            <span className="font-color-dark-grey font-size-small">Maximale Größe: 10MB </span>
          </div>

          {filteredFiles.length > 0 && (
            <>
              <div className="card download-list mt-large">
                <h3 className="mb-large">Dokumente</h3>

                {filteredFiles.map((data, index) => (
                  <React.Fragment key={`${data.name}_${index}`}>
                    <div className="file-status-bar">
                      <div className="row">
                        <div className="col-md-8 d-flex align-items-center mb-3 mb-md-0">
                          <img className="mr-3" src={IconDownloadAdded} alt="" />
                          <span className="file-name">{data.name}</span>
                        </div>
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-6 d-flex justify-content-md-end">
                              <span className="file-size">{fileSize(data.size)}</span>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                              <button
                                type="button"
                                className="file-remove"
                                onClick={() => removeFile(data.name)}
                              >
                                <img src={IconCloseCrossGray} alt="" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className={index < filteredFiles.length - 1 ? "visible" : "invisible"} />
                  </React.Fragment>
                ))}
              </div>

              <div className="dropzone-actions mt-large">
                <button type="button" className="remove-elements mr-4" onClick={clearUploadsList}>
                  <img className="mr-2" src={IconCloseBlue} alt="" />
                  <div className="font-size-small">ALLE DOKUMENTE ENTFERNEN</div>
                </button>

                <button type="button" className="btn btn-download-list contact" onClick={sendFiles}>
                  Versenden
                </button>
              </div>

              <Spinner show={isLoading} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

// UploadForm.propTypes = {

// }

export default UploadForm;
