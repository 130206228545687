import React from "react";
import short from "short-uuid";

import { ReactComponent as Arrow } from "../../../Images/SVG/arrow_out.svg";
import ICalLink from "../IcalLink";
import { formatDate } from "../../../Utils/DateUtils";

const ListItem = (props) => {
  const imgEl = React.createRef();

  function renderImage(item) {
    if (item[0]) {
      return (
        <img
          src={item[0].images.defaultImage.publicUrl}
          alt=""
          ref={imgEl}
          width="100%"
          height="auto"
          className="float-right mt-large image"
        />
      );
    }
  }

  function renderEventLink(item) {
    if (item.eventLink.url) {
      return (
        <span>
          <Arrow />
          &nbsp;
          <a href={item.eventLink.url} target="_blank" rel="noopener noreferrer">
            {item.eventLink.text}
          </a>
          &nbsp; &nbsp;
        </span>
      );
    }
  }

  function renderIcalLink(item) {
    let found = false;
    if (item.categories.length > 0) {
      found = item.categories.some((category) => {
        return category.id === 99;
      });
    }
    if (!found) {
      return (
        <ICalLink
          label="Save the date"
          location={item.place}
          title={item.title}
          description={item.teaser}
          startDate={item.startDate}
          endDate={item.endDate}
        />
      );
    }
  }

  return (
    <div id={short.uuid()} className="event-item row mb-large pr-medium pl-medium">
      <div className="col-12 card">
        <div className="row">
          <div className="col-4">{renderImage(props.item.media)}</div>
          <div className="col-8">
            <div className="time-label mt-large mb-small">
              <div>{formatDate(props.item.startDate)}</div>
            </div>
            <h3>{props.item.title}</h3>
            <div className="item-teaser mt-large">{props.item.teaser}</div>
            <div className="event-link mt-medium">
              {renderEventLink(props.item)}
              {renderIcalLink(props.item)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
