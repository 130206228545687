import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as short from "short-uuid";

import Card from "./Sidebar/Card";
import { getGroupOnboardingLink } from "./../../Utils/UserUtils";

const Sidebar = ({ sidebar = [] }) => {
  const renderItem = (item) => {
    switch (item.type) {
      case "partnerportal_logocard":
        return (
          <Card
            key={short.uuid()}
            label={item.content.header}
            link={item.content.headerLink.link}
            target={item.content.headerLink.target}
            image={item.content.image}
            addAffiliateKey={item.content.addAffiliateKey}
            affiliateKeyName={item.content.affiliateKeyProperty}
          />
        );
      case "partnerportal_onboarding_widget":
        if (getGroupOnboardingLink()) {
          return (
            <Card
              key={short.uuid()}
              label={item.content.header}
              link={getGroupOnboardingLink()}
              target="_blank"
              image={item.content.image}
              addAffiliateKey="0" // currently not supported
              affiliateKeyName=""
            />
          );
        }
      default:
        return <span key={short.uuid()}></span>;
    }
  };

  const renderContent = () => {
    return sidebar.map((item) => renderItem(item));
  };

  return (
    <section className="sidebar" id="sidebar">
      {renderContent()}
    </section>
  );
};

Sidebar.propTypes = {
  sidebar: PropTypes.instanceOf(Array),
};

const mapStateToProps = (state) => {
  return {
    sidebar: state.dashboard?.dashboard?.page?.sidebar,
  };
};

export default connect(mapStateToProps)(Sidebar);
