import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Routes, Text } from "../../Constants";
import { ReactComponent as ArrowLeft } from "../../Images/SVG/arrow_left.svg";

const BackLink = (props) => {
  function generateBackLink() {
    if (props.history.length > 2) {
      return (
        <div
          className="mr-small"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.history.go(-1);
          }}
        >
          <span className="mr-small">
            <ArrowLeft />
          </span>
          {Text.BACK}
        </div>
      );
    }

    // if i have no previous visited page, go back to dashboard
    // Example: (direct call of an url)
    return (
      <a href={Routes.DASHBOARD}>
        <span className="mr-small">
          <ArrowLeft />
        </span>
        {Text.BACK}
      </a>
    );
  }

  return <div className="back-link">{generateBackLink()}</div>;
};

const mapStateToProps = (state) => {
  return {
    success: state.page.success,
    fetching: state.page.fetching,
    error: state.page.error,
    page: state.page.page,
  };
};

export default connect(mapStateToProps)(withRouter(BackLink));
