import React from "react";
// import { Redirect } from 'react-router-dom';
import { Routes, Text } from "../../Constants";

const MetaFooter = () => {
  // TODO(Peter): Wird das noch gebraucht?
  /*
  function redirectToImprint() {
    return <Redirect to={Routes.IMPRINT} />;
  }

  function redirectToPrivacy() {
    return <Redirect to={Routes.PRIVACY} />;
  }
  */

  return (
    <div className="row justify-content-center">
      <div className="col-lg-6 text-center pre-footer">
        <a href={Routes.PRIVACY}>{Text.PRIVACY}</a>
        <span> | </span>
        <a href={Routes.IMPRINT}>{Text.IMPRINT}</a>
      </div>
    </div>
  );
};

export default MetaFooter;
