import React, { useState, useEffect } from "react";
import * as short from "short-uuid";
import NewsletterModal from "./NewsletterModal";
import NlCheckbox from "./NlCheckbox";

const NewsletterCard = (props) => {
  const [state, setState] = useState({
    nl1: false,
    nl2: false,
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal === false) {
      setState({
        nl1: false,
        nl2: false,
      });
    }
  }, [showModal]);

  function changeItem(item) {
    setState({
      ...state,
      [item]: !state[item],
    });
  }

  return (
    <div id={short.uuid()} className="col-md-6 col-lg-4">
      <div className="card-type-second">
        <div className="row card-label">
          <div className="col-12">
            <h3>
              <span className="h3-dark ml-medium-2">{props.item.content.header}</span>
            </h3>
          </div>
        </div>

        <div className="row newsletter-selection">
          <div className="col-12 checkboxes">
            <div className="row checkbox-row">
              <NlCheckbox status={state.nl1} item="nl1" changeItem={changeItem} />
              <span className="checkbox-title">{props.item.content.form[0].laic.name}</span>
            </div>
            <div className="row checkbox-row">
              <NlCheckbox status={state.nl2} item="nl2" changeItem={changeItem} />
              <span className="checkbox-title">{props.item.content.form[0].lloyd.name}</span>
            </div>
          </div>
        </div>

        <div className="row card-link">
          <button
            type="button"
            className="ml-xlarge btn btn-blue-transparent btn-small"
            onClick={() => {
              setShowModal(true);
            }}
          >
            {props.item.content.subheader}
            <NewsletterModal
              showModal={showModal}
              hideModal={() => setShowModal(false)}
              preselectedNewsletter={{ laic: state.nl1, lloyd: state.nl2 }}
              content={props.item.content}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsletterCard;
