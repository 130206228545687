/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import short from "short-uuid";
import { formatDate } from "../../../Utils/DateUtils";
import { ReactComponent as ArrowOut } from "../../../Images/SVG/arrow_out.svg";
import { ReactComponent as ArrowRight } from "../../../Images/SVG/arrow_right.svg";
import { getCategories } from "../../../Utils/NewsUtils";

const NewsListItem = (props) => {
  function renderNewsLink(item) {
    switch (item.type) {
      case "1":
        return (
          <div key={short.uuid()} className="news-link mt-medium">
            <a href={item.slug} target="_blank">
              <span>
                <ArrowOut />
                &nbsp;
                <span className="news-link-text">Anzeigen</span>
              </span>
              &nbsp; &nbsp;
            </a>
          </div>
        );
      case "4":
        return (
          <div key={short.uuid()} className="news-link mt-medium">
            <a href={`https://laic.de/news/${item.pathSegment}`} target="_blank">
              <span>
                <ArrowOut />
                &nbsp;
                <span className="news-link-text">Anzeigen</span>
              </span>
              &nbsp; &nbsp;
            </a>
          </div>
        );
      default:
        return (
          <div key={short.uuid()} className="news-link mt-medium">
            <a href={item.slug}>
              <span>
                <ArrowRight />
                &nbsp;
                <span className="news-link-text">Weiterlesen</span>
              </span>
              &nbsp; &nbsp;
            </a>
          </div>
        );
    }
  }

  return (
    <div id={short.uuid()} className="event-item row mb-large pr-medium pl-medium">
      <div className="col-12 card">
        <div className="d-flex flex-row justify-content-around">
          <div className="col-8">
            <div className="time-label mt-large mb-small">
              <div>
                {formatDate(props.item.datetime)}
                {getCategories(props.item)}
              </div>
            </div>
            <h3>{props.item.title}</h3>
            <div className="item-teaser mt-large">{props.item.teaser}</div>
            {renderNewsLink(props.item)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsListItem;
