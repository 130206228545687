import React from "react";

import { uuid } from "short-uuid";
import Elements from "../ContentElements/Elements";
import HorizontalRuler from "../ContentElements/HorizontalRuler";

const ModalContent = (props) => {
  function renderContent() {
    if (props.page !== null && props.page.items) {
      switch (props.page.common.layout) {
        case "default": {
          const items = props.page.items.colPos0;
          if (items) {
            return items.map((item) => renderContentItem(item));
          }
          break;
        }
        default:
      }
    }

    return <div />;
  }

  function renderContentItem(item) {
    return <Elements key={item.id} items={[item]} />;
  }

  function renderDashboardButton() {
    if (props.renderButton) {
      return (
        <div>
          <HorizontalRuler key={uuid()} classes="mt-large" />
          <button
            className="btn mt-large"
            onClick={() => {
              props.handleClose();
            }}
          >
            Weiter zum Dashboard
          </button>
        </div>
      );
    }
  }

  return (
    <section id="modalContent">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="row justify-content-center mb-medium">
            <div className="col-xl-8 col-lg-10 col-md-12">
              {renderContent()}
              {renderDashboardButton()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ModalContent;
