import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../CheckmarkIcons";

const NlCheckbox = ({ status, item, changeItem }) => {
  return (
    <Checkbox
      status={status}
      onStatusChanged={() => {
        changeItem(item);
      }}
    />
  );
};

NlCheckbox.propTypes = {
  status: PropTypes.bool,
  item: PropTypes.string,
  changeItem: PropTypes.func,
};

export default NlCheckbox;
