/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ReactComponent as Close } from "../../Images/SVG/close.svg";
import { closeGetStartedModal } from "../../Redux/GetStartedRedux";
import { getUserName } from "../../Utils/UserUtils";
import { postApi } from "../../Utils/ApiUtils";
import { GET_STARTED } from "../../Constants/ApiConstants";

const validator = require("email-validator");

const GetStartedModal = (props) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "male",
  });

  const [generalError, setGeneralError] = useState(false);

  const validateEntries = () => {
    const { firstName, lastName, email, gender } = formData;

    return (
      firstName.trim() &&
      lastName.trim() &&
      email.trim() &&
      validator.validate(email) &&
      gender.trim()
    );
  };

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      gender: "male",
    });
  };

  const submitData = async () => {
    if (validateEntries()) {
      const { firstName, lastName, email, gender } = formData;

      const { error } = await postApi(GET_STARTED, {
        rest: {
          form: {
            firstName,
            lastName,
            email,
            gender,
          },
        },
      });

      if (error === undefined) {
        resetForm();

        props.closeGetStartedModal();
      } else {
        setGeneralError(true);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const userName = getUserName();

  return (
    <Modal
      show={props.getStartedModalOpen}
      keyboard
      className="custom-modal-style download-email-modal getstarted-modal"
      animation
    >
      <Modal.Header>
        <div
          className="col-12 mb-medium d-flex justify-content-end"
          id="close"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.closeGetStartedModal();
          }}
        >
          <Close />
        </div>
      </Modal.Header>
      <Modal.Body>
        <section id="modalContent">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="row justify-content-center mb-medium">
                <div className="col-xl-8 col-lg-10 col-md-12 newsletter-modal-head getstarted-modal-head">
                  <h1>LAIC - My Portfolio Selection</h1>
                  <h4>
                    Zum Start der LAIC-My Portfolio Section bitten wir Sie, sich zu registrieren.
                    Füllen Sie dazu einfach das folgende Formular aus.
                  </h4>
                  <fieldset>
                    <legend>Anrede</legend>
                    <div className="radio">
                      <label htmlFor="herr">
                        <input
                          className="radio__input"
                          type="radio"
                          name="gender"
                          id="herr"
                          value="male"
                          checked={formData.gender === "male"}
                          onChange={handleChange}
                        />
                        <span className="radio__label">Herr</span>
                      </label>
                    </div>
                    <div className="radio">
                      <label htmlFor="frau">
                        <input
                          className="radio__input"
                          type="radio"
                          name="gender"
                          id="frau"
                          value="female"
                          checked={formData.gender === "female"}
                          onChange={handleChange}
                        />
                        <span className="radio__label">Frau</span>
                      </label>
                    </div>
                  </fieldset>
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="floatingInput">
                        <input
                          className="newsletter-modal-input getstarted-modal-input floatingInput__input"
                          type="text"
                          placeholder="Vorname *"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                        <span className="floatingInput__label">Vorname *</span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="floatingInput">
                        <input
                          className="newsletter-modal-input getstarted-modal-input floatingInput__input"
                          type="text"
                          placeholder="Nachname *"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                        <span className="floatingInput__label">Nachname *</span>
                      </div>
                    </div>
                  </div>
                  <div className="floatingInput">
                    <input
                      className="newsletter-modal-input getstarted-modal-input floatingInput__input"
                      type="email"
                      name="email"
                      placeholder="Email *"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <span className="floatingInput__label">Email *</span>
                  </div>
                  <span className="newsletter-modal-required-hint getstarted-modal-required-hint">
                    * Pflichtfelder
                  </span>
                  {userName && (
                    <div className="getstarted-modal-user">
                      <span>Vermittler</span>
                      <div className="getstarted-modal-user-name">{userName}</div>
                    </div>
                  )}
                  <hr />
                  <div className="d-flex flex-row-reverse newsletter-modal-confirm">
                    <button type="button" className="ml-xlarge btn" onClick={submitData}>
                      Absenden
                    </button>

                    {generalError ? (
                      <span className="newsletter-modal-error">
                        Beim der Übermittlung der Daten ist ein Fehler aufgetreten.
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

GetStartedModal.propTypes = {
  getStartedModalOpen: PropTypes.bool.isRequired,
  closeGetStartedModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ getStarted: { getStartedModalOpen } }) => {
  return {
    getStartedModalOpen,
  };
};

export default connect(mapStateToProps, { closeGetStartedModal })(GetStartedModal);
