import React from "react";

import * as short from "short-uuid";
import { ReactComponent as Mail } from "../../Images/SVG/mail.svg";

const MailItem = (props) => {
  return (
    <span key={short.uuid()} id="contact">
      <a href={props.link}>
        <Mail />
        {props.title}
      </a>
    </span>
  );
};

export default MailItem;
