import React from "react";
import PropTypes from "prop-types";

const SimpleTable = ({ classes, headline, table }) => {
  const elementClasses = `simpleTable row ${classes}`;

  return (
    <div className={elementClasses}>
      <div className="col-12">
        <h2>{headline}</h2>
        <div
          className="copy mt-xlarge mr-large ml-large"
          dangerouslySetInnerHTML={{ __html: table }}
        />
      </div>
    </div>
  );
};

SimpleTable.propTypes = {
  classes: PropTypes.string,
  headline: PropTypes.string,
  table: PropTypes.string,
};

export default SimpleTable;
