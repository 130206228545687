import React from "react";
import * as short from "short-uuid";
import Truncate from "react-truncate";
import { formatDate } from "../../../Utils/DateUtils";
import { ReactComponent as ArrowRight } from "../../../Images/SVG/arrow_right.svg";

const NewsTeaser = (props) => {
  const newsList = props.news;

  function renderNews() {
    if (newsList.length > 0) {
      const news = newsList[0];

      return (
        <div className="row teaser ml-small mb-large mr-small">
          <div className="col-12 date">{formatDate(news.datetime)}</div>
          <div className="col-12 teaser-text">
            <Truncate lines={2}>{news.title}</Truncate>
          </div>
          <div className="deep-link mt-small ml-small">
            <a href={news.slug}>
              <ArrowRight height="13px" />
              Anzeigen
            </a>
          </div>
        </div>
      );
    }
  }

  return (
    <div id={short.uuid()} className="col-md-6 col-lg-4 news-teaser-card">
      <div className="card-type-second">
        <div className="row card-label">
          <div className="col-12">
            <h3>
              <span className="h3-dark ml-medium-2">{props.header}</span>
            </h3>
          </div>
        </div>
        {renderNews()}
        <div className="row card-link">
          <button
            className="ml-xlarge btn btn-blue-transparent btn-small"
            onClick={() =>
              props.history.push({
                pathname: props.listPage,
              })
            }
          >
            {props.linkLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsTeaser;
