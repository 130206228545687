export default {
  // Forms
  EMAIL: "E-Mail",
  PASSWORD: "Passwort",
  EMPTY_CREDENTIALS: "Bitte füllen Sie Ihre Anmeldedaten korrekt aus",

  // Common
  BACK: "Zurück",
  LOGOUT: "Logout",

  // Pages
  DASHBOARD: "Dashboard",

  // Meta
  PRIVACY: "Datenschutz",
  IMPRINT: "Impressum",

  // API
  LOGIN_ERROR: "Es ist ein Fehler beim Login aufgetreten.",
};
