/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PreFooter from "../Footer/PreFooter";
import MetaFooter from "../Footer/MetaFooter";
import { Text, Routes } from "../../Constants";
import { attemptLogin } from "../../Redux/LoginRedux";
import Spinner from "../Common/Spinner";

const Login = ({ attemptLogin, history, error, fetching, success }) => {
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
    rememberUser: false,
    error: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { username, password } = loginData;

    if (username && password) {
      setLoginData({
        ...loginData,
        error: false,
      });

      await attemptLogin(username, password);

      history.push(Routes.DASHBOARD);
    } else {
      setLoginData({
        ...loginData,
        error: Text.EMPTY_CREDENTIALS,
      });
    }
  };

  const getErrorMessage = () => {
    if (loginData.error) {
      return loginData.error;
    }
    if (error) {
      return error;
    }
  };

  let errorClass = "invisible";

  if (error || loginData.error !== false) {
    errorClass = "login-error mt-medium";
  }

  return (
    <div className="row justify-content-center full-height">
      <div className="login align-self-center">
        <h1 className="text-center">Ihr Partnerportal</h1>
        <div className="panel pd-large mt-xlarge">
          <h3 className="mt-xlarge">Login</h3>
          <form className="form mt-xlarge" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username" className="sr-only">
                {Text.EMAIL}
              </label>
              <input
                type="text"
                className="form-control form-control-xs form-control-custom text-light"
                name="username"
                placeholder={Text.EMAIL}
                value={loginData.username}
                onChange={(e) => setLoginData({ ...loginData, username: e.target.value })}
              />
            </div>
            <div className="form-group mt-xlarge">
              <label htmlFor="password" className="sr-only">
                {Text.PASSWORD}
              </label>
              <input
                type="password"
                className="form-control form-control-xs form-control-custom"
                name="password"
                placeholder={Text.PASSWORD}
                autoComplete="new-password"
                value={loginData.password}
                onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
              />
            </div>
            <div className="d-flex flex-row-reverse">
              <a className="password-reset-link" href="/password-reset">
                Passwort vergessen?
              </a>
            </div>
            <div className="separator col-12 mt-xxxlarge" />
            <div className={errorClass}>{getErrorMessage()}</div>
            <div className="form-group mt-xlarge">
              <input type="hidden" name="_csrf_token" value="csrf_token" />
              <input type="submit" className="btn" value="Anmelden" onClick={handleSubmit} />
            </div>
          </form>
        </div>
        <PreFooter />
        <MetaFooter />
      </div>
      <Spinner show={fetching} />
    </div>
  );
};

Login.propTypes = {
  success: PropTypes.bool,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  attemptLogin: PropTypes.func,
  history: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  return {
    success: state.login.success,
    fetching: state.login.fetching,
    error: state.login.error,
  };
};

export default connect(mapStateToProps, { attemptLogin })(withRouter(Login));
