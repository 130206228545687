import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Close } from "../../Images/SVG/close.svg";
import { emailIsValid } from "../../Utils/ValidationUtils";
import { getGroupId, getGroups, isSuperAdmin } from "../../Utils/UserUtils";

const CreateUserModal = (props) => {
  const [state, setState] = useState({
    gender: "male",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
    emailError: false,
    firstNameError: false,
    lastNameError: false,
    passwordError: false,
    password2Error: false,
    isSuperAdmin: isSuperAdmin(),
    userGroups: getGroups(),
    selectedUserGroup: getGroupId(),
    groupName: "",
    uid: false,
  });

  useEffect(() => {
    if (props.userObject) {
      setState({
        ...state,
        firstName: props.userObject.firstName,
        lastName: props.userObject.lastName,
        name: props.userObject.name,
        email: props.userObject.email,
        gender: props.userObject.title === "Herr" ? "male" : "female",
        uid: props.userObject.uid,
        groupName: props.userObject.groupName,
        emailError: false,
        firstNameError: false,
        lastNameError: false,
        passwordError: false,
        password2Error: false,
      });
    } else {
      setState({
        ...state,
        firstName: "",
        lastName: "",
        name: "",
        email: "",
        gender: "male",
        uid: false,
        groupName: "",
        emailError: false,
        firstNameError: false,
        lastNameError: false,
        passwordError: false,
        password2Error: false,
      });
    }
  }, [props.userObject]);

  const getHeadline = () => {
    if (state.uid) {
      return <h1>Benutzer bearbeiten</h1>;
    }

    return <h1>Neuen Benutzer erstellen</h1>;
  };

  const renderValidationNotice = () => {
    if (!state.uid) {
      return <span className="create-user-modal-info">Alle Felder sind Pflichtangaben</span>;
    }

    return <span className="create-user-modal-info" />;
  };

  const getSubline = () => {
    if (state.uid) {
      return (
        <h4>
          Hier können Sie die grundlegende Informationen und Zuordnungen eines Benutzers anpassen.
        </h4>
      );
    }

    return (
      <h4>
        Erstellen Sie einen neuen Benutzer. Die E-Mail-Adresse darf noch nicht im System hinterlegt
        sein, anderenfalls kann der Account nicht angelegt werden.
      </h4>
    );
  };

  const renderPasswordLabel = () => {
    if (!state.uid) {
      return <></>;
    }

    return (
      <div className="row warning mt-xlarge">
        <span>
          Wenn kein neues Passwort eingetragen wird, behält das alte Passwort seine Gültigkeit
        </span>
      </div>
    );
  };

  function onChangeGender(e) {
    setState({
      ...state,
      gender: e.target.value,
    });
  }

  function onChangeFirstName(e) {
    setState({
      ...state,
      firstName: e.target.value,
    });
  }

  function onChangeLastName(e) {
    setState({
      ...state,
      lastName: e.target.value,
    });
  }

  function onChangeEmail(e) {
    setState({
      ...state,
      email: e.target.value,
    });
  }

  function onChangePassword(e) {
    setState({
      ...state,
      password: e.target.value,
    });
  }

  function onChangePassword2(e) {
    setState({
      ...state,
      password2: e.target.value,
    });
  }

  function onChangeGroup(e) {
    setState({
      ...state,
      selectedUserGroup: e.target.value,
    });
  }

  function onSave() {
    if (isValid()) {
      if (state.uid) {
        props.updateUser({
          username: state.email,
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          name: state.firstName + " " + state.lastName,
          title: mapGender(state.gender),
          password: state.password,
          usergroup: state.selectedUserGroup,
          uid: state.uid,
        });
      } else {
        props.saveUser({
          username: state.email,
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          name: state.firstName + " " + state.lastName,
          title: mapGender(state.gender),
          password: state.password,
          usergroup: state.selectedUserGroup,
        });
      }
    }
  }

  function mapGender(gender) {
    if (gender === "male") {
      return "Herr";
    } else if (gender === "female") {
      return "Frau";
    }

    return "    ";
  }

  function isValid() {
    let passwordError = false;
    let firstNameError = false;
    let lastNameError = false;
    let hasError = false;
    let password2Error = false;
    let emailError = false;

    if (state.firstName.length < 2) {
      firstNameError = "Der Vorname ist zu kurz";
      hasError = true;
    }
    if (state.lastName.length < 2) {
      lastNameError = "Der Nachname ist zu kurz";
      hasError = true;
    }

    if (!emailIsValid(state.email)) {
      emailError = "Die eingegebene E-Mail-Adresse ist nicht korrekt.";
      hasError = true;
    }

    if (!state.uid || state.password2 !== state.password) {
      if (state.password.length < 6) {
        passwordError = "Das Passwort muss mind 6 Zeichen lang sein";
        hasError = true;
      }

      if (state.password2 !== state.password) {
        password2Error = "Die Passwörter stimmen nicht überein";
        passwordError = "Die Passwörter stimmen nicht überein";
        hasError = true;
      }
    }

    setState({
      ...state,
      emailError: emailError,
      firstNameError: firstNameError,
      lastNameError: lastNameError,
      passwordError: passwordError,
      password2Error: password2Error,
    });

    return !hasError;
  }

  function renderGroupSelect() {
    if (state.userGroups.length < 2 || !state.isSuperAdmin) {
      return <></>;
    }

    return (
      <div className="row groupSelect mt-xlarge">
        <div className="col-12">
          <label className="">Zuzuordnende Gruppe wählen</label>
          <select id="listFilter" className="group-select" onChange={onChangeGroup}>
            {state.userGroups.map((item) => {
              const selected = state.groupName === item.title;
              return (
                <option value={item.uid} selected={selected}>
                  {item.title}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }

  return (
    <Modal show={props.show} keyboard className="custom-modal-style create-user-modal" animation>
      <Modal.Header>
        <div
          className="col-12 mb-medium d-flex justify-content-end"
          id="close"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.closeModal();
          }}
        >
          <Close />
        </div>
      </Modal.Header>
      <Modal.Body>
        <section id="modalContent">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="row justify-content-center mb-medium">
                <div className="col-xl-8 col-lg-10 col-md-12 create-user-modal-head">
                  {getHeadline()}
                  {getSubline()}
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="row gender mt-xlarge">
                          <label className="">Anrede</label>
                          <input
                            type="radio"
                            value="male"
                            name="gender"
                            checked={state.gender === "male"}
                            onChange={onChangeGender}
                          />
                          <span className="ml-small mr-large">Herr</span>
                          <input
                            type="radio"
                            value="female"
                            name="gender"
                            checked={state.gender === "female"}
                            onChange={onChangeGender}
                          />
                          <span className="ml-small mr-large">Frau</span>
                          <input
                            type="radio"
                            value="other"
                            name="gender"
                            checked={state.gender === "other"}
                            onChange={onChangeGender}
                          />
                          <span className="ml-small">Divers</span>
                        </div>
                      </div>
                      {renderGroupSelect()}
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6">
                            <div className="row">
                              <input
                                className={
                                  state.firstNameError
                                    ? "create-user-modal-input name error"
                                    : "create-user-modal-input name"
                                }
                                placeholder="Vorname"
                                type="text"
                                onChange={onChangeFirstName}
                                value={state.firstName}
                              />
                            </div>
                            <div className="row">
                              <span className="create-user-modal-error">
                                {state.firstNameError}
                              </span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <input
                                className={
                                  state.lastNameError
                                    ? "create-user-modal-input lastname error"
                                    : "create-user-modal-input lastname"
                                }
                                placeholder="Nachname"
                                type="text"
                                onChange={onChangeLastName}
                                value={state.lastName}
                              />
                            </div>
                            <div className="row">
                              <span className="create-user-modal-error">{state.lastNameError}</span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <input
                            className={
                              state.emailError || props.errors
                                ? "create-user-modal-input error"
                                : "create-user-modal-input"
                            }
                            placeholder="Email"
                            type="email"
                            onChange={onChangeEmail}
                            value={state.email}
                          />
                        </div>
                        <div className="row">
                          <span className="create-user-modal-error">
                            {props.errors ? props.errors : state.emailError}
                          </span>
                        </div>
                        {renderPasswordLabel()}
                        <div className="row">
                          <input
                            className={
                              state.passwordError
                                ? "create-user-modal-input error"
                                : "create-user-modal-input"
                            }
                            placeholder="Passwort"
                            type="password"
                            onChange={onChangePassword}
                          />
                        </div>
                        <div className="row">
                          <span className="create-user-modal-error">{state.passwordError}</span>
                        </div>

                        <div className="row">
                          <input
                            className={
                              state.password2Error
                                ? "create-user-modal-input error"
                                : "create-user-modal-input"
                            }
                            placeholder="Passwort wiederholen"
                            type="password"
                            onChange={onChangePassword2}
                          />
                        </div>
                        <div className="row">
                          {state.password2Error ? (
                            <span className="create-user-modal-error">{state.password2Error}</span>
                          ) : (
                            renderValidationNotice()
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-blue-transparent"
                        onClick={() => {
                          props.closeModal();
                        }}
                      >
                        Abbrechen
                      </button>
                      <button type="button" className="ml-xlarge btn" onClick={onSave}>
                        <span>Speichern</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserModal;
