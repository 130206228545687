// User and Authentication Routes
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const PROFILE = "/profile";
export const USER_LIST = "/userlist";
export const PASSWORD_RESET = "/password-reset";

// Internal Routes
export const DASHBOARD = "/";
export const PAGE = "/page/:slug";

// Meta Routes
export const IMPRINT = "/imprint";
export const PRIVACY = "/privacy";

export const { REACT_APP_BASE_URL } = process.env;
