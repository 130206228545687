/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
import React, { useLayoutEffect } from "react";
import * as short from "short-uuid";
import PropTypes from "prop-types";
import { formatDate } from "../../../Utils/DateUtils";
import { currency, percentage } from "../../../Utils/TextUtils";
import { createPieChart } from "../../../Utils/ChartUtils";
import { COLOR_ORANGE, COLOR_PURPLE, COLOR_VIOLET } from "../../../Constants/ChartConstants";

const FondFacts = (props) => {
  const { classes, content } = props;

  const elementClasses = `fond_facts ${classes}`;
  const fond = content.fond;
  const depot = content.depot;

  const rand = Math.random();

  useLayoutEffect(() => {
    if (content.displayType === "funds") {
      if (fond.regionStructure.length !== 0) {
        createPieChart(
          "chartdiv_regionStructure" + rand,
          "legenddiv_regionStructure" + rand,
          fond.regionStructure,
          COLOR_VIOLET,
        );
      }

      if (fond.businessStructure.length !== 0) {
        createPieChart(
          "chartdiv_businessStructure" + rand,
          "legenddiv_businessStructure" + rand,
          fond.businessStructure,
          COLOR_PURPLE,
        );
      }

      if (fond.assetStructure.length !== 0) {
        createPieChart(
          "chartdiv_assetStructure" + rand,
          "legenddiv_assetStructure" + rand,
          fond.assetStructure,
          COLOR_ORANGE,
        );
      }
    } else {
      if (depot.regionStructure.length !== 0) {
        createPieChart(
          "chartdiv_regionStructure" + rand,
          "legenddiv_regionStructure" + rand,
          depot.regionStructure,
          COLOR_VIOLET,
        );
      }

      if (depot.businessStructure.length !== 0) {
        createPieChart(
          "chartdiv_businessStructure" + rand,
          "legenddiv_businessStructure" + rand,
          depot.businessStructure,
          COLOR_PURPLE,
        );
      }

      if (depot.assetStructure.length !== 0) {
        createPieChart(
          "chartdiv_assetStructure" + rand,
          "legenddiv_assetStructure" + rand,
          depot.assetStructure,
          COLOR_ORANGE,
        );
      }
    }

    return () => {};
  }, []);

  function renderChart(headline, type) {
    const chart_id = `chartdiv_${type}${rand}`;
    const legend_id = `legenddiv_${type}${rand}`;

    return (
      <div className="col-4 chart">
        <h5>{headline}</h5>
        <div id={chart_id} className="pie_chart mt-medium" />
        <div id={legend_id} className="pie_chart_legend mt-medium" />
      </div>
    );
  }

  function renderFact(label, value) {
    return (
      <div key={short.uuid()} className="col-md-6 col-lg-4">
        <div className="fond_fact">
          <div className="label">{label}</div>
          <div className="value">{value}</div>
        </div>
      </div>
    );
  }

  function renderStructure() {
    if (content.displayType === "funds") {
      if (
        fond.regionStructure.length === 0 &&
        fond.businessStructure.length === 0 &&
        fond.assetStructure.length === 0
      ) {
        return <div />;
      }

      return (
        <div>
          <div className="row">
            <div className="col-12 mt-xxlarge">
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card mt-xxlarge">
                <h4>Fondsstruktur</h4>
                <div className="row charts mt-large">
                  {renderChart("Regionen", "regionStructure")}
                  {renderChart("Branchen", "businessStructure")}
                  {renderChart("Assetsklassen", "assetStructure")}
                </div>
                <div className="row card_footer justify-content-center mt-xxlarge">
                  <div className="col-10">
                    <div className="card_label mb-small">Hinweis:</div>
                    <div className="text">
                      Der LAIC-Fonds kann mittelbar über Publikumsfonds und ETF´s in bis zu 1.000
                      Einzeltitel investieren. Die abgebildete Fondsallokation stellt die aktuelle
                      Aufteilung der allokierten Publikumsfonds und ETFs nach Branchen, Regionen und
                      Assetklassen gemäß der Spezifikation der jeweiligen
                      Kapitalverwaltungsgesellschaft dar.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        depot.regionStructure.length === 0 &&
        depot.businessStructure.length === 0 &&
        depot.assetStructure.length === 0
      ) {
        return <div />;
      }

      return (
        <div>
          <div className="row">
            <div className="col-12 mt-xxlarge">
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card mt-xxlarge">
                <h4>Depotstruktur</h4>
                <div className="row charts mt-large">
                  {renderChart("Regionen", "regionStructure")}
                  {renderChart("Branchen", "businessStructure")}
                  {renderChart("Assetsklassen", "assetStructure")}
                </div>
                <div className="row card_footer justify-content-center mt-xxlarge">
                  <div className="col-10">
                    <div className="card_label mb-small">Hinweis:</div>
                    <div className="text">
                      Der LAIC-Fonds kann mittelbar über Publikumsfonds und ETF´s in bis zu 1.000
                      Einzeltitel investieren. Die abgebildete Fondsallokation stellt die aktuelle
                      Aufteilung der allokierten Publikumsfonds und ETFs nach Branchen, Regionen und
                      Assetklassen gemäß der Spezifikation der jeweiligen
                      Kapitalverwaltungsgesellschaft dar.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function renderKeyFacts() {
    if (content.displayType === "funds") {
      return (
        <>
          <div className="row is-flex mt-xxlarge">
            {renderFact("Rechtsform", fond.legalForm)}
            {renderFact("Verwahrstelle / Depotbank", fond.custodianBankName)}
            {renderFact("Auflagedatum", formatDate(fond.launchedAt))}
          </div>
          <div className="row is-flex mt-xxlarge">
            {renderFact("Fondsvolumen gesamt", currency(fond.volume, "EUR"))}
            {renderFact("Geschäftsjahresende", formatDate(fond.financialYearEndAt))}
            {renderFact("NAV", currency(fond.nav, "EUR"))}
          </div>
          <div className="row is-flex mt-xxlarge">
            {renderFact("Ertragsverwendung", fond.distributionType)}
            {renderFact("Rücknahmepreis", currency(fond.redemptionPrice, "EUR"))}
            {renderFact("Letzte Ausschüttung", formatDate(fond.lastDistributionAt))}
          </div>
          <div className="row is-flex mt-xxlarge">
            {renderFact("Laufende Kosten", percentage(fond.ongoingChargesPerc))}
            {renderFact("KVG / Verwaltungsgesellschaft", fond.holdingCompanyName)}
            {renderFact("Stand", formatDate(fond.referenceDate))}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="row is-flex mt-xxlarge">
            {renderFact("Risikoklasse", depot.srri_class_target)}
            {renderFact("Auflagevolumen", currency(depot.opening_cash, "EUR"))}
            {renderFact("Auflagedatum", depot.opening_at)}
          </div>
          <div className="row is-flex mt-xxlarge">
            {renderFact("Depotvolumen gesamt", currency(depot.depot_value, "EUR"))}
            {renderFact(
              "Performance Seit Auflage",
              content.depotPerfArbs.performanceAbsolute + "%",
            )}
            {renderFact("Stand", formatDate(depot.reference_date))}
          </div>
        </>
      );
    }
  }

  return (
    <div key={short.uuid()} className={elementClasses}>
      <h4>{props.content.header}</h4>
      {renderKeyFacts()}
      {renderStructure()}
    </div>
  );
};

FondFacts.propTypes = {
  classes: PropTypes.string,
  content: PropTypes.instanceOf(Object),
};

export default React.memo(FondFacts);
