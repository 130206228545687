import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as short from "short-uuid";
import { NavDropdown, Navbar } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { connect } from "react-redux";
import { Routes, Text } from "../../Constants";
// import { ReactComponent as Home } from "../../Images/SVG/home.svg";
import { getUserName, getGroupLogo, isUserAdmin } from "../../Utils/UserUtils";
import { loadNavigation } from "../../Redux/NavigationRedux";

const Navigation = (props) => {
  function calcSubNav() {
    const subNav = [];
    if (props.page !== null && props.page.items) {
      switch (props.page.common.layout) {
        case "default": {
          const items = props.page.items.colPos0;
          if (items) {
            items.forEach(function (item) {
              if (item.navigation.sectionIndex === true) {
                const anchorTarget = `#${item.id}_${item.type}`;
                const newElement = {
                  anchorTarget,
                  label: item.navigation.sectionTitle,
                };
                subNav.push(newElement);
              }
            });
          }
          break;
        }
        default:
      }
    }

    return subNav;
  }

  function renderHome() {
    const logoUrl = Routes.REACT_APP_BASE_URL + getGroupLogo();

    return (
      <div className="navbar-home">
        <a href={Routes.DASHBOARD}>
          <span>
            <img src={logoUrl} className="brand" alt="" />
          </span>
        </a>
      </div>
    );
  }

  function renderSubNav() {
    if (props.history.location.pathname === Routes.DASHBOARD) {
      return <span />;
    }

    const subNav = calcSubNav();
    if (subNav && subNav.length > 0) {
      return (
        <div className="subNav d-sm-none d-none d-md-flex">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">{renderSubNavItems(subNav)}</div>
            </div>
          </div>
        </div>
      );
    }
  }

  function renderSubNavItems(subNav) {
    return subNav.map((item) => renderSubNavItem(item));
  }

  function renderSubNavItem(item) {
    return (
      item &&
      item.label && (
        <a key={short.uuid()} href={item.anchorTarget} className="subLink">
          {item.label ? item.label : "Label missing"}
        </a>
      )
    );
  }

  function renderNavigation() {
    if (props.navigation) {
      return props.navigation.map((item) => renderNavigationItem(item));
    }
  }

  function renderNavigationItem(item) {
    if (item) {
      return (
        <NavDropdown
          key={item.title}
          title={item.title}
          id="basic-nav-dropdown"
          className="nav-categoy"
        >
          {renderChildren(item.children)}
        </NavDropdown>
      );
    }
  }

  function renderChildren(children) {
    if (children) {
      const maxItems = children.length;
      return children.map((child, index) => renderChild(child, index, maxItems));
    }
  }

  function renderChild(child, index, maxItems) {
    if (index + 1 === maxItems) {
      return (
        <div key={`nav-${index}`}>
          <NavDropdown.Item href={child.link} className="child">
            {child.title}
          </NavDropdown.Item>
        </div>
      );
    }
    return (
      <div key={`nav-${index}`}>
        <NavDropdown.Item href={child.link} className="child">
          {child.title}
        </NavDropdown.Item>
        <NavDropdown.Divider />
      </div>
    );
  }

  const [isNavigationLoaded, setNavigationLoaded] = useState(false);
  useEffect(() => {
    if (!isNavigationLoaded) {
      props.loadNavigation();
      setNavigationLoaded(true);
    }
  }, [isNavigationLoaded, props]);

  return (
    <div className="headNav">
      <Navbar expand="lg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                {renderHome()}

                <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto" />
                <Navbar.Collapse id="basic-navbar-nav" className="float-right">
                  <Nav className="mr-auto funds-nav justify-content-center">
                    {renderNavigation()}
                  </Nav>
                  <Nav className="profile-nav">
                    <NavDropdown title={getUserName()} id="basic-nav-dropdown" className="profile">
                      <NavDropdown.Item href="/profile" className="child">
                        Profil
                      </NavDropdown.Item>
                      {isUserAdmin() && <NavDropdown.Divider />}
                      {isUserAdmin() && (
                        <NavDropdown.Item href="/userlist" className="child">
                          Benutzer verwalten
                        </NavDropdown.Item>
                      )}
                      <NavDropdown.Divider />
                      <NavDropdown.Item href={Routes.LOGOUT} className="logout child">
                        {Text.LOGOUT}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      {renderSubNav()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    page: state.page.page,
    success: state.navigation.success,
    fetching: state.navigation.fetching,
    error: state.navigation.error,
    navigation: state.navigation?.navigation?.page,
  };
};

export default connect(mapStateToProps, { loadNavigation })(withRouter(Navigation));
