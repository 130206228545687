/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BackLink from "../Components/Common/BackLink";
import PreFooter from "../Components/Footer/PreFooter";
import Elements from "../Components/ContentElements/Elements";

import { loadPage, clearItems } from "../Redux/PageRedux";
import DebugInfo from "../Components/Common/DebugInfo";

const PageContainer = (props) => {
  function renderContent() {
    if (props.page !== null && props.page.items) {
      switch (props.page.common.layout) {
        case "default": {
          const items = props.page.items.colPos0;
          if (items) {
            return <Elements items={items} />;
          }
          break;
        }
        default:
          return <DebugInfo json={props} />;
      }
    }

    return <div />;
  }

  const [isPageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (!isPageLoaded) {
      props.clearItems();
      props.loadPage(props.history.location.pathname);
      setPageLoaded(true);
    }
  }, [isPageLoaded, props]);

  return (
    <section id="pageContent">
      <div className="row justify-content-center mb-medium">
        <div className="col-xl-10 col-lg-12">
          <BackLink />
          {renderContent()}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="login mb-xlarge">
          <PreFooter />
        </div>
      </div>
    </section>
  );
};

PageContainer.propTypes = {
  success: PropTypes.bool,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  page: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    success: state.page.success,
    fetching: state.page.fetching,
    error: state.page.error,
    page: state.page.page,
    items: state.page.items,
  };
};

export default connect(mapStateToProps, { loadPage, clearItems })(withRouter(PageContainer));
