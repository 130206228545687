import React from "react";
import PropTypes from "prop-types";

import * as short from "short-uuid";

const ExternalItem = (props) => {
  return (
    <a
      key={short.uuid()}
      href={props.link}
      target={props.link.startsWith("http") ? "_blank" : ""}
      rel={props.link.startsWith("http") ? "noreferrer" : ""}
      className="btn btn-white btn-small mr-medium"
      style={{ display: "inline-flex", alignItems: "center" }}
    >
      <span>{props.title}</span>
    </a>
  );
};

ExternalItem.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
};

export default ExternalItem;
