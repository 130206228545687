import React from "react";
import * as short from "short-uuid";
import PropTypes from "prop-types";

const Cards = ({ headline, items, classes }) => {
  const renderHeadline = () => {
    if (headline.length < 1) {
      return <span />;
    }

    return (
      <div className="col-12">
        <h2>{headline}</h2>
      </div>
    );
  };

  const renderItem = (item) => {
    return (
      <div key={short.uuid()} className="col-md-6 mb-large">
        <div className="card">
          <h3>{item.header}</h3>
          <span className="subline mb-small">{item.subheader}</span>
          <div className="copy" dangerouslySetInnerHTML={{ __html: item.bodytext }} />
        </div>
      </div>
    );
  };

  const renderItems = () => {
    return items.map((item) => renderItem(item));
  };

  const elementClasses = `cards row ${classes}`;

  return (
    <div className={elementClasses}>
      {renderHeadline()}
      <div className="row is-flex">{renderItems()}</div>
    </div>
  );
};

Cards.propTypes = {
  headline: PropTypes.string,
  items: PropTypes.instanceOf(Array),
  classes: PropTypes.string,
};

export default Cards;
