import React from "react";
import DownloadCardItem from "./DownloadCardItem";
import short from "short-uuid";
import DownloadLinkCardItem from "./DownloadLinkCardItem";

const MixedSourceMultiDownloadCards = (props) => {
  function renderDownloadListLeft() {
    const maxItems = props.files_left.length - 1;
    return props.files_left.map((item, index) => renderDownloadItem(item, index, maxItems));
  }

  function renderDownloadListRight() {
    const maxItems = props.files_right.length - 1;
    return props.files_right.map((item, index) => renderDownloadItem(item, index, maxItems));
  }

  const renderDownloadItem = (item, index, maxItems) => {
    let rulerClass = "visible";
    if (index === maxItems) {
      rulerClass = "invisible";
    }

    if (item.type === "internal" && item["asset"]) {
      const screenName =
        item.asset.properties.title !== null
          ? item.asset.properties.title
          : item.asset.properties.filename;

      return (
        <DownloadCardItem
          key={short.uuid()}
          title={screenName}
          item={item.asset}
          rulerClass={rulerClass}
        />
      );
    } else if (item.type === "external") {
      return <DownloadLinkCardItem key={short.uuid()} item={item.link} rulerClass={rulerClass} />;
    }

    return <></>;
  };

  const elementClasses = `multiple-download-cards ${props.classes}`;

  return (
    <div className={elementClasses}>
      <div className="row is-flex">
        {props.files_left.length > 0 && (
          <div className="col-md-6 mb-large">
            <div className="card download-list">
              <h3>{props.header_left}</h3>
              {renderDownloadListLeft()}
            </div>
          </div>
        )}
        {props.files_right.length > 0 && (
          <div className="col-md-6 mb-large">
            <div className="card download-list">
              <h3>{props.header_right}</h3>
              {renderDownloadListRight()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MixedSourceMultiDownloadCards;
