import React from "react";
import PropTypes from "prop-types";

const SimpleImage = ({ classes, headline, image }) => {
  const elementClasses = `simpleImage row ${classes}`;

  return (
    <div className={elementClasses}>
      <div className="col-12 mb-large">
        <h2>{headline}</h2>
      </div>
      <div className="col-12">
        <img src={image} alt="" width="100%" height="100%" />
      </div>
    </div>
  );
};

SimpleImage.propTypes = {
  classes: PropTypes.string,
  headline: PropTypes.string,
  image: PropTypes.string,
};

export default SimpleImage;
