import React, { useState } from "react";
import BackLink from "../Common/BackLink";
import PreFooter from "../Footer/PreFooter";
import { getApi } from "../../Utils/ApiUtils";
import { PUBLIC_PRIVACY } from "../../Constants/ApiConstants";

const PrivacyFlex = () => {
  const [state, setState] = useState({
    headline: "Datenschutz",
    content: "",
    loaded: false,
  });

  const getContent = async () => {
    const { data } = await getApi(PUBLIC_PRIVACY, {});
    if (data && data.content) {
      if (data.content.colPos0 && data.content.colPos0[0]) {
        const set = data.content.colPos0[0].content;
        setState({
          headline: set.header,
          content: set.bodytext,
          loaded: true,
        });
      }
    }
  };

  function renderContent() {
    if (state.loaded === false) {
      getContent();
    }
    return (
      <div className="col-10">
        <BackLink />
        <div className="mb-large">
          <h1>Datenschutz</h1>
        </div>
        <div className="mb-medium">
          <h2>{state.headline}</h2>
        </div>
        <div className="copy" dangerouslySetInnerHTML={{ __html: state.content }} />
      </div>
    );
  }

  return (
    <section id="privacy" className="meta">
      <div className="row justify-content-center">{renderContent()}</div>

      <div className="row justify-content-center mt-xlarge mb-xxlarge">
        <div className="col-lg-10 col-md-10 col-xs-12 separator" />
      </div>

      <div className="row justify-content-center">
        <div className="col-4">
          <PreFooter />
        </div>
      </div>
    </section>
  );
};

export default PrivacyFlex;
