import React, { useState } from "react";
import { connect } from "react-redux";
import { addToDownloadList, deleteFromDownloadList } from "../../Redux/DownloadRedux";
import { openGetStartedModal } from "../../Redux/GetStartedRedux";

import VideoModal from "./VideoModal";

const TeaserImageText = (props) => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  function renderDownload() {
    if (props.file === false || typeof props.file === "undefined" || props.file.length < 1) {
      return <span />;
    }
    // only pull first asset for the moment
    let file = props.file[0];

    if (file.properties.type === "video" && props.files && props.files.length > 0) {
      file = props.files[0];
    }

    return (
      <div className="download mt-xlarge">
        <span className="download_label">Dokument: </span>
        {renderCartButton(file)}

        <a
          href={file.publicUrl}
          download={file.properties.filename}
          className="btn btn-negative btn-download btn-small pb-small"
          target="_blank"
          rel="noopener noreferrer"
        >
          Downloaden
        </a>
      </div>
    );
  }

  const addOrdDeleteFromList = (item) => {
    if (checkFileAddedToBasket(item)) {
      props.deleteFromDownloadList(item);
    } else {
      props.addToDownloadList(item);
    }
  };

  const checkFileAddedToBasket = (file) => {
    if (file.properties.uidLocal) {
      return props.files.some((el) => el.properties.uidLocal === file.properties.uidLocal);
    }
    return props.files.some(
      (el) => el.properties.fileReferenceUid === file.properties.fileReferenceUid,
    );
  };

  const renderCartButton = (file) => {
    if (checkFileAddedToBasket(file)) {
      return (
        <div
          onClick={() => addOrdDeleteFromList(file)}
          className="btn btn-download-list remove btn-small pb-small"
        >
          Von Downloadliste
        </div>
      );
    }
    return (
      <div
        onClick={() => addOrdDeleteFromList(file)}
        className="btn btn-download-list add btn-small pb-small"
      >
        Zur Downloadliste
      </div>
    );
  };

  const renderVideoModalButton = () => {
    if (props.file && props.file.find((el) => el.properties.type === "video") !== undefined) {
      return (
        <>
          <button
            onClick={() => {
              setShowVideoModal(true);
            }}
            type="button"
            className="btn btn-small btn-negative mr-large"
          >
            Video Ansehen
          </button>
          <VideoModal
            src={props.file.find((el) => el.properties.type === "video").publicUrl}
            showModal={showVideoModal}
            hideModal={() => setShowVideoModal(false)}
          />
        </>
      );
    }

    return <></>;
  };

  const renderGetStartedButtonButton = () => {
    if (props.showGetStarted === "1") {
      return (
        <>
          <button
            type="button"
            className="btn btn-small"
            onClick={() => {
              props.openGetStartedModal();
            }}
          >
            Get Started
          </button>
        </>
      );
    }
    return <></>;
  };

  const elementClasses = `row teaserImageText ${props.classes}`;
  return (
    <div className={elementClasses}>
      <div className="col-md-5 mb-xlarge">
        <img src={props.image} className="image" alt="" />
      </div>
      <div className="col-md-7 ">
        {props.headline && (
          <div className="mb-large">
            <h2>{props.headline}</h2>
          </div>
        )}
        <div className="copy" dangerouslySetInnerHTML={{ __html: props.copy }} />
        {renderVideoModalButton()}
        {renderGetStartedButtonButton()}
        {renderDownload()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    files: state.downloads.fileCollection,
  };
};

export default connect(mapStateToProps, {
  addToDownloadList,
  deleteFromDownloadList,
  openGetStartedModal,
})(TeaserImageText);
