import React from "react";

import Person from "./Person";

const ContactPerson = (props) => {
  const { contact } = props.item.content;

  return (
    <div className="contact_person">
      <div className="row">
        <div className="col-6">
          <div className="row service-item">
            <div className="label col-12">Telefon</div>
            <div className="col-12">
              <a href={`tel:${contact.phone}`}>{contact.phone}</a>
            </div>
          </div>
          <div className="row service-item">
            <div className="label col-12">E-Mail</div>
            <div className="col-md-12">
              <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </div>
          </div>
          <div className="row service-item">
            <div className="label col-12">Servicezeiten</div>
            <div
              className="col-12 copy"
              dangerouslySetInnerHTML={{ __html: contact.description }}
            />
          </div>
        </div>
        <div className="col-6">
          <Person
            firstName={contact.firstName}
            lastName={contact.lastName}
            url={contact.image.publicUrl}
            position={contact.position}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactPerson;
