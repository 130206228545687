import React from "react";
import * as short from "short-uuid";
import { buildScreenName, currency } from "../../../Utils/TextUtils";
import { formatDate } from "../../../Utils/DateUtils";

const Intro = (props) => {
  const classes = `row fonds-intro ${props.classes}`;
  const { fond } = props.content;
  const { contact } = props.content;
  const { content } = props;
  const { depot } = props.content;

  function renderManager() {
    const inlineStyles = {
      backgroundImage: `url(${contact.image.publicUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };

    return (
      <div className="col-sm-12 col-md-5 manager">
        <div className="profileImage mb-medium" style={inlineStyles} />
        {renderInfo()}
      </div>
    );
  }

  function renderInfo() {
    const screenName = buildScreenName(contact);

    if (screenName.length > 0) {
      return (
        <div className="info">
          <div className="label mb-small">Fondsmanager</div>
          <h4>{buildScreenName(contact)}</h4>
          <div className="position">{contact.position}</div>
        </div>
      );
    }
  }

  function renderIndex(index) {
    const classes = "col indikator text-center";

    if (content.displayType === "funds") {
      if (index === fond.riskClass) {
        return (
          <div className={classes}>
            <span className="active">{index}</span>
            <span className="marker" />
          </div>
        );
      }
    } else {
      if (index === depot.srri_class_target) {
        return (
          <div className={classes}>
            <span className="active">{index}</span>
            <span className="marker" />
          </div>
        );
      }
    }

    return (
      <div className={classes}>
        <span>{index}</span>
      </div>
    );
  }

  function renderRiskIndicator() {
    return (
      <div className="riskmesure mt-xxlarge">
        <div className="row pl-small pr-small">
          {renderIndex("1")}
          {renderIndex("2")}
          {renderIndex("3")}
          {renderIndex("4")}
          {renderIndex("5")}
          {renderIndex("6")}
          {renderIndex("7")}
        </div>
        <div className="arrow-line">
          <div className="st">
            <div className="arrow left" />
          </div>
          <div className="st">
            <div className="arrow right float-right" />
          </div>
        </div>
        <div className="row legend mt-large">
          <div className="col-6">
            <div className="label">Geringes Risiko</div>
            <div className="value">Potentiell geringerer Ertrag</div>
          </div>
          <div className="col-6 text-right">
            <div className="label">Höheres Risiko</div>
            <div className="value">Potentiell höherer Ertrag</div>
          </div>
        </div>
      </div>
    );
  }

  function renderMainFacts() {
    if (content.displayType === "funds") {
      return (
        <div className="row pb-large main-facts">
          <div className="col">
            <div className="label mb-small">ISIN</div>
            <div className="value">{fond.isin}</div>
          </div>
          <div className="col">
            <div className="label mb-small">WKN</div>
            <div className="value">{fond.wkn}</div>
          </div>
          <div className="col">
            <div className="label mb-small">NAV</div>
            <div className="value">{currency(fond.nav, "EUR")}</div>
          </div>
          <div className="col">
            <div className="label mb-small">Fondsvolumen Gesamt</div>
            <div className="value">{currency(fond.volume, "EUR")}</div>
          </div>
          <div className="col">
            <div className="label mb-small">Stand</div>
            <div className="value">{formatDate(fond.referenceDate)}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row pb-large main-facts">
          <div className="col">
            <div className="label mb-small">Auflagedatum</div>
            <div className="value">{depot.opening_at}</div>
          </div>
          <div className="col">
            <div className="label mb-small">Auflagevolumen</div>
            <div className="value">{currency(depot.opening_cash, "EUR")}</div>
          </div>
          <div className="col">
            <div className="label mb-small">Depotvolumen gesamt</div>
            <div className="value">{currency(depot.depot_value, "EUR")}</div>
          </div>
          <div className="col">
            <div className="label mb-small">Seit Auflage</div>
            <div className="value">{content.depotPerfArbs.performanceAbsolute + "%"}</div>
          </div>
          <div className="col">
            <div className="label mb-small">Stand</div>
            <div className="value">{formatDate(depot.reference_date)}</div>
          </div>
        </div>
      );
    }
  }

  function renderFacts() {
    return (
      <div className="col-sm-12 col-md-7">
        <div className="row">
          <div className="col-12 fond-keys">{renderMainFacts()}</div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr />
          </div>
        </div>
        <div className="row mt-large">
          <div className="col-12">
            <h4>Risikoindikator</h4>
            {renderRiskIndicator()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div key={short.uuid()} className={classes}>
      {renderManager()}
      {renderFacts()}
    </div>
  );
};

export default Intro;
