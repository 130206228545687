/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from "react";
import short from "short-uuid";

const FilterItem = (props) => {
  return (
    <span
      className={`filter-item ${props.isActive ? "active-filter" : ""}`}
      key={short.uuid()}
      onClick={() => props.setFilter()}
    >
      {props.title}
    </span>
  );
};

export default FilterItem;
