/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { connect } from "react-redux";
import {
  setSidebarOpen,
  setSidebarClose,
  downloadDownloadList,
  deleteFromDownloadList,
  clearDownloadList,
  openMailModal,
} from "../../../Redux/DownloadRedux";
import IconDownload from "../../../Images/SVG/download_list_icon.svg";
import IconClose from "../../../Images/SVG/close_cross.svg";
import DownloadList from "./DownloadList";
import { isAuthenticated } from "../../../Utils/UserUtils";

const SideDrawer = (props) => {
  if (!isAuthenticated()) {
    return <></>;
  }

  return (
    <div className={props.download.sidebarOpen ? "side-drawer open" : "side-drawer"}>
      <div className="dl">
        <div className="dl-header">
          <div className="row align-items-center ml-small-2 mr-small-2">
            <img src={IconDownload} alt="IconDownload" className="dl-header-icon" />
            <h3>Downloadliste</h3>
            <div className="spacer" />
            <img
              src={IconClose}
              alt="IconClose"
              className="dl-header-close"
              onClick={() => props.setSidebarClose()}
            />
          </div>
        </div>
        <DownloadList
          download={props.download}
          deleteFromDownloadList={props.deleteFromDownloadList}
          clearDownloadList={props.clearDownloadList}
          openMailModal={props.openMailModal}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    download: state.downloads,
  };
};

export default connect(mapStateToProps, {
  setSidebarOpen,
  setSidebarClose,
  downloadDownloadList,
  deleteFromDownloadList,
  clearDownloadList,
  openMailModal,
})(SideDrawer);
