import React, { useState } from "react";

import { Dropdown } from "react-bootstrap";
import Spinner from "../../Common/Spinner";
import { ReactComponent as Add } from "../../../Images/SVG/download_add.svg";
import { ReactComponent as Checked } from "../../../Images/SVG/download_added.svg";
import { ReactComponent as Delete } from "../../../Images/SVG/close_cross_grey.svg";

import { postApi } from "../../../Utils/ApiUtils";
import { BROCHURE_ORDER } from "../../../Constants/ApiConstants";

const BrochureOrderForm = (props) => {
  const classes = `row justify-content-center brochure-order ${props.classes}`;

  const [files] = useState(props.content.materials);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const addFile = (file) => {
    setSelectedFiles([...selectedFiles, file]);
  };

  const removeFile = (file) => {
    setSelectedFiles(selectedFiles.filter((fileFromArray) => fileFromArray.uid !== file.uid));
  };

  const isSelected = (file) => {
    return selectedFiles.find((fileFromArray) => fileFromArray.uid === file.uid);
  };

  const onpackageChange = (file, newpackage) => {
    setSelectedFiles(
      selectedFiles.map((fileFromArray) =>
        fileFromArray.uid === file.uid ? { ...fileFromArray, package: newpackage } : fileFromArray,
      ),
    );
  };

  const onMessageChange = ({ target: { value } }) => {
    setMessage(value);
  };

  const onOrder = async (e) => {
    e.preventDefault();

    setResponse(null);
    setIsLoading(true);

    const data = {
      rest: {
        form: {
          material: selectedFiles.map((selectedFile) => ({
            uid: selectedFile.uid,
            package: selectedFile.package,
          })),
          recivers: props.content.formRecivers,
          message,
        },
      },
    };

    try {
      const { payload, error } = await postApi(BROCHURE_ORDER, data);

      setResponse({ type: payload.status, message: payload.message });

      if (error) {
        throw error;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSelectedFiles([]);
      setMessage([]);
      setIsLoading(false);
    }
  };

  return (
    <div className={classes}>
      <div className="col-12">
        <div className="brochure-order-list">
          <div className="card download-list mt-large">
            <div className="row">
              <div className="col-12">
                <div className="mb-large">
                  <h3>{props.content.header}</h3>
                </div>
              </div>
            </div>

            {files.map((file, index) => {
              const availablePackages = Object.values(file.packages);
              const selectedFile = isSelected(file);

              return (
                <React.Fragment key={`${file.name}_${file.uid}`}>
                  <div className="file-status-bar">
                    <div className="row">
                      <div className="col-md-8 d-flex align-items-center mb-3 mb-md-0">
                        <div className="mr-3">
                          {selectedFile ? (
                            <Checked className="action-button" />
                          ) : (
                            <button
                              onClick={() => {
                                addFile({ ...file, package: availablePackages[0] });
                              }}
                              type="button"
                              className="action-button"
                            >
                              <Add />
                            </button>
                          )}
                        </div>
                        <span className="file-name">{file.name}</span>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-6 d-flex justify-content-md-end">
                            {selectedFile ? (
                              <Dropdown className="dropdown-select">
                                <Dropdown.Toggle
                                  id={`dropdown-select_${index}`}
                                  variant="light"
                                  className="brochoure-order-select"
                                >
                                  {selectedFile.package}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {availablePackages.map((brochurPackage) => (
                                    <Dropdown.Item
                                      key={brochurPackage}
                                      active={brochurPackage === selectedFile.package}
                                      onClick={() => onpackageChange(file, brochurPackage)}
                                    >
                                      {brochurPackage}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            ) : null}
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                            {selectedFile ? (
                              <button
                                type="button"
                                className="action-button"
                                onClick={() => removeFile(file)}
                              >
                                <Delete />
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className={index < files.length - 1 ? "visible" : "invisible"} />
                </React.Fragment>
              );
            })}
          </div>
        </div>

        <div className="mt-xxxlarge">
          <h2>{props.content.formHeader}</h2>
          <b>{props.content.formSubline}</b>

          <div className="brochure-order-message mt-xlarge">
            <label>Nachricht</label>
            <input type="text" value={message} onChange={onMessageChange} />
          </div>
        </div>

        {response?.message && (
          <>
            <div
              className={`brochure-order-alert alert alert-${
                response.type === "success" ? "success" : "danger"
              } mt-xxlarge`}
              role="alert"
            >
              <span
                className="alert-message"
                dangerouslySetInnerHTML={{ __html: response.message }}
              />

              <button
                type="button"
                className="brochure-order-alert-toggler"
                onClick={() => setResponse(null)}
              >
                <Delete />
              </button>
            </div>

            <hr className="mt-xxlarge" />
          </>
        )}

        <div className="mt-xxlarge">
          <button type="button" onClick={onOrder} className="btn brochure-order-send">
            JEZT BESTELLEN
          </button>
        </div>

        <Spinner show={isLoading} />
      </div>
    </div>
  );
};

export default BrochureOrderForm;
