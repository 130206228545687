import React from "react";

import * as short from "short-uuid";
import { ReactComponent as Phone } from "../../Images/SVG/phone.svg";

const PhoneItem = (props) => {
  return (
    <span key={short.uuid()} id="phone">
      <a href={props.link}>
        <Phone className="mr-small" />
        {props.title}
      </a>
    </span>
  );
};

export default PhoneItem;
