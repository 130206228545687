import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { postApi, getApi, createApiSause } from "../Utils/ApiUtils";
import {
  USER_AUTHENTICATION,
  REACT_APP_FE_USER_FOLDER,
  DASHBOARD_ROUTE,
} from "../Constants/ApiConstants";
import * as UserUtil from "../Utils/UserUtils";
import { Text } from "../Constants";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginRequest: ["username", "password"],
  loginSuccess: ["user"],
  loginFailure: ["error"],
  logout: null,
});

export const LoginTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  username: null,
  success: null,
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

/**
 * Attempting to login
 *
 * @param state
 * @param username
 */
export const request = (state, { username }) => {
  return { ...state, fetching: true, error: null, success: null, username };
};

/**
 * Successfully logged in
 *
 * @param state
 */
export const success = (state) => {
  return { ...state, fetching: false, error: null, success: true };
};

/**
 * Had a problem logging in
 *
 * @param state
 * @param error
 */
export const failure = (state, { error }) => {
  return { ...state, fetching: false, success: false, error };
};

/**
 * Login
 *
 * @param username
 * @param password
 */
export const attemptLogin = (username, password) => async (dispatch) => {
  dispatch(Creators.loginRequest(username));

  const { payload, error } = await postApi(USER_AUTHENTICATION, {
    user: username,
    pass: password,
    logintype: "login",
    pid: REACT_APP_FE_USER_FOLDER,
  });

  if (payload && payload.status === "logged-in") {
    UserUtil.setUser(payload);

    // LPP-204
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "login",
      userId: payload.userObject.uid,
    });

    const api = createApiSause();

    // NEED TO DO THIS BECAUSE OF Typo3 CORE AUTH CHECK PROBLEM
    // LPP-233 - added query string param to prevent request batching on iOS
    await api.get(`${DASHBOARD_ROUTE}?ts=${Date.now()}`, {});

    dispatch(Creators.loginSuccess());
  }

  if (payload && payload.status === "login failure") {
    dispatch(Creators.loginFailure(Text.LOGIN_ERROR));
  }

  if (error) {
    dispatch(Creators.loginFailure(error.message));
  }
};

/**
 * Logged out
 *
 * @param state
 */
export const logout = (state) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: success,
  [Types.LOGIN_FAILURE]: failure,
  [Types.LOGOUT]: logout,
});
