/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import * as short from "short-uuid";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addToDownloadList } from "../../Redux/DownloadRedux";
import DownloadCardItem from "./DownloadCardItem";

const DownloadAndNewsCard = (props) => {
  const renderEvent = () => {
    if (props.news[0]) {
      return (
        <div className="card">
          <h3>Webinare & Schulungen</h3>

          <span className="subline">{props.news[0].title}</span>
          <div
            className="copy mt-large"
            dangerouslySetInnerHTML={{ __html: props.news[0].teaser }}
          />
          {renderEventlink(props.news[0])}
        </div>
      );
    }
    return (
      <div className="card">
        <h3>Webinare & Schulungen</h3>
      </div>
    );
  };

  const renderEventlink = (event) => {
    if (event.eventlink.length) {
      return (
        <a
          href={props.news[0].eventlink}
          target="_blank"
          className="external-link"
          rel="noopener noreferrer"
        >
          {props.news[0].eventlinktext}
        </a>
      );
    }
  };

  const renderDownload = () => {
    if (props.file === false || typeof props.file === "undefined" || props.file.length < 1) {
      return <span />;
    }
    // only pull first asset for the moment
    const file = props.file[0];

    return (
      <div className="download mt-xlarge">
        <span className="download_label">Dokument:</span>
        <a
          href={file.publicUrl}
          download={file.properties.filename}
          className="btn btn-negative btn-download"
          target="_blank"
          rel="noopener noreferrer"
        >
          Downloaden
        </a>
      </div>
    );
  };

  const renderDownloadList = () => {
    return props.files.map((item, index) => renderDownloadItem(item, index));
  };

  const renderDownloadItem = (item, index) => {
    const maxItems = props.files.length - 1;
    let rulerClass = "visible";
    if (index === maxItems) {
      rulerClass = "invisible";
    }

    const screenName =
      item.properties.title !== null ? item.properties.title : item.properties.filename;

    return (
      <DownloadCardItem key={short.uuid()} title={screenName} item={item} rulerClass={rulerClass} />
    );
  };

  return (
    <div className={`download-news-card ${props.classes}`}>
      <div className="row is-flex">
        <div className="col-md-6 mb-large">
          <div className="card download-list">
            <h3>{props.subheader}</h3>
            {renderDownloadList()}
          </div>
        </div>
        <div className="col-md-6 mb-large">{renderEvent()}</div>
      </div>
      <div className="row documents">
        <div className="col-12">{renderDownload()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    download: state.downloads,
  };
};

export default connect(mapStateToProps, { addToDownloadList })(withRouter(DownloadAndNewsCard));
