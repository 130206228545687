import { createReducer, createActions } from "reduxsauce";
import { reactLocalStorage } from "reactjs-localstorage";
import Immutable from "seamless-immutable";
import { postApi } from "../Utils/ApiUtils";
import { Routes } from "../Constants";
import { DOWNLOAD_ONDEMAND, MAIL_ONDEMAND } from "../Constants/ApiConstants";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  openSidebar: null,
  closeSidebar: null,
  mailModalOpen: ["value"],
  addToFileCollection: ["file"],
  addEmailFile: ["file"],
  deleteFromFileCollection: ["fileId"],
  deleteEmailFile: null,
  clearFileCollection: null,
});

export const DownloadTypes = Types;

export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  sidebarOpen: false,
  mailModalOpen: false,
  fileCollection: reactLocalStorage.getObject("savedFileState", { files: [] }).files,
  emailFile: null,
});

// TODO: Add emailFile: [val] and the methods for that. For a single file.  and customize the email modal.
/* ------------- Reducers ------------- */

export const openSidebar = (state) => {
  return { ...state, sidebarOpen: true };
};

export const closeSidebar = (state) => {
  return { ...state, sidebarOpen: false };
};

export const mailModalOpen = (state, { value }) => {
  return { ...state, mailModalOpen: value };
};

export const addToFileCollection = (state, { file }) => {
  const found = state.fileCollection.filter((el) => {
    if (el.properties.uidLocal) {
      return el.properties.uidLocal === file.properties.uidLocal;
    }
    return el.properties.fileReferenceUid === file.properties.fileReferenceUid;
  });
  if (found.length === 0) {
    reactLocalStorage.setObject("savedFileState", { files: [...state.fileCollection, file] });
    return { ...state, fileCollection: [...state.fileCollection, file] };
  }
  return { ...state, fileCollection: [...state.fileCollection] };
};

export const addEmailFile = (state, { file }) => {
  return { ...state, emailFile: file };
};

export const deleteFromFileCollection = (state, { fileId }) => {
  const newFilesState = state.fileCollection.filter((el) => {
    if (el.properties.uidLocal) {
      return el.properties.uidLocal !== fileId;
    }
    return el.properties.fileReferenceUid !== fileId;
  });

  reactLocalStorage.setObject("savedFileState", { files: newFilesState });
  return { ...state, fileCollection: newFilesState };
};

export const deleteEmailFile = (state) => {
  return { ...state, emailFile: null };
};

export const clearFileCollection = (state) => {
  reactLocalStorage.setObject("savedFileState", { files: [] });
  return { ...state, fileCollection: [] };
};
/* ------------- Redux Pub Actions ------------- */
export const setSidebarOpen = () => (dispatch) => {
  dispatch(Creators.openSidebar());
};

export const setSidebarClose = () => (dispatch) => {
  dispatch(Creators.closeSidebar());
};

export const openMailModal = () => (dispatch) => {
  dispatch(Creators.mailModalOpen(true));
};

export const closeMailModal = () => (dispatch) => {
  dispatch(Creators.mailModalOpen(false));
};

export const addToDownloadList = (file) => (dispatch) => {
  dispatch(Creators.addToFileCollection(file));
};

export const addFileForMail = (file) => (dispatch) => {
  dispatch(Creators.addEmailFile(file));
};

export const deleteFileForEmail = () => (dispatch) => {
  dispatch(Creators.deleteEmailFile());
};

export const deleteFromDownloadList = (file) => (dispatch) => {
  if (file.properties.uidLocal) {
    dispatch(Creators.deleteFromFileCollection(file.properties.uidLocal));
  } else {
    dispatch(Creators.deleteFromFileCollection(file.properties.fileReferenceUid));
  }
};

export const clearDownloadList = () => (dispatch) => {
  dispatch(Creators.clearFileCollection());
};

export const downloadDownloadList = async (files) => {
  const { payload, error } = await postApi(DOWNLOAD_ONDEMAND, {
    rest: {
      downloadDemand: {
        assets: files.map((el) => el.properties.uidLocal),
      },
    },
  });

  const link = document.createElement("a");
  link.href = payload.downloadLink;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const sendFilesToMailService = async (data) => {
  const { payload, error } = await postApi(MAIL_ONDEMAND, {
    rest: {
      sendAssetsDemand: data,
    },
  });
};

export const sendFileToMailService = async (data) => {
  const { payload, error } = await postApi(MAIL_ONDEMAND, {
    rest: {
      sendAssetsDemand: [data],
    },
  });
};
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.OPEN_SIDEBAR]: openSidebar,
  [Types.CLOSE_SIDEBAR]: closeSidebar,
  [Types.MAIL_MODAL_OPEN]: mailModalOpen,
  [Types.ADD_TO_FILE_COLLECTION]: addToFileCollection,
  [Types.ADD_EMAIL_FILE]: addEmailFile,
  [Types.DELETE_FROM_FILE_COLLECTION]: deleteFromFileCollection,
  [Types.DELETE_EMAIL_FILE]: deleteEmailFile,
  [Types.CLEAR_FILE_COLLECTION]: clearFileCollection,
});
