import Cookie from "js-cookie";
import { buildScreenName } from "./TextUtils";
import { createCookieExpireDate } from "./DateUtils";
import v5 from "uuid/v5";
import { forEach } from "react-bootstrap/ElementChildren";

const COOKIE_USER_PATH = "user";

/**
 * Returns current user
 *
 * @return {object}
 */
export const getUser = () => Cookie.getJSON(COOKIE_USER_PATH);

/**
 * Set current user
 *
 * @return {void}
 */
export const setUser = (user) => {
  Cookie.set(COOKIE_USER_PATH, user, { expires: createCookieExpireDate() });
};

/**
 * Destroy current user out
 *
 * @return {void}
 */
export const logoutUser = () => Cookie.remove(COOKIE_USER_PATH);

/**
 * Checks user is logged in
 *
 * @return {boolean}
 */
export const isAuthenticated = () => !!getUser();

export const isUserAdmin = () => {
  const { userObject } = getUser();
  if (!userObject) {
    return false;
  }
  const userGroup = userObject.usergroup;

  return Object.entries(userGroup).some((group, index) => {
    return group[1]["uid"] === 6;
  });
};

export const isSuperAdmin = () => {
  const { userObject } = getUser();
  if (!userObject) {
    return false;
  }
  const userGroup = userObject.usergroup;

  return Object.entries(userGroup).some((group, index) => {
    return group[1]["uid"] === 10;
  });
};

export const getUserName = () => {
  const { userObject } = getUser();

  return buildScreenName(userObject);
};

export const getGroupLogo = () => {
  const { userObject } = getUser();

  if (typeof userObject === "undefined") {
    return "";
  }

  let logo = "";
  const userGroup = userObject.usergroup;

  Object.keys(userGroup).forEach((key) => {
    if (userGroup[key].uid !== 6 && userGroup[key].uid !== 10) {
      logo = userGroup[key].logo;
    }
  });

  return logo;
};

export const getGroupName = () => {
  const { userObject } = getUser();

  if (typeof userObject === "undefined") {
    return "";
  }

  const userGroup = userObject.usergroup;

  let title = "";

  Object.keys(userGroup).forEach((key) => {
    if (userGroup[key].uid !== 6) {
      title = userGroup[key].title;
    }
  });
  return title;
};

export const getGroups = () => {
  const { groups } = getUser();

  if (typeof groups === "undefined") {
    return [];
  }

  return groups;
};

export const getGroupId = () => {
  try {
    const { userObject } = getUser();

    if (typeof userObject === "undefined") {
      return "";
    }

    const userGroup = userObject.usergroup;

    let id = "";

    Object.keys(userGroup).forEach((key) => {
      if (userGroup[key].uid !== 6 && userGroup[key].uid !== 10) {
        id = userGroup[key].uid;
      }
    });

    return id;
  } catch (e) {
    return 666;
  }
};

export const getGroupOnboardingLink = () => {
  const { userObject } = getUser();
  let namespace = "5aad1892-8461-44a1-a226-60b80752a201";

  if (typeof userObject === "undefined") {
    return "";
  }
  const userGroup = userObject.usergroup;
  let url = false;
  let id = false;
  Object.keys(userGroup).forEach((key) => {
    if (userGroup[key].url) {
      url = userGroup[key].url;
      id = userGroup[key].uid;
    }
  });
  if (!url) {
    return false;
  }
  let parameter = id + "-" + userObject.uid;

  return url + "?acc_id=" + v5(parameter, namespace);
};
