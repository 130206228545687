import React from "react";
import classNames from "classnames";
import { ReactComponent as SpinnerIcon } from "../../Images/SVG/spinner.svg";

export default ({ show, small }) => {
  return (
    <div
      id="spinner"
      className={classNames({
        spinner: true,
        show,
        small,
      })}
    >
      <div>
        <SpinnerIcon />
      </div>
    </div>
  );
};
