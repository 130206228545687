import React from "react";
import PropTypes from "prop-types";

const HorizontalRuler = ({ classes }) => {
  const elementClasses = `horizontalRuler row ${classes}`;

  return (
    <div className={elementClasses}>
      <div className="col-12">
        <hr />
      </div>
    </div>
  );
};

HorizontalRuler.propTypes = {
  classes: PropTypes.string,
};

export default HorizontalRuler;
