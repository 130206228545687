import React from "react";

const Person = (props) => {
  const imgEl = React.createRef();

  return (
    <div className="row">
      <div className="col-12 mb-medium d-flex justify-content-end">
        <div className="image">
          <img
            src={props.url}
            alt=""
            ref={imgEl}
            width="100%"
            height="100%"
            className="mb-medium"
          />
          <span className="label name">
            {props.firstName} {props.lastName}
          </span>
          <span className="label">{props.position}</span>
        </div>
      </div>
    </div>
  );
};

export default Person;
