/* eslint-disable dot-notation */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-use-before-define */
import React from "react";
import { isLocalhost } from "../../Utils/DevUtils";
import PageHeadline from "./PageHeadline";
import TeaserImageText from "./TeaserImageText";
import HorizontalRuler from "./HorizontalRuler";
import Slider from "./Slider";
import SimpleImage from "./SimpleImage";
import Cards from "./Cards";
import SimpleTable from "./SimpleTable";
import Headline from "./Headline";
import Sample from "./Sample";
import CTA from "./CTA";
import RteCard from "./RteCard";
import DownloadAndNewsCard from "./DownloadAndNewsCard";
import SimpleText from "./SimpleText";
import MultiDownloadCards from "./MultiDownloadCards";
import ContactPerson from "./ContactPerson";
import List from "./News/List";
import DetailPage from "./DetailPage/DetailPage";
import DebugInfo from "../Common/DebugInfo";
import InvestmentTeam from "./InvestmentTeam";
import MultipleLinksCard from "./MultipleLinksCard";
import FondFacts from "./Fonds/FondFacts";
import Intro from "./Fonds/Intro";
import Performance from "./Fonds/Performance";
import IntroFlex from "./Fonds/IntroFlex";
import Downloads from "./Downloads/Downloads";
import Download from "./Download";
import MixedSourceMultiDownloadCards from "./MixedSourceMultiDownloadCards";
import MSDACard from "./MSDACard";
import UploadForm from "./UploadForm/UploadForm";
import OrderForm from "./OrderForm/BrochureOrderForm";

const Elements = (props) => {
  function buildGroup(type) {
    if (props.items && props.items.length > 0) {
      return props.items.filter((item) => item.type === type);
    }

    return null;
  }

  function renderContentElement(item) {
    const sectionId = `${item.id}_${item.type}`;

    if (isLocalhost && showOnly && showOnly !== item.type) {
      return <b>`${item.type} skipped`</b>;
    }

    switch (item.type) {
      case "PageHeadline":
        return (
          <PageHeadline
            showServiceInformation={item.content.showServiceInformation}
            headline={item.content.headline}
            layout="default"
          />
        );
      case "header":
        return (
          <PageHeadline
            showServiceInformation={false}
            headline={item.content.header}
            subline={item.content.subheader}
            layout={item.appearance.layout}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_introwithassets":
        return (
          <TeaserImageText
            sectionId={sectionId}
            headline={item.content.header}
            showGetStarted={item.content.getStarted}
            copy={item.content.bodytext}
            classes={mapClasses(item)}
            image={item.content.image.publicUrl}
            file={item.content.assets}
          />
        );
      case "partnerportal_horizontal-ruler":
        return <HorizontalRuler classes={mapClasses(item)} />;
      case "partnerportal_contentslider":
        return (
          <Slider
            headline={item.content.header}
            classes={mapClasses(item)}
            slides={item.content.slides}
          />
        );
      case "partnerportal_image":
        return (
          <SimpleImage
            headline={item.content.header}
            image={item.content.image.publicUrl}
            classes={mapClasses(item)}
          />
        );
      case "Cards":
        return (
          <Cards
            headline={item.content.headline}
            items={item.content.items}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_rtecard":
        return (
          <RteCard
            headline={item.content.header}
            subline={item.content.subheader}
            copy={item.content.bodytext}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_rte_multiple_cards":
        return (
          <Cards
            headline={item.content.header}
            items={item.content.items}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_coststable":
        return (
          <SimpleTable
            headline={item.content.header}
            table={item.content.bodytext}
            classes={mapClasses(item)}
          />
        );
      case "Headline":
        return <Headline headline={item.content.headline} classes={mapClasses(item)} />;
      case "Sample":
        return (
          <Sample
            headline={item.content.headline}
            persona={item.content.persona}
            subline={item.content.subline}
            copy={item.content.copy}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_exampletables":
        return (
          <Sample
            headline={item.content.header}
            persona={item.content.bullets}
            subline={item.content.subheader}
            copy={item.content.table}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_outgoinglink":
        return (
          <CTA
            url={item.content.headerLink.url}
            target={item.content.headerLink.target}
            label={item.content.header}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_introflex":
        return (
          <TeaserImageText
            sectionId={sectionId}
            headline={item.content.header}
            copy={item.content.bodytext}
            classes={mapClasses(item)}
            image={item.content.image.publicUrl}
            file={false}
          />
        );
      case "partnerportal_downloadandeventcard":
        return (
          <DownloadAndNewsCard
            news={item.content.news}
            header={item.content.header}
            subheader={item.content.subheader}
            files={item.content.Documents}
            classes={mapClasses(item)}
          />
        );
      case "text":
        return (
          <SimpleText
            header={item.content.header}
            subheader={item.content.subheader}
            copy={item.content.bodytext}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_multipledownloads":
        return (
          <MultiDownloadCards
            header_left={item.content.header}
            header_right={item.content.subheader}
            files_left={item.content.assets_left}
            files_right={item.content.assets_right}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_mixedmultipledownloads":
        return (
          <MixedSourceMultiDownloadCards
            header_left={item.content.header_left}
            header_right={item.content.header_right}
            files_left={item.content.assets_left}
            files_right={item.content.assets_right}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_multidownloadandeventcard":
        return (
          <MSDACard
            news={item.content.news}
            header_news={item.content.header_news}
            header_downloads={item.content.header_downloads}
            downloads={item.content.downloads}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_contactpersoncard":
        return <ContactPerson item={item} />;
      case "news_pi1":
        return item.content.data.settings.action === "detail" ? (
          <>
            <DetailPage item={item} />
          </>
        ) : (
          <>
            <List
              header={item.content.header}
              subheader={item.content.subheader}
              currentPage={item.content.data.pagination.current}
              maxPages={item.content.data.pagination.numberOfPages}
              items={item.content.data.list}
            />
          </>
        );
      case "partnerportal_personcard":
        if (groups["partnerportal_personcard"]) {
          const teams = groups["partnerportal_personcard"];
          groups["partnerportal_personcard"] = null; // Just render it once
          return <InvestmentTeam teams={teams} classes={mapClasses(item)} />;
        }
        return <></>;
      case "partnerportal_multiplelinkscard":
        return <MultipleLinksCard content={item.content} classes={mapClasses(item)} />;
      case "partnerportal_fondfacts":
        return <FondFacts content={item.content} classes={mapClasses(item)} />;
      case "partnerportal_fondintro":
        return <Intro content={item.content} classes={mapClasses(item)} />;
      case "partnerportal_fondintroflex":
        return <IntroFlex content={item.content} classes={mapClasses(item)} />;
      case "partnerportal_graphic":
        return <Performance content={item.content} classes={mapClasses(item)} />;
      case "partnerportal_fonddownloads":
        if (groups["partnerportal_fonddownloads"]) {
          const items = groups["partnerportal_fonddownloads"];
          groups["partnerportal_fonddownloads"] = null;
          return <Downloads items={items} />;
        }
        return <></>;
      case "partnerportal_folderdownloads":
        return <Download content={item.content} classes={mapClasses(item)} />;
      case "partnerportal_formupload":
        return (
          <UploadForm
            id={item.id}
            type={item.type}
            content={item.content}
            classes={mapClasses(item)}
          />
        );
      case "partnerportal_orderform":
        return <OrderForm content={item.content} classes={mapClasses(item)} />;
      default:
        return (
          <DebugInfo componentName="Elements" title={`Unknown type: ${item.type}`} json={item} />
        );
    }
  }

  function mapClasses(item) {
    let classes = "";
    if (item.appearance.spaceBefore.length) {
      classes = `mt-${switchClasses(item.appearance.spaceBefore)}`;
    }

    if (item.appearance.spaceAfter.length) {
      classes = `${classes} mb-${switchClasses(item.appearance.spaceAfter)}`;
    }

    return classes;
  }

  function switchClasses(item) {
    switch (item) {
      case "extra-small":
        return "small";
      case "small":
        return "medium";
      case "medium":
        return "medium-2";
      case "large":
        return "large";
      case "extra-large":
        return "xlarge";
      case "xxlarge":
        return "xxlarge";
      case "xxxlarge":
        return "xxxlarge";
      default:
        return <></>;
    }
  }

  function renderItem(item) {
    const elementId = `${item.id}_${item.type}`;
    return (
      <section key={item.id} id={elementId}>
        {renderContentElement(item)}
      </section>
    );
  }

  // building itemGroups where BE didnt grouped before
  let groups = [];
  const showOnly = null;
  groups["partnerportal_personcard"] = buildGroup("partnerportal_personcard");
  groups["partnerportal_fonddownloads"] = buildGroup("partnerportal_fonddownloads");
  return props.items && props.items.map((item) => renderItem(item));
};

export default Elements;
