import React from "react";
import { ReactComponent as LFLogo } from "../../Images/SVG/logo_lloyd.svg";
import { ReactComponent as LaicLogo } from "../../Images/SVG/logo_laic.svg";
import VKILogo from "../../Images/logo_vki.png";

import { getGroupId } from "../../Utils/UserUtils";

const PreFooter = () => {
  const renderLogo = () => {
    switch (getGroupId()) {
      case 8:
        return (
          <div className="col-lg-4">
            <span className="pd-medium">
              <img src={VKILogo} alt="vki" className="img-fluid" />
            </span>
          </div>
        );
      case 666:
        return (
          <>
            <div className="col-lg-4">
              <span className="pd-medium">
                <LFLogo />
              </span>
            </div>
            <div className="col-lg-4">
              <span className="pd-medium">
                <LaicLogo />
              </span>
            </div>
            <div className="col-lg-4">
              <span className="pd-medium">
                <img src={VKILogo} alt="vki" className="img-fluid" />
              </span>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="col-lg-4">
              <span className="pd-medium">
                <LFLogo />
              </span>
            </div>
            <div className="col-lg-4">
              <span className="pd-medium">
                <LaicLogo />
              </span>
            </div>
          </>
        );
    }
  };

  return (
    <div className="mt-xlarge text-center">
      <div className="row">
        <div className="col-lg-12 text-center mb-small">
          <span className="font-color-dark-grey font-size-small">Ein Service von</span>
        </div>
      </div>
      <div className="row justify-content-center">{renderLogo()}</div>
    </div>
  );
};

export default PreFooter;
